import authConfig from "@/config/auth"
import { AccountAdminApi } from "@/services/Api"
import { Notification } from "@/components"
import UserService from "@/services/User"

import ErrorHandlerService from "@/services/ErrorHandler"

class AuthService {
	get authAdminTokenKey (): string {
		return authConfig.authAdminTokenKey
	}

	async isLoggedIn (): Promise<boolean> {
		const isLoggedIn = await UserService.isLoggedIn()

		return isLoggedIn
	}

	async login (): Promise<void> {
		try {
			const userInfo = await UserService
				.getInfo()

			if (!userInfo) window.location.replace("/signin")
		} catch (error) {
			ErrorHandlerService.handle(error)
		}
	}

	async logout (): Promise<void> {
		try {
			await AccountAdminApi
				.delete("/auth/logout")

			window.location.replace("/signin")
		} catch (error) {
			Notification.error(
				{ message: "Algo deu errado e não conseguimos identificar. Entre em contato." })
		}
	}
}

export default new AuthService()
