import colors from "@/styles/colors"
import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	unknown: {
		color: newColors.grey[300]
	},
	contact: {
		flex: 1,
		"&:hover": {
			textDecoration: "underline"
		},
		color: colors.palette.link,
		cursor: "pointer",
		fontSize: "16px"
	}
})

export default useStyles
