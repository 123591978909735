import React, { useState, ReactElement } from "react"
import { Link } from "react-router-dom"

import {
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
	CircularProgress,
	Box,
	IconButton
} from "@material-ui/core"
import {
	Close as CloseIcon
} from "@material-ui/icons"

import { ptBR } from "date-fns/locale"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"

import { InboxAdminApi } from "@/services/Api"

import useValidation from "@/hooks/useValidation"
import useDidMount from "@/hooks/useDidMount"

import { Divider, Loading, Portlet } from "@/components"

import useStyles from "@/pages/Inbox/Usage/styles"
import CardSkeleton from "@/skeletons/CardSkeleton"
import { isSmallScreen } from "@/utils/checkDevice"
import useCustomStyles from "@/styles/custom"
import { dayInMilliseconds } from "@/utils/time"
import { Feature, Usage, UsagePerFeature } from "@/pages/Inbox/protocols/UsageProtocol"
import colors from "@/styles/colors"

type Where = {
	orderByFeat: "total" | "message-blast" | "integration" | "attendance" | "chatbot"
	orderByMetric: "total_messages" | "sent_but_not_delivered_messages" | "not_sent_messages" | "delivered_messages" | "not_sent_messages_percent"
	page: number
	rowsPerPage: number
	search: string
	from: Date
	to: Date
	fromInstanceCreatedAt: Date | null
	toInstanceCreatedAt: Date | null
	totalMin?: number
	totalMax?: number
	messageBlastMin?: number
	messageBlastMax?: number
	integrationMin?: number
	integrationMax?: number
	attendanceMin?: number
	attendanceMax?: number
	chatbotMin?: number
	chatbotMax?: number
	clientSearch?: string
}

const InboxInstances = () => {
	const [usages, setUsages] = useState<Usage[]>([])
	const [count, setCount] = useState<number>(0)
	const [loading, setLoading] = useState<boolean>(true)

	const [where, setWhere] = useState<Where>({
		orderByFeat: "total",
		orderByMetric: "total_messages",
		page: 0,
		rowsPerPage: 500,
		search: "",
		from: new Date(Date.now() - 7 * dayInMilliseconds),
		to: new Date(),
		fromInstanceCreatedAt: null,
		toInstanceCreatedAt: null
	})

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const {
		triggerValidation
	} = useValidation()

	const getUsages = async (newWhere?: Partial<Where>) => {
		setLoading(true)

		try {
			const { data } = await InboxAdminApi
				.get("/metrics/messages", {
					params: {
						...where,
						...newWhere
					}
				})
			setUsages(data.usages)
			setCount(data.count)
		} catch (error) {
			triggerValidation(error)
		}

		setLoading(false)
	}

	const handleWhereChange = (newData: Partial<Where>) => {
		setWhere((currentState) => (
			{
				...currentState,
				...newData
			}))
	}

	const handlePageChange = async (event: unknown, page: number) => {
		handleWhereChange({
			page
		})

		await getUsages({ page })
	}

	const handleRowsPerPageChange = async (rowsPerPage: number) => {
		handleWhereChange({
			rowsPerPage
		})
		await getUsages({ rowsPerPage })
	}

	const handleDateFilterChange = async (
		type: "from" | "to" | "fromInstanceCreatedAt" | "toInstanceCreatedAt",
		date: Date | null
	) => {
		const newDate = date ? new Date(date).toLocaleDateString("en-US") : null

		handleWhereChange({
			[type as string]: newDate
		})
	}

	useDidMount(() => {
		getUsages()
	})

	const colorByIndex = (index: number): string => {
		const colors = ["darkorange", "green", "red", "darkred", "chocolate"]
		return colors[index % colors.length]
	}

	const cellBackgrounByFeature = (feature: Feature): string => {
		const greyFeats: Feature[] = ["total", "integration", "chatbot"]
		if (greyFeats.includes(feature)) {
			return colors.grayScale[10]
		} else {
			return colors.grayScale[11]
		}
	}

	const toPercent = (value: number): string => {
		if (Number.isNaN(value)) {
			return "0%"
		}

		return (value * 100).toFixed(1).replace(".", ",") + "%"
	}

	const smallerFontSize = "0.75rem"

	const buildHeader = (feature: Feature): ReactElement[] => {
		const consolidate = {
			totalMessages: 0,
			sentButNotDeliveredMessages: 0,
			deliveredMessages: 0,
			notSentMessages: 0
		}
		usages.forEach(usage => {
			const data = usage.usage[feature]
			consolidate.totalMessages += data?.totalMessages || 0
			consolidate.sentButNotDeliveredMessages += data?.sentButNotDeliveredMessages || 0
			consolidate.deliveredMessages += data?.deliveredMessages || 0
			consolidate.notSentMessages += data?.notSentMessages || 0
		})
		const defaultStyle = {
			backgroundColor: cellBackgrounByFeature(feature),
			fontSize: "0.75rem"
		}

		return [
			<TableCell key="notSent" align="center" style={{ color: colorByIndex(0), ...defaultStyle }}>
				{(consolidate.sentButNotDeliveredMessages).toLocaleString("pt-BR")}
			</TableCell>,
			<TableCell key="sent" align="center" style={{ color: colorByIndex(1), ...defaultStyle }}>
				{(consolidate.deliveredMessages).toLocaleString("pt-BR")}
			</TableCell>,
			<TableCell key="error" align="center" style={{ color: colorByIndex(2), ...defaultStyle }}>
				{(consolidate.notSentMessages).toLocaleString("pt-BR")}
			</TableCell>,
			<TableCell key="errorPercent" align="center" style={{ color: colorByIndex(3), ...defaultStyle }}>
				{toPercent(consolidate.notSentMessages / consolidate.totalMessages)}
			</TableCell>,
			<TableCell key="notDeliveredPercent" align="center" style={{ color: colorByIndex(4), ...defaultStyle }}>
				{toPercent(consolidate.sentButNotDeliveredMessages / consolidate.totalMessages)}
			</TableCell>
		]
	}

	const LegendBox = (label: string, color: string): ReactElement => {
		return <Box display="flex" mr="24px" alignItems="center" color={color}>
			<Box width="16px" height="16px" bgcolor={color} mr="8px" />
			{label}
		</Box>
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography color="textPrimary" className={classes.title}>
					Relatório de mensagens
				</Typography>

				<Divider size={1} orientation="horizontal" />

				<Portlet elevation={1}>
					<Grid container>
						<Grid item xs={12}>
							<form onSubmit={() => getUsages(where)}>
								<Grid container spacing={2} alignItems="center">
									<Grid item xs={2}>
										<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
											<DatePicker
												id="from"
												variant={isSmallScreen ? "dialog" : "inline"}
												inputVariant="outlined"
												disableFuture
												format="dd/MM/yyyy"
												label="De (mensagens)"
												animateYearScrolling
												views={["year", "month", "date"]}
												value={where.from}
												onChange={(date) =>
													handleDateFilterChange("from", date as Date)
												}
												maxDate={where.to}
												maxDateMessage="Não pode ser maior que a data final"
												color="primary"
												className={customClasses.datePicker}
												InputProps={{
													className: customClasses.datePickerInput
												}}
												cancelLabel="Cancelar"
												autoOk
												required={true}
											/>
										</MuiPickersUtilsProvider>
									</Grid>

									<Grid item xs={2}>
										<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
											<DatePicker
												id="to"
												variant={isSmallScreen ? "dialog" : "inline"}
												inputVariant="outlined"
												disableFuture
												label="Até (mensagens)"
												format="dd/MM/yyyy"
												animateYearScrolling
												views={["year", "month", "date"]}
												value={where.to}
												onChange={(date) =>
													handleDateFilterChange("to", date as Date)
												}
												minDate={where.from}
												minDateMessage="Não pode ser maior que a data inicial"
												color="primary"
												className={customClasses.datePicker}
												InputProps={{
													className: customClasses.datePickerInput
												}}
												cancelLabel="Cancelar"
												autoOk
												required={true}
											/>
										</MuiPickersUtilsProvider>
									</Grid>

									<Grid item xs={2}>
										<Grid
											container
											alignItems="center"
											justify="space-evenly"
										>
											<Grid item xs>
												<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
													<DatePicker
														id="fromInstanceCreatedAt"
														variant={isSmallScreen ? "dialog" : "inline"}
														inputVariant="outlined"
														disableFuture
														format="dd/MM/yyyy"
														label="De (cadastro)"
														animateYearScrolling
														views={["year", "month", "date"]}
														value={where.fromInstanceCreatedAt}
														onChange={(date) =>
															handleDateFilterChange("fromInstanceCreatedAt", date as Date)
														}
														maxDate={where.toInstanceCreatedAt}
														maxDateMessage="Não pode ser maior que a data final"
														color="primary"
														className={customClasses.datePicker}
														InputProps={{
															className: customClasses.datePickerInput
														}}
														cancelLabel="Cancelar"
														autoOk
														InputLabelProps={{ shrink: true }}
														required={false}
													/>
												</MuiPickersUtilsProvider>
											</Grid>

											{where?.fromInstanceCreatedAt && (
												<Grid item>
													<IconButton
														className={classes.dateDeleteIcon}
														onClick={() =>
															handleDateFilterChange("fromInstanceCreatedAt", null)
														}
													>
														<CloseIcon fontSize="small" />
													</IconButton>
												</Grid>
											)}
										</Grid>
									</Grid>

									<Grid item xs={2}>
										<Grid
											container
											alignItems="center"
											justify="space-evenly"
										>
											<Grid item xs>
												<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
													<DatePicker
														id="toInstanceCreatedAt"
														variant={isSmallScreen ? "dialog" : "inline"}
														inputVariant="outlined"
														disableFuture
														label="Até (cadastro)"
														format="dd/MM/yyyy"
														animateYearScrolling
														views={["year", "month", "date"]}
														value={where.toInstanceCreatedAt}
														onChange={(date) =>
															handleDateFilterChange("toInstanceCreatedAt", date as Date)
														}
														minDate={where.fromInstanceCreatedAt}
														minDateMessage="Não pode ser maior que a data inicial"
														color="primary"
														className={customClasses.datePicker}
														InputProps={{
															className: customClasses.datePickerInput
														}}
														InputLabelProps={{ shrink: true }}
														cancelLabel="Cancelar"
														autoOk
														required={false}
													/>
												</MuiPickersUtilsProvider>
											</Grid>

											{where?.toInstanceCreatedAt && (
												<Grid item>
													<IconButton
														className={classes.dateDeleteIcon}
														onClick={() =>
															handleDateFilterChange("toInstanceCreatedAt", null)
														}
													>
														<CloseIcon fontSize="small" />
													</IconButton>
												</Grid>
											)}
										</Grid>
									</Grid>

									<Grid item xs={4}>
										<TextField
											value={where.clientSearch}
											onChange={({ target }) => {
												handleWhereChange({
													clientSearch: target.value as string
												})
											}}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											label="Cliente"
											fullWidth
											size="small"
										/>
									</Grid>

									<Grid item xs={2}>
										<FormControl fullWidth variant="outlined" size="small" required={true}>
											<InputLabel id="order-by-feat">Ordenar por produto</InputLabel>
											<Select
												label="Ordenar por produto"
												labelId="order-by-feat"
												onChange={({ target }) => {
													handleWhereChange({
														orderByFeat: target.value as Where["orderByFeat"]
													})
												}}
												value={where.orderByFeat}
											>
												{[
													{ label: "Total", value: "total" },
													{ label: "Envio em massa", value: "message-blast" },
													{ label: "Integração", value: "integration" },
													{ label: "Atendimento", value: "attendance" },
													{ label: "Chatbot", value: "chatbot" }
												].map(({ label, value }) => (
													<MenuItem value={value} key={value}>
														{label}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>

									<Grid item xs={2}>
										<FormControl fullWidth variant="outlined" size="small" required={true}>
											<InputLabel id="order-by-metric">Ordenar por métrica</InputLabel>
											<Select
												label="Ordenar por métrica"
												labelId="order-by-metric"
												onChange={({ target }) => {
													handleWhereChange({
														orderByMetric: target.value as Where["orderByMetric"]
													})
												}}
												value={where.orderByMetric}
											>
												{[
													{ label: "Total", value: "total_messages" },
													{ label: "Não entregues", value: "sent_but_not_delivered_messages" },
													{ label: "Entregues", value: "delivered_messages" },
													{ label: "Com erro", value: "not_sent_messages" },
													{ label: "Percentual com erro", value: "not_sent_messages_percent" }
												].map(({ label, value }) => (
													<MenuItem value={value} key={value}>
														{label}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>

									<Grid item xs={2}>
										<TextField
											value={where.totalMin}
											onChange={({ target }) =>
												handleWhereChange({
													totalMin: target.value !== "" ? +target.value : undefined
												})
											}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											label="Total - Min"
											fullWidth
											type="number"
											size="small"
										/>
									</Grid>

									<Grid item xs={2}>
										<TextField
											value={where.totalMax}
											onChange={({ target }) =>
												handleWhereChange({
													totalMax: target.value !== "" ? +target.value : undefined
												})
											}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											label="Total - Max"
											fullWidth
											type="number"
											size="small"
										/>
									</Grid>

									<Grid item xs={2}>
										<TextField
											value={where.messageBlastMin}
											onChange={({ target }) =>
												handleWhereChange({
													messageBlastMin: target.value !== "" ? +target.value : undefined
												})
											}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											label="Envio em massa - Min"
											fullWidth
											type="number"
											size="small"
										/>
									</Grid>

									<Grid item xs={2}>
										<TextField
											value={where.messageBlastMax}
											onChange={({ target }) =>
												handleWhereChange({
													messageBlastMax: target.value !== "" ? +target.value : undefined
												})
											}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											label="Envio em massa - Max"
											fullWidth
											type="number"
											size="small"
										/>
									</Grid>

									<Grid item xs={2}>
										<TextField
											value={where.integrationMin}
											onChange={({ target }) =>
												handleWhereChange({
													integrationMin: target.value !== "" ? +target.value : undefined
												})
											}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											label="Integração - Min"
											fullWidth
											type="number"
											size="small"
										/>
									</Grid>

									<Grid item xs={2}>
										<TextField
											value={where.integrationMax}
											onChange={({ target }) =>
												handleWhereChange({
													integrationMax: target.value !== "" ? +target.value : undefined
												})
											}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											label="Integração - Max"
											fullWidth
											type="number"
											size="small"
										/>
									</Grid>

									<Grid item xs={2}>
										<TextField
											value={where.attendanceMin}
											onChange={({ target }) =>
												handleWhereChange({
													attendanceMin: target.value !== "" ? +target.value : undefined
												})
											}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											label="Atendimento - Min"
											fullWidth
											type="number"
											size="small"
										/>
									</Grid>

									<Grid item xs={2}>
										<TextField
											value={where.attendanceMax}
											onChange={({ target }) =>
												handleWhereChange({
													attendanceMax: target.value !== "" ? +target.value : undefined
												})
											}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											label="Atendimento - Max"
											fullWidth
											type="number"
											size="small"
										/>
									</Grid>

									<Grid item xs={2}>
										<TextField
											value={where.chatbotMin}
											onChange={({ target }) =>
												handleWhereChange({
													chatbotMin: target.value !== "" ? +target.value : undefined
												})
											}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											label="Chatbot - Min"
											fullWidth
											type="number"
											size="small"
										/>
									</Grid>

									<Grid item xs={2}>
										<TextField
											value={where.chatbotMax}
											onChange={({ target }) =>
												handleWhereChange({
													chatbotMax: target.value !== "" ? +target.value : undefined
												})
											}
											InputLabelProps={{ shrink: true }}
											variant="outlined"
											label="Chatbot - Max"
											fullWidth
											type="number"
											size="small"
										/>
									</Grid>

									<Grid item xs={2}>
										<Button
											color="primary"
											variant="contained"
											fullWidth
											disabled={loading}
											endIcon={loading && <CircularProgress size={20} style={{ color: colors.grayScale[11] }} />}
											type="submit"
											onClick={() => getUsages(where)}
										>
											Aplicar filtros
										</Button>
									</Grid>
								</Grid>
							</form>
						</Grid>

						<Divider size={3} orientation="horizontal" />

						<Grid item xs={12}>
							<Box display="flex" >
								{LegendBox("Não entregues", colorByIndex(0))}
								{LegendBox("Entregues", colorByIndex(1))}
								{LegendBox("Com erro", colorByIndex(2))}
								{LegendBox("% erros", colorByIndex(3))}
								{LegendBox("% não entregues", colorByIndex(4))}
							</Box>
							<Divider orientation="horizontal" size={2} />
							<Loading customLoadingElement={<CardSkeleton cardHeight={200} />} loading={loading}>
								{
									count > 0
										? <Paper className={classes.paper}>
											<TableContainer >
												<Table stickyHeader aria-label="sticky table">
													<TableHead>
														<TableRow>
															<TableCell rowSpan={2}>
																Instância - Account

																<Divider orientation="horizontal" size={0.5} />

																Dono
															</TableCell>

															{Object.entries(
																{ total: "Total", messageBlast: "Envio em massa", integration: "Integração", attendance: "Atendimento", chatbot: "Chatbot" }
															).map(([feat, label]) => (
																<TableCell key={feat} colSpan={5} align="center" style={{ backgroundColor: cellBackgrounByFeature(feat as Feature) }}>
																	{label}
																</TableCell>
															))}
														</TableRow>
														<TableRow >
															{
																[
																	...buildHeader("total"),
																	...buildHeader("messageBlast"),
																	...buildHeader("integration"),
																	...buildHeader("attendance"),
																	...buildHeader("chatbot")
																]
															}
														</TableRow>
													</TableHead>
													<TableBody>
														{usages.map(({ customer, usage }) => (
															<TableRow key={customer.instance_id} >
																<TableCell>
																	<Link to={`/inbox/instance/${customer.instance_id}`}>
																		{customer.instance_id}
																	</Link>
																	{" "} - {" "}
																	<Link to={`/account/${customer.account_id}`}>
																		{customer.account_id}
																	</Link>

																	<Divider orientation="horizontal" size={0.5} />

																	<Typography style={{ fontSize: smallerFontSize, width: "100px", wordWrap: "break-word" }}>
																		{customer.email}
																	</Typography>
																</TableCell>

																{(["total", "messageBlast", "integration", "attendance", "chatbot"] as (keyof Usage["usage"])[]).map((feature) => {
																	const data = usage[feature]
																	const event: (keyof UsagePerFeature)[] = ["sentButNotDeliveredMessages", "deliveredMessages", "notSentMessages"]
																	const backgroundColor = cellBackgrounByFeature(feature)
																	return [
																		...event.map((key, index) => (
																			<TableCell key={key} align="center" style={{ color: colorByIndex(index), backgroundColor, fontSize: "0.75rem" }}>
																				{data ? data[key].toLocaleString("pt-BR") : "-"}
																			</TableCell>
																		)),
																		(
																			<>
																				<TableCell align="center" key="err-percent" style={{ color: colorByIndex(3), backgroundColor, fontSize: "0.75rem" }}>
																					{
																						Number.isFinite(data?.notSentMessagesPercent)
																							? toPercent(data.notSentMessagesPercent)
																							: "-"
																					}
																				</TableCell>
																				<TableCell align="center" key="err-percent" style={{ color: colorByIndex(4), backgroundColor, fontSize: "0.75rem" }}>
																					{
																						Number.isFinite(data?.sentButNotDeliveredMessages)
																							? toPercent(data.sentButNotDeliveredMessages / data.sentMessages)
																							: "-"
																					}
																				</TableCell>
																			</>
																		)
																	]
																})}
															</TableRow>
														))}
													</TableBody>
												</Table>
											</TableContainer>

											<TablePagination
												rowsPerPageOptions={[100, 200, 500, 1000, 2000]}
												component="div"
												count={count}
												rowsPerPage={where.rowsPerPage}
												page={where.page}
												onPageChange={handlePageChange}
												onRowsPerPageChange={({ target }) => handleRowsPerPageChange(+target.value)}
												labelRowsPerPage={"Resultados por página:"}
												labelDisplayedRows={e =>
													`Página ${e.page + 1} de ${Math.ceil(e.count / where.rowsPerPage)} | Total de resultados: ${count}`
												}
											/>
										</Paper>
										: (
											<>
												<Divider size={3} orientation="horizontal" />
												<Typography align="center" variant="h2">
													Nenhum dado de utilização atende aos filtros
												</Typography>
											</>)
								}
							</Loading>
						</Grid>
					</Grid>
				</Portlet >
			</Grid >
		</Grid >
	)
}

export default InboxInstances
