import { ActionDialog, Notification } from "@/components"
import { Chip, Grid, TextField } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { PlanAddonsInAccountCreateData } from "@/pages/Account/protocols/PlanAddonsInAccountProtocol"
import { AccountAdminApi } from "@/services/Api"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { AddonsByTypeResponse } from "@/pages/Account/protocols/AddonProtocol"
import useStyle from "@/pages/Account/Account/Details/components/Addons/CreatePlanAddonInAccountFormDialog/styles"

interface CreatePlanAddonsInAccountFormDialogProps{
	accountId: number
	appInstanceId: number
	showCreatePlanAddonsInAccountDialog?: boolean
	setShowCreatePlanAddonsInAccountDialog?: (value: boolean) => void
	getPlanAddonsInAccounts?: (searchValue?: string) => Promise<void>
}

type PlanAddonListing = {
	typeId: number
	type: string
	addon: string
	addonId: number
}

const CreatePlanAddonsInAccountFormDialog = (props: CreatePlanAddonsInAccountFormDialogProps) => {
	const { accountId, appInstanceId, showCreatePlanAddonsInAccountDialog, setShowCreatePlanAddonsInAccountDialog, getPlanAddonsInAccounts } = props
	const [formData, setFormData] = useState<PlanAddonsInAccountCreateData>({} as PlanAddonsInAccountCreateData)
	const [actionDialogLoading, setActionDialogLoading] = useState<boolean>(false)
	const [addonsByType, setAddonsByType] = useState<AddonsByTypeResponse[]>([])

	const { validation, triggerValidation } = useValidation()
	const classes = useStyle()

	const addons: PlanAddonListing[] = []

	addonsByType.forEach(addonByType => {
		addonByType.addons.forEach(addon => {
			addons.push({
				typeId: addonByType.typeId,
				type: addonByType.typeDisplayName,
				addon: addon.display_name,
				addonId: addon.id
			})
		})
	})

	const handleCreatePlanAddonsInAccount = async () => {
		setActionDialogLoading(true)
		try {
			await AccountAdminApi.post("/plan-addons-in-account", {
				...formData
			})

			setFormData({} as PlanAddonsInAccountCreateData)

			if (setShowCreatePlanAddonsInAccountDialog) {
				setShowCreatePlanAddonsInAccountDialog(false)
			}

			await getPlanAddonsInAccounts?.()
			Notification.success({ message: `Adicional criado com sucesso na conta ${accountId}!` })
		} catch (error) {
			Notification.error({ message: `Houve um erro ao criar um adicional na conta ${accountId}!` })
			triggerValidation(error as ErrorType)
		}
		setActionDialogLoading(false)
	}

	const getPlanAddonsByTypes = async () => {
		setActionDialogLoading(true)
		try {
			const { data } = await AccountAdminApi
				.get("/plan-addons-by-types")

			setAddonsByType(data)
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setActionDialogLoading(false)
	}

	useEffect(() => {
		setFormData({} as PlanAddonsInAccountCreateData)
		getPlanAddonsByTypes()
	// eslint-disable-next-line
	}, [showCreatePlanAddonsInAccountDialog])

	return <ActionDialog
		title="Criar novo adicional"
		onSave={handleCreatePlanAddonsInAccount}
		saveText="Pronto"
		onClose={() => setShowCreatePlanAddonsInAccountDialog?.(false)}
		loading={actionDialogLoading}
		openDialog={showCreatePlanAddonsInAccountDialog}
		fullWidth
	>
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Autocomplete
					noOptionsText="Nenhum adicional encontrado"
					options={addons}
					getOptionSelected={(option, value) => option.addonId === value.addonId}
					getOptionLabel={(option) => option.addon}
					groupBy={option => option.type}
					fullWidth
					filterSelectedOptions
					onChange={(_, value) => setFormData({
						app_instance_id: appInstanceId,
						account_id: accountId,
						plan_addon_id: Number(value?.addonId)
					})}
					renderInput={(params) => {
						return (
							<TextField
								className={classes.filterInput}
								variant="outlined"
								placeholder="Selecionar o adicional"
								{...({
									...params,
									InputProps: {
										...params.InputProps
									}
								})}
								error={!!validation.value}
								helperText={validation.value}
							/>
						)
					}}
					renderTags={(value, getTagProps) => {
						return value.map((value, index) => (
							<Chip
								key={index}
								label={value.addon}
								size="small"
								{...getTagProps({ index })}
							/>
						))
					}
					}
					size="small"
				/>
			</Grid>
		</Grid>
	</ActionDialog>
}

export default CreatePlanAddonsInAccountFormDialog
