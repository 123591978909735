import React from "react"
import {
	Done as DoneIcon,
	Error as ErrorIcon,
	HighlightOff as NoChannel,
	Warning as WarningIcon
} from "@material-ui/icons"

import {
	InboxChannelLibraryClientType,
	InboxWhatsappChannelStatus
} from "@/pages/Inbox/protocols/ChannelProtocol"

import { InboxChannelStatusData } from "@/utils/channelStatus"

import colors from "@/styles/colors"
import newColors from "@/styles/newColors"

export const WhatsappChipOptions: Record<InboxWhatsappChannelStatus, InboxChannelStatusData> = {
	"no-session": {
		label: "Sem sessão",
		icon: <ErrorIcon style={{ color: "inherit" }}/>,
		customStyle: {
			backgroundColor: colors.palette.error,
			color: newColors.grey[50]
		},
		description: "Os dados de autenticação não estão salvos no servidor e não existe uma aplicação aberta tentando autenticar esse usuário."
	},
	connected: {
		label: "Conectado",
		icon: <DoneIcon style={{ color: "inherit" }}/>,
		description: "O Whatsapp Web do usuário está aberto e integrado com o inbox.",
		customStyle: {
			backgroundColor: colors.palette.confirm,
			color: newColors.grey[50]
		}
	},
	authenticating: {
		label: "Autenticando",
		icon: <WarningIcon style={{ color: "inherit" }}/>,
		description: "Uma aba do Google Chrome foi aberta e estamos aguardando a leitura de QR Code para obter os dados de autenticação do usuário e conectar o Whatsapp Web do usuário com o Inbox.",
		customStyle: {
			backgroundColor: "#1C52BF",
			color: newColors.grey[50]
		}
	},
	offline: {
		label: "Offline (reconectando)",
		icon: <WarningIcon style={{ color: "inherit" }}/>,
		description:	"Estágio de pré-reconexão. Os dados de autenticação estão salvos vamos tentar reconectar o usuário em breve.",
		customStyle: {
			backgroundColor: "#E17100",
			color: newColors.grey[50]
		}
	},
	connecting: {
		label: "Reconectando (reconectando)",
		icon: <WarningIcon style={{ color: "inherit" }}/>,
		description:	"Utilizando a sessão que já existe para reconectar.",
		customStyle: {
			backgroundColor: "#FF8A12",
			color: newColors.grey[50]
		}
	},
	conflicted: {
		label: "Conflito",
		icon: <WarningIcon style={{ color: "inherit" }}/>,
		description: "O usuário abriu um Whatsapp Web fora da nossa aplicação.",
		customStyle: {
			backgroundColor: colors.palette.warning,
			color: newColors.grey[50]
		}
	},
	unpaired: {
		label: "Inacessível (reconectando)",
		icon: <WarningIcon style={{ color: "inherit" }}/>,
		description: "Existe uma aplicação aberta para esse usuário que está re-estabelecendo conexão com o Whatsapp",
		customStyle: {
			backgroundColor: "#f5A46B",
			color: newColors.grey[50]
		}
	},
	no_channel: {
		label: "Sem canal",
		icon: <NoChannel style={{ color: "inherit" }}/>,
		description: "Nenhum canal foi criado (Não houve nenhuma conexão no whatsapp utilizando a plataforma).",
		customStyle: {
			backgroundColor: "#d62b2b",
			color: newColors.grey[50]
		}
	}
}

export const getWhatsappChannelStatusData = (status: InboxWhatsappChannelStatus): InboxChannelStatusData => {
	return {
		customStyle: WhatsappChipOptions[status]?.customStyle,
		icon: WhatsappChipOptions[status]?.icon,
		label: WhatsappChipOptions[status]?.label,
		description: WhatsappChipOptions[status]?.description
	}
}

export const getWhatsappChannelStatusPlatformName = (whatsappLibraryClient: InboxChannelLibraryClientType): string => {
	const whatsappInboxChannelLibraryClientTypeToPlatformName: Record<InboxChannelLibraryClientType, string> = {
		baileys: "Baileys"
	}

	const platformName = whatsappInboxChannelLibraryClientTypeToPlatformName[whatsappLibraryClient] || "Nenhum"

	return `Whatsapp - ${platformName}`
}

export const getWhatsappChannelStatusFilterLabel = (status: InboxWhatsappChannelStatus): string => {
	const {
		label
	} = getWhatsappChannelStatusData(status)

	return `Whatsapp - ${label}`
}
