import { ActionDialog, CustomTextField, Notification } from "@/components"
import { Grid } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { EditablePlatformTextData } from "@/pages/Inbox/protocols/EditablePlatformTextProtocol"
import { InboxAdminApi } from "@/services/Api"
import useValidation from "@/hooks/useValidation"
import { formatSlug } from "@/utils/mask"

interface CreateEditableTextFormDialogProps{
	showCreateEditableTextDialog?: boolean
	setShowCreateEditableTextDialog?: (value: boolean) => void
	getEditableTexts?: (searchValue?: string) => Promise<void>
}

const CreateEditableTextFormDialog = (props: CreateEditableTextFormDialogProps) => {
	const { showCreateEditableTextDialog, setShowCreateEditableTextDialog, getEditableTexts } = props
	const [formData, setFormData] = useState<EditablePlatformTextData>({} as EditablePlatformTextData)
	const [actionDialogLoading, setActionDialogLoading] = useState<boolean>(false)

	const { validation, triggerValidation, clearValidation } = useValidation()

	const handleCreateEditableText = async (data: EditablePlatformTextData) => {
		setActionDialogLoading(true)
		try {
			await InboxAdminApi.post("/editable-platform-text/", {
				...data
			})

			setFormData({} as EditablePlatformTextData)

			if (setShowCreateEditableTextDialog) {
				setShowCreateEditableTextDialog(false)
			}

			await getEditableTexts?.()
			Notification.success({ message: "Texto editável adicionado com sucesso." })
		} catch (error) {
			Notification.error({ message: "Houve um erro." })
			triggerValidation(error)
		}
		setActionDialogLoading(false)
	}
	const handleInputEditableTextChange = (name: string, value: string) => {
		clearValidation(name)

		const inputHandlerRules: Record<string, () => string> = {
			code: function () {
				return formatSlug(value)
			},
			default: function () {
				return value
			}
		}
		const rule = inputHandlerRules[name] ? name : "default"

		setFormData({ ...formData, [name]: inputHandlerRules[rule]() })
	}

	useEffect(() => {
		setFormData({} as EditablePlatformTextData)
	}, [showCreateEditableTextDialog])

	return <ActionDialog
		title="Criar novo texto editável"
		onSave={() => handleCreateEditableText(formData)}
		saveText="Pronto"
		onClose={() => setShowCreateEditableTextDialog?.(false)}
		loading={actionDialogLoading}
		openDialog={showCreateEditableTextDialog}
		fullWidth
		maxWidth="lg"
	>
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<CustomTextField
					value={formData.code}
					onChange={
						({ target }) => handleInputEditableTextChange("code", target.value)
					}
					variant="outlined"
					placeholder="Código"
					fullWidth
					helperText={validation.code}
					error={!!validation.code}
				/>
			</Grid>

			<Grid item xs={12}>
				<CustomTextField
					value={formData.text_ptbr}
					onChange={
						({ target }) => handleInputEditableTextChange("text_ptbr", target.value)
					}
					variant="outlined"
					placeholder="Texto"
					fullWidth
					helperText={validation.text_ptbr}
					error={!!validation.text_ptbr}
					multiline
				/>
			</Grid>
		</Grid>
	</ActionDialog>
}

export default CreateEditableTextFormDialog
