import React, { useState } from "react"
import {
	Grid,
	Typography,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody
} from "@material-ui/core"
import { Divider, Loading, Portlet } from "@/components"
import WhatsappChipSessionStatus from "@/channels/Whatsapp/components/WhatsappChipSessionStatus"

import useDidMount from "@/hooks/useDidMount"

import CardSkeleton from "@/skeletons/CardSkeleton"

import {
	InboxChannelStatus,
	InboxWhatsappChannelStatus
} from "@/pages/Inbox/protocols/ChannelProtocol"

import { InboxAdminApi } from "@/services/Api"

import useStyles from "@/pages/Inbox/InstanceMetrics/styles"

type InstancesCountByChannelStatus = Record<InboxChannelStatus, {
	count: number
}>

const InstanceMetrics: React.FC = () => {
	const classes = useStyles()

	const [loadingInstancesCountByChannelStatus, setLoadingInstancesCountByChannelStatus] = useState(true)
	const [instancesCountByChannelStatus, setInstancesCountByChannelStatus] = useState({} as InstancesCountByChannelStatus)

	const getInstancesCountByChannelStatus = async () => {
		setLoadingInstancesCountByChannelStatus(true)

		const result = await InboxAdminApi.get("/instances/metrics/instances-count-by-channel-status")

		setInstancesCountByChannelStatus(result.data)

		setLoadingInstancesCountByChannelStatus(false)
	}

	const getFormattedInfo = () => {
		let totalSavedSessions = 0
		let totalGoogleChromeOpened = 0

		Object.entries(instancesCountByChannelStatus).forEach(([key, value]) => {
			const inboxChannelStatus = key as InboxChannelStatus

			const sessionNotSavedStatus: InboxChannelStatus[] = ["authenticating", "no-session"]
			const isSessionNotSavedStatus = sessionNotSavedStatus.includes(inboxChannelStatus)

			if (!isSessionNotSavedStatus) {
				totalSavedSessions += value.count
			}

			const googleChromeClosedStatus: InboxChannelStatus[] = ["offline", "no-session"]
			const isGoogleChromeClosedStatus = googleChromeClosedStatus.includes(inboxChannelStatus)

			if (!isGoogleChromeClosedStatus) {
				totalGoogleChromeOpened += value.count
			}
		})

		return {
			totalSavedSessions,
			totalGoogleChromeOpened
		}
	}

	useDidMount(() => {
		getInstancesCountByChannelStatus()
	})

	const formattedInfo = getFormattedInfo()

	return (
		<Grid
			container
		>
			<Grid item xs={12}>
				<Typography
					className={classes.title}
				>
					QUANTIDADE DE INSTÂNCIAS POR STATUS
				</Typography>

				<Divider size={3} orientation="horizontal"/>

				<Loading
					customLoadingElement={<CardSkeleton cardHeight={480} />}
					loading={loadingInstancesCountByChannelStatus}
				>
					<Portlet
						elevation={1}
					>
						<Grid container>
							<Grid item xs={12}>
								<Grid item xs>
									<TableContainer>
										<Table
											stickyHeader
											aria-label="sticky table"
										>
											<TableHead>
												<TableRow>
													<TableCell>
														Status
													</TableCell>

													<TableCell />

													<TableCell>
														Quantidade de Instâncias
													</TableCell>
												</TableRow>
											</TableHead>

											<TableBody>
												{Object.entries(instancesCountByChannelStatus || {}).map(([key, value]) => {
													const inboxChannelStatus = key as InboxChannelStatus

													return (
														<TableRow
															key={key}
														>
															<TableCell>
																<WhatsappChipSessionStatus
																	status={inboxChannelStatus as InboxWhatsappChannelStatus}
																/>
															</TableCell>

															<TableCell />

															<TableCell>
																<Typography
																	variant="body1"
																>
																	{value.count}
																</Typography>
															</TableCell>
														</TableRow>
													)
												})}

												<TableRow>
													<TableCell />
													<TableCell>Total de sessões salvas</TableCell>
													<TableCell>{formattedInfo.totalSavedSessions}</TableCell>
												</TableRow>

												<TableRow>
													<TableCell />
													<TableCell>Total de Google Chrome abertos</TableCell>
													<TableCell>{formattedInfo.totalGoogleChromeOpened}</TableCell>
												</TableRow>
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</Grid>
						</Grid>
					</Portlet>
				</Loading>
			</Grid>
		</Grid>
	)
}

export default InstanceMetrics
