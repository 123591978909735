import { ActionDialog, CustomTextField, Divider, Loading, Notification, Portlet } from "@/components"
import CardSkeleton from "@/skeletons/CardSkeleton"
import {
	Grid,
	Typography,
	List,
	ListItem,
	ListItemText
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import useStyles from "@/pages/Account/Account/Details/components/PaymentInformationData/styles"
import { UserInInstanceCard } from "@/pages/Account/Account/Details/components/UserInInstanceCard"
import { AccountPaymentIdentificationData, AccountFullData } from "@/pages/Account/protocols/AccountProtocol"
import { AccountAdminApi } from "@/services/Api"

interface PaymentInformationDataProps {
	getInboxInstanceLoading: boolean
	accountData?: AccountFullData
}

const PaymentInformationData = (props: PaymentInformationDataProps) => {
	const { getInboxInstanceLoading, accountData } = props
	const [paymentIdentification, setPaymentIdentification] = useState<AccountPaymentIdentificationData | undefined>(accountData?.extra_data?.payment_identification)
	const [showDialog, setShowDialog] = useState<boolean>(false)
	const [dialogLabel, setDialogLabel] = useState<string>("")
	const [dataEditKey, setDataEditKey] = useState<string>("")
	const [textfieldValue, setTextfieldValue] = useState<string>("")

	const classes = useStyles()

	const openEditDialog = (key: string, value?: string) => {
		if (key === "name") {
			setDialogLabel("Name")
		}
		if (key === "email") {
			setDialogLabel("Email")
		}
		if (key === "phone") {
			setDialogLabel("Celular")
		}
		if (key === "id_integration") {
			setDialogLabel("Código de integração")
		}

		setDataEditKey(key)
		setTextfieldValue(value || "")
		setShowDialog(true)
	}

	const handleCloseEditDataDialog = () => {
		setShowDialog(false)
	}

	const handleDataChange = (value: string) => {
		setTextfieldValue(value)
	}

	const handleUpdatePaymentInformation = async () => {
		try {
			await AccountAdminApi
				.put(`/account/${accountData?.id}/extra-data`, {
					payment_identification: {
						[dataEditKey]: textfieldValue
					}
				})

			setPaymentIdentification({ ...paymentIdentification, [dataEditKey]: textfieldValue })

			setShowDialog(false)
			Notification.success({ message: "Usuário atualizado com sucesso!" })
		} catch (error) {
			Notification.error({ message: "Houve um erro" })
		}
	}

	useEffect(() => {
		setPaymentIdentification(accountData?.extra_data?.payment_identification)
	}, [accountData])

	return <Grid item xs={12} md={6}>
		<Typography
			variant="h2"
			className={classes.title}
		>
                    DADOS PARA IDENTIFICAÇÃO NA PLATAFORMA DE PAGAMENTO
		</Typography>

		<Divider size={2} orientation="horizontal"/>

		<Loading
			customLoadingElement={
				<CardSkeleton
					cardHeight={200}
				/>}
			loading={getInboxInstanceLoading}
		>
			<Portlet
				style={{
					padding: "8px 16px",
					minHeight: "220px"
				}}
				elevation={1}
			>
				<Grid item xs>
					<List>
						<ListItem
							className={classes.listItem}
						>
							<ListItemText
								primary={<Typography className={classes.listPrimaryText} variant="caption">NOME</Typography>}
								secondary={
									<UserInInstanceCard
										name={ paymentIdentification?.name ? paymentIdentification.name : "---"}
										onClickEditButton={() => openEditDialog("name", paymentIdentification?.name)}
									/>
								} />
						</ListItem>

						<ListItem
							className={classes.listItem}
						>
							<ListItemText
								primary={<Typography className={classes.listPrimaryText} variant="caption">EMAIL</Typography>}
								secondary={
									<UserInInstanceCard
										name={ paymentIdentification?.email ? paymentIdentification.email : "---"}
										onClickEditButton={() => openEditDialog("email", paymentIdentification?.email)}
									/>
								} />
						</ListItem>

						<ListItem
							className={classes.listItem}
						>
							<ListItemText
								primary={<Typography className={classes.listPrimaryText} variant="caption">CELULAR</Typography>}
								secondary={
									<UserInInstanceCard
										name={ paymentIdentification?.phone ? paymentIdentification?.phone : "---"}
										onClickEditButton={() => openEditDialog("phone", paymentIdentification?.phone)}
									/>
								}
							/>
						</ListItem>

						<ListItem
							className={classes.listItem}
						>
							<ListItemText
								primary={<Typography className={classes.listPrimaryText} variant="caption">CÓDIGO DE INTEGRAÇÃO</Typography>}
								secondary={
									<UserInInstanceCard
										name={paymentIdentification?.id_integration ? paymentIdentification?.id_integration : "---"}
										onClickEditButton={() => openEditDialog("id_integration", paymentIdentification?.id_integration)}
									/>
								}
							/>
						</ListItem>

					</List>
				</Grid>
			</Portlet>
		</Loading>

		<ActionDialog
			title={`Editar ${dialogLabel.toLocaleLowerCase()}`}
			onSave={() => handleUpdatePaymentInformation()}
			saveText="Salvar"
			onClose={handleCloseEditDataDialog}
			openDialog={showDialog}
			fullWidth
		>
			<Grid container>
				<Grid item xs={12}>
					<CustomTextField
						onChange={
							({ target }) => handleDataChange(target.value)
						}
						value={textfieldValue}
						variant="outlined"
						placeholder={dialogLabel}
						fullWidth
					/>
				</Grid>

				<Divider size={2} orientation="horizontal"/>
			</Grid>
		</ActionDialog>
	</Grid>
}

export default PaymentInformationData
