import { AccountAdminApi } from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import { getStatusCode } from "@/utils/response"

export type UserData = {
	id: number
	name: string
	email: string
}

type UserInfo = {
	authToken: string
	userData: UserData
}

class UserService {
	private static cache: UserInfo | null = null

	async getInfo (): Promise<UserInfo | null> {
		try {
			/**
			 * That's a way to avoid making multiple repeated requests
			 * throughout the application lifetime.
			 */
			if (UserService.cache) {
				return UserService.cache
			}

			const response = await AccountAdminApi.get("/user")

			const userInfo = response.data

			UserService.cache = userInfo

			return {
				authToken: UserService?.cache?.authToken || "",
				userData: userInfo
			}
		} catch (error) {
			ErrorHandlerService.handle(error)
			return null
		}
	}

	purgeCache (): void {
		UserService.cache = null
	}

	async isLoggedIn (): Promise<boolean> {
		let statusCode: number

		try {
			const response = await AccountAdminApi
				.get("/user/auth/check")

			statusCode = response.status
		} catch (error) {
			ErrorHandlerService.handle(error)

			statusCode = getStatusCode(error) || 500
		}

		/**
		 * User is not logged in if the status code
		 * returned by that request is 401 (Unauthorized).
		 */
		if (statusCode === 401) {
			return false
		}

		return true
	}
}

export default new UserService()
