import IMask from "imask"
import slugfy from "slugify"

export const formatPhoneNumber = (rawNumber?: string) => {
	let formattedPhoneNumber = rawNumber

	if (rawNumber) {
		const phoneNumberMask = IMask.createMask({
			mask: [
				{
					mask: "+55 (00) 0000-0000"
				},
				{
					mask: "+55 (00) 00000-0000"
				}
			]
		})

		formattedPhoneNumber = phoneNumberMask.resolve(rawNumber)
	}

	return formattedPhoneNumber
}

export const formatUrl = (rawUrl: string) => {
	let formattedUrl: string = rawUrl

	if (rawUrl) {
		const validPrefixes = ["https", "http"]

		// eslint-disable-next-line
		const asterisks = [...new Array(rawUrl.length)].map(_ => "*").join("")

		const defaultPrefix = validPrefixes[0]

		const currentUsedPrefixIndex = validPrefixes.findIndex(prefix => rawUrl.startsWith(prefix))

		let prefix: string

		if (currentUsedPrefixIndex === -1) {
			prefix = defaultPrefix
		} else {
			prefix = validPrefixes[currentUsedPrefixIndex]
		}

		const urlMask = IMask.createMask({
			mask: `${prefix}://${asterisks}`
		})

		formattedUrl = urlMask.resolve(formattedUrl)

		formattedUrl = formattedUrl
			.replace(/\s/g, "")
	}

	return formattedUrl
}

export const formatEmail = (rawEmail: string) => {
	let formattedEmail: string = rawEmail

	if (rawEmail) {
		formattedEmail = rawEmail
			.toLowerCase()
			.replace(/\s/g, "")
	}

	return formattedEmail
}

export const formatBrazilianCurrency = (value: number) => {
	const formattedCurrency = Intl.NumberFormat(
		"pt-BR",
		{
			style: "currency",
			currency: "BRL"
		}).format(value)

	return formattedCurrency
}

export const formatSlug = (text: string): string => {
	return slugfy(text, {
		replacement: "_",
		remove: /[*+~.()[\]{}'"!:=?/;><]/g,
		lower: true,
		trim: false
	})
}

export const formatIntNumber = (text: string): number => {
	const textWithoutNonDigits = text.replace(/\D/gm, "")
	return Number(textWithoutNonDigits)
}

export const formatCnpj = (text: string) => {
	return text.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
}

export const formatStringToPascalCase = (text: string) => {
	return text.toLowerCase().replace(new RegExp(/\w/), s => s.toUpperCase())
}
