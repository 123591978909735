import { makeStyles } from "@material-ui/core"

import newColors from "@/styles/newColors"

const useStyles = makeStyles({
	copyInviteLinkButton: {
		width: "100%",
		"&:hover": {
			backgroundColor: newColors.getPrimaryColor(),
			color: newColors.grey[0],
			opacity: 0.9
		}
	},
	mfaContainer: {
		display: "flex",
		alignItems: "center"
	}
})

export default useStyles
