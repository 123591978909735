import React from "react"
import {
	Chip
} from "@material-ui/core"

import useStyles from "@/pages/Inbox/Instances/components/UserInInstanceCard/styles"
import { Star as StarIcon } from "@material-ui/icons"

type TeamDataProps = {
	id: number
	instanceId?: number
	name: string
}

type UserInInstanceCardProps = {
	name?: string
	email?: string,
	status?: string,

	role_code?: string

	teams?: TeamDataProps[]

	onClickEditButton?: () => void
	onClickDeleteButton?: () => void
	onClickBlockButton?: () => void
}

const UserInInstanceCard: React.FC<UserInInstanceCardProps> = (props) => {
	const { role_code } = props

	const classes = useStyles()

	return (
		<Chip
			variant="outlined" className={classes.roleChip}
			label={role_code === "admin" ? "Dono" : "Atendente"}
			style={{
				background: role_code === "admin" ? "#E809AE" : "#d5da81"
			}}
			icon={<StarIcon style={{
				fontSize: "14px",
				color: "white"
			}}/>}
		/>

	)
}

export default UserInInstanceCard
