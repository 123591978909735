import {
	Divider,
	Portlet,
	Loading,
	PopConfirm,
	Notification,
	ActionDialog,
	CustomTextField
} from "@/components"
import {
	Button,
	Grid,
	IconButton,
	InputAdornment,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography
} from "@material-ui/core"
import {
	Search as SearchIcon,
	Close as CloseIcon,
	Delete as DeleteIcon,
	Edit as EditIcon
} from "@material-ui/icons"
import CardSkeleton from "@/skeletons/CardSkeleton"
import useCustomStyles from "@/styles/custom"
import useStyles from "@/pages/Inbox/EditablePlatformText/styles"

import { InboxAdminApi } from "@/services/Api"
import React, { useState } from "react"
import useValidation from "@/hooks/useValidation"
import useDidMount from "@/hooks/useDidMount"
import useDebounce from "@/hooks/useDebounce"
import { formatDateAndTimeBrazilianDate } from "@/utils/time"
import { formatSlug } from "@/utils/mask"

import {
	EditablePlatformTextColumns,
	EditablePlatformTextData,
	GetEditablePlatformTextResponse
} from "@/pages/Inbox/protocols/EditablePlatformTextProtocol"
import CreateEditableTextFormDialog from "@/pages/Inbox/EditablePlatformText/CreateEditableTextFormDialog/index"

const TableColumns: EditablePlatformTextColumns[] = [
	{
		name: "id",
		label: "ID"
	},
	{
		name: "code",
		label: "Código"
	},
	{
		name: "text_ptbr",
		label: "Texto PT-BR"
	},
	{
		name: "created_at",
		label: "Data de Criação",
		formatDate: (value: string) => formatDateAndTimeBrazilianDate(value)?.dateAndTime || " - "
	}
]

const EditablePlatformText = () => {
	const classes = useStyles()
	const customClasses = useCustomStyles()

	const [editableTexts, setEditableTexts] = useState<GetEditablePlatformTextResponse>(
		{} as GetEditablePlatformTextResponse
	)
	const [searchInputValue, setSearchInputValue] = useState<string | null>("")
	const [loading, setLoading] = useState<boolean>(true)
	const [actionDialogLoading, setActionDialogLoading] = useState<boolean>(false)
	const [actualEditEditableText, setActualEditEditableText] = useState<EditablePlatformTextData>({} as EditablePlatformTextData)
	const [showEditEditableTextDialog, setShowEditEditableTextDialog] = useState<boolean>(false)
	const [showCreateEditableTextDialog, setShowCreateEditableTextDialog] = useState<boolean>(false)
	const { validation, triggerValidation, clearValidation } = useValidation()

	const getEditableTexts = async (searchValue?: string | null) => {
		setLoading(true)
		try {
			const { data } = await InboxAdminApi
				.get("/editable-platform-text", {
					params: {
						searchValue
					}
				})

			setEditableTexts(data)
		} catch (error) {
			triggerValidation(error)
		}
		setLoading(false)
	}

	useDebounce(
		async () =>	await getEditableTexts(searchInputValue),
		searchInputValue,
		1000
	)

	const handleSearchChange = async (search: string) => {
		const searchValue: string = search

		setSearchInputValue(searchValue)
	}

	const handleFinishEditEditableText = async (editableText: EditablePlatformTextData) => {
		setActionDialogLoading(true)
		try {
			await InboxAdminApi.put(`/editable-platform-text/${editableText.id}`, editableText)

			setShowEditEditableTextDialog(false)
			await getEditableTexts()
			Notification.success({ message: "Os dados foram salvos." })
		} catch (error) {
			Notification.error({ message: "Houve um erro." })
			triggerValidation(error)
		}

		setActionDialogLoading(false)
	}

	const handleEditEditableText = (editableText: EditablePlatformTextData) => {
		setActualEditEditableText(editableText)
		clearValidation("code")
		clearValidation("text_ptbr")
		setShowEditEditableTextDialog(true)
	}

	const handleInputEditableTextChange = (name: string, value: string) => {
		clearValidation(name)

		const inputHandlerRules: Record<string, () => string> = {
			code: function () {
				return formatSlug(value)
			},
			default: function () {
				return value
			}
		}
		const rule = inputHandlerRules[name] ? name : "default"

		setActualEditEditableText({ ...actualEditEditableText, [name]: inputHandlerRules[rule]() })
	}

	const deleteOneEditableText = async (editableTextId: number) => {
		try {
			await InboxAdminApi.delete(`/editable-platform-text/${editableTextId}`)

			await getEditableTexts()
			Notification.success({ message: "Texto editável apagado com sucesso." })
		} catch (error) {
			triggerValidation(error)
		}
	}

	const handleDeleteEditableText = async (editableTextId: number) => {
		const props = {
			description: "Deseja deletar este texto editável?",
			confirmButtonText: "EXCLUIR",
			onConfirm: () => deleteOneEditableText(editableTextId)
		}
		PopConfirm.open(props)
	}

	useDidMount(() => {
		getEditableTexts()
	})

	return <Grid>
		<Grid item xs={12}>
			<Grid item>
				<Button
					variant="contained"
					className={customClasses.buttonAction}
					onClick={() => {
						setShowCreateEditableTextDialog(true)
					}}
				>
					NOVO TEXTO EDITÁVEL
				</Button>
			</Grid>

			<Typography
				color="textPrimary"
				className={classes.title}
			>
				Textos editáveis
			</Typography>

			<Divider size={1} orientation="horizontal" />

			<Portlet
				elevation={1}
			>
				<Divider size={3} orientation="horizontal" />
				<Grid container>
					<Grid container justify="flex-end">
						<Grid item xs={12} md={5}>
							<TextField
								value={searchInputValue}
								placeholder="Pesquisar"
								variant="outlined"
								fullWidth
								size="small"

								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
									endAdornment: searchInputValue && (
										<IconButton
											size="small"
											onClick={() => handleSearchChange("")}
										>
											<CloseIcon
												fontSize="small"
											/>
										</IconButton>
									)
								}}

								onChange={({ target }) =>
									handleSearchChange(target.value)
								}
							/>
						</Grid>
					</Grid>

					<Divider size={3} orientation="horizontal" />

					<Grid item xs={12}>
						<Loading
							customLoadingElement={
								<CardSkeleton
									cardHeight={200}
								/>}
							loading={loading}
						>
							{
								editableTexts.length > 0
									? 										<Paper className={classes.paper}>
										<TableContainer>
											<Table stickyHeader aria-label="sticky table">
												<TableHead>
													<TableRow>
														{TableColumns
															.map((column, index) => (
																<TableCell
																	key={index}
																>
																	{
																		column.label
																	}
																</TableCell>
															))}
														<TableCell align="center">
																Ações
														</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{
														editableTexts.map((editableText, tableRowIndex: number) => {
															const tableRowInfo = {
																column: 0,
																getCellKey: function () {
																	++this.column
																	return `${tableRowIndex}_${this.column}`
																}
															}
															return (
																<TableRow
																	hover
																	tabIndex={-1}
																	key={tableRowIndex}
																	className={classes.tableRow}
																>
																	{TableColumns
																		.map((tableColumn) => {
																			let value = editableText[tableColumn.name] as string
																			if (tableColumn?.formatDate) {
																				value = tableColumn.formatDate(value)
																			}
																			return (
																				<TableCell
																					key={tableRowInfo.getCellKey()}
																					style={tableColumn?.style}
																					align={tableColumn?.align}
																				>
																					{
																						tableColumn.name === "text_ptbr"
																							? (
																								// Replaces space for &nbsp to let easier to put double space in text
																								<p style={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={{ __html: value.replaceAll(" ", "&nbsp") }}></p>
																							)
																							: value
																					}
																				</TableCell>
																			)
																		}
																		)
																	}

																	<TableCell
																		align="center"
																		style={{ minWidth: "95px" }}
																		key={tableRowInfo.getCellKey()}
																	>
																		<IconButton
																			className={classes.editIcon}
																			onClick={(event) => {
																				event.preventDefault()
																				handleEditEditableText(editableText)
																			}}
																		>
																			<EditIcon fontSize="small" />
																		</IconButton>

																		<IconButton
																			className={classes.deleteIcon}
																			onClick={(event) => {
																				event.preventDefault()
																				handleDeleteEditableText(editableText.id)
																			}}
																		>
																			<DeleteIcon fontSize="small" />
																		</IconButton>

																	</TableCell>

																</TableRow>
															)
														})}
												</TableBody>
											</Table>
										</TableContainer>

									</Paper>
									: 										(
										<>
											<Divider size={3} orientation="horizontal" />
											<Typography align="center" variant="h2">
													Nenhum <b>texto editável</b> cadastrado
											</Typography>
										</>
									)
							}
						</Loading>

						<ActionDialog
							title="Alterar texto editável"
							onSave={() => handleFinishEditEditableText(actualEditEditableText)}
							saveText="Salvar"
							onClose={() => setShowEditEditableTextDialog(false)}
							loading={actionDialogLoading}
							openDialog={showEditEditableTextDialog}
							fullWidth
							maxWidth="lg"
						>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<CustomTextField
										value={actualEditEditableText.code}
										onChange={
											({ target }) => handleInputEditableTextChange("code", target.value)
										}
										variant="outlined"
										placeholder="Código"
										fullWidth
										helperText={validation.code}
										error={Boolean(validation.code)}
									/>
								</Grid>
								<Grid item xs={12}>
									<CustomTextField
										value={actualEditEditableText.text_ptbr}
										onChange={
											({ target }) => handleInputEditableTextChange("text_ptbr", target.value)
										}
										variant="outlined"
										placeholder="Texto"
										fullWidth
										helperText={validation.text_ptbr}
										error={Boolean(validation.text_ptbr)}
										multiline
									/>
								</Grid>
								<Grid item xs={12}>

								</Grid>
							</Grid>
						</ActionDialog>

						<CreateEditableTextFormDialog
							showCreateEditableTextDialog={showCreateEditableTextDialog}
							setShowCreateEditableTextDialog={setShowCreateEditableTextDialog}
							getEditableTexts={getEditableTexts}
						/>
					</Grid>
				</Grid>
			</Portlet>
		</Grid>
	</Grid>
}

export default EditablePlatformText
