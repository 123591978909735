import { Divider } from "@/components"
import useDidMount from "@/hooks/useDidMount"
import { AccountAdminApi } from "@/services/Api"
import { Grid, Typography, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core"
import React, { useState } from "react"
import { AccountType } from "@/pages/Account/protocols/AccountProtocol"

type Account = {
	accountId?: number
	accountEmail?: string
	financeEmail?: string
	accountType?: AccountType
	subIsTrial?: boolean
	subIsFinished?: boolean
	subIsExpired?: boolean
	subIsActive?: boolean
	planCode?: string
	hotmartEmail?: string
	emailMatch?: "Finance" | "Account" | "FinanceDomain" | "AccountDomain"
	status: "ActiveHotmartAndActiveLetalk"
	| "ActiveHotmartAndTrialLetalk"
	| "ActiveHotmartAndExpiredLetalk"
	| "ActiveHotmartAndFinishedLetalk"
	| "ActiveHotmartAndNotFoundLetalk"
	| "NotFoundHotmartAndActiveLetalk"
}

type Section = {
	title: string
	data: Account[]
	titleColor?: string
}

const Hotmart = () => {
	const [accounts, setAccounts] = useState<Account[]>([])
	const activeHotmartAndActiveLetalk = accounts.filter(acc => acc.status === "ActiveHotmartAndActiveLetalk")
	const activeHotmartAndTrialLetalk = accounts.filter(acc => acc.status === "ActiveHotmartAndTrialLetalk")
	const activeHotmartAndExpiredLetalk = accounts.filter(acc => acc.status === "ActiveHotmartAndExpiredLetalk")
	const activeHotmartAndFinishedLetalk = accounts.filter(acc => acc.status === "ActiveHotmartAndFinishedLetalk")
	const activeHotmartAndNotFoundLetalk = accounts.filter(acc => acc.status === "ActiveHotmartAndNotFoundLetalk")
	const notFoundHotmartAndActiveLetalk = accounts.filter(acc => acc.status === "NotFoundHotmartAndActiveLetalk")

	const sections: Section[] = [{
		title: "Assinaturas ativas na Hotmart e trial na Letalk",
		data: activeHotmartAndTrialLetalk
	}, {
		title: "Assinaturas ativas na Hotmart e expiradas na Letalk",
		data: activeHotmartAndExpiredLetalk
	}, {
		title: "Assinaturas ativas na Hotmart e finalizadas na Letalk",
		data: activeHotmartAndFinishedLetalk
	}, {
		title: "Assinaturas ativas na Hotmart e account não encontrada na Letalk",
		data: activeHotmartAndNotFoundLetalk
	}, {
		title: "Assinaturas ativas na Letalk (não trial) que não identificamos assinatura na Hotmart",
		data: notFoundHotmartAndActiveLetalk
	}, {
		title: "Assinaturas ativas na Hotmart e ativas na Letalk",
		data: activeHotmartAndActiveLetalk,
		titleColor: "green"
	}]

	const getData = async () => {
		const response = await AccountAdminApi.get("/hotmart")
		setAccounts(response.data)
	}

	useDidMount(() => {
		getData()
	})

	const AccountTable = (accounts: Account[]) => {
		return (
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							Account Id
						</TableCell>

						<TableCell>
							Account Type
						</TableCell>

						<TableCell>
							Email Account
						</TableCell>

						<TableCell>
							Email Financeiro
						</TableCell>

						<TableCell>
							Email Hotmart
						</TableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{accounts.map((account, index) => (
						<TableRow key={index}>
							<TableCell>
								{account.accountId || "-"}
							</TableCell>

							<TableCell>
								{account.accountType || "-"}
							</TableCell>

							<TableCell>
								{account.accountEmail || "-"}
							</TableCell>

							<TableCell>
								{account.financeEmail || "-"}
							</TableCell>

							<TableCell>
								{account.hotmartEmail || "-"}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		)
	}

	return (
		<Grid item xs={12}>
			<Typography variant="h3">
				Assinaturas
			</Typography>

			{sections.map(({ title, data, titleColor }) => (
				<>
					<Divider orientation="horizontal" size={3} />

					<Typography variant="h4" style={{ color: titleColor || "red" }}>
						{title} [{data.length}]
					</Typography>

					{data.length > 0 && (
						<Grid container>
							{AccountTable(data)}
						</Grid>
					)}
				</>
			))}
		</Grid>
	)
}

export default Hotmart
