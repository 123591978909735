/**
 * This file should be used in place of colors.ts
 * This is a new version, according to the new design system
 */
const newColors = {
	getPrimaryColor: function (): string {
		return this.purple[500]
	},
	grey: {
		0: "#FFFFFF",
		50: "#F7F7F8",
		100: "#EEEEF0",
		200: "#D9D9DE",
		300: "#B8B9C1",
		400: "#91939F",
		500: "#747583",
		600: "#5D5E6C",
		700: "#4C4D58",
		800: "#41414B",
		900: "#393941",
		950: "#29292E"
	},
	purple: {
		50: "#F9F6FE",
		100: "#F1E9FE",
		200: "#E6D7FD",
		300: "#D3B8FA",
		400: "#B88AF6",
		500: "#9855EE",
		600: "#873CE1",
		700: "#722BC5",
		800: "#6128A1",
		900: "#502182",
		950: "#340C5F"
	},
	green: {
		50: "#F6FCE9",
		100: "#EAF8CF",
		200: "#D5F1A5",
		300: "#B8E670",
		400: "#93D334",
		500: "#7DBC26",
		600: "#60961A",
		700: "#4A7219",
		800: "#3C5B19",
		900: "#354E19",
		950: "#192A09"
	},
	red: {
		50: "#FEF2F2",
		100: "#FEE2E2",
		200: "#FDCBCB",
		300: "#FBA7A6",
		400: "#F67473",
		500: "#EE5655",
		600: "#D92A29",
		700: "#B71F1E",
		800: "#971E1D",
		900: "#7E1F1E",
		950: "#440B0B"
	},
	yellow: {
		50: "#FFF8EB",
		100: "#FDE9C8",
		200: "#FBD28C",
		300: "#F9B450",
		400: "#F79722",
		500: "#F1760F",
		600: "#D5540A",
		700: "#B1370C",
		800: "#902B10",
		900: "#762511",
		950: "#440F04"
	},
	blue: {
		50: "#F0F9FF",
		100: "#E0F2FE",
		200: "#BBE5FC",
		300: "#7ED2FB",
		400: "#4AC0F7",
		500: "#10A2E7",
		600: "#0481C5",
		700: "#0467A0",
		800: "#085884",
		900: "#0D496D",
		950: "#092E48"
	}
}

export default newColors
