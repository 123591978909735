import React, { ReactNode } from "react"
import { Link, useLocation } from "react-router-dom"
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core"

import useStyles from "@/components/ListItemMenu/styles"

type ListItemMenuProps = {
	to: string
	icon: ReactNode
	text?: string

	onClick?: () => void
}

const ListItemMenu: React.FC<ListItemMenuProps> = (props) => {
	const { to, icon, text, onClick } = props

	const classes = useStyles()
	const location = useLocation()

	const isMenuItemActive = (route: string) => location.pathname.includes(route)

	const handleClick = () => {
		onClick && onClick()
	}

	return (
		<ListItem
			button
			component={Link}
			to={to}
			key={to}
			className={`${isMenuItemActive(to) ? classes.listItemActive : ""}`}
			onClick={handleClick}
		>
			<ListItemIcon className={classes.listItemIcon}>
				{icon}
			</ListItemIcon>
			{
				text && <ListItemText primary={text} />
			}
		</ListItem>
	)
}

export default ListItemMenu
