import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	container: {
		height: "100%"
	}
})

type CardSkeleton = {
	cardHeight: number
}

const CardSkeleton = (props: CardSkeleton) => {
	const {
		cardHeight
	} = props

	const classes = useStyles()

	return (
		<Grid
			container
			alignItems="center"
			direction="column"
			className={classes.container}
		>
			<Skeleton
				variant="rect"
				width="100%"
				height={cardHeight}
				animation="wave"
			/>
		</Grid>
	)
}

export default CardSkeleton
