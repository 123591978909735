import { InfoDialog, JsonEditor, Notification } from "@/components"
import { Grid } from "@material-ui/core"
import React from "react"
import { AddonData, AddonLimits } from "@/pages/Account/protocols/AddonProtocol"
import { AccountAdminApi } from "@/services/Api"

interface UpdateLimitsFormDialogProps{
	addonData: AddonData
	showDialog?: boolean
	setShowDialog?: (value: boolean) => void
	getAddons?: (searchValue?: string) => Promise<void>
}

const UpdateLimitsFormDialog = (props: UpdateLimitsFormDialogProps) => {
	const { addonData, showDialog, setShowDialog, getAddons } = props

	const handleUpdateLimits = async (limits: AddonLimits) => {
		await AccountAdminApi.put(`/plan-addons/${addonData.id}`, {
			...addonData,
			limits
		})

		if (setShowDialog) {
			setShowDialog(false)
		}

		await getAddons?.()
		Notification.success({ message: "Limites atualizados." })
	}

	return <InfoDialog
		title="Atualizar limites"
		onClose={() => setShowDialog && setShowDialog(false)}
		openDialog={showDialog}
		fullWidth
	>
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<JsonEditor
					value={addonData.limits as string}
					height="300px"
					onSave={ async (data) => {
						await handleUpdateLimits(data as AddonLimits)
					}}
				/>
			</Grid>
		</Grid>
	</InfoDialog>
}

export default UpdateLimitsFormDialog
