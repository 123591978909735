import React, { useState } from "react"
import { useHistory } from "react-router-dom"

import {
	Container,
	Grid,
	InputAdornment,
	IconButton,
	Button,
	CircularProgress,
	TextField,
	FormHelperText
} from "@material-ui/core"
import {
	Visibility as ShowPassword,
	VisibilityOff as HiddenPassword
} from "@material-ui/icons"

import { AccountAdminApi } from "@/services/Api"
import AuthService from "@/services/Auth"

import useValidation from "@/hooks/useValidation"

import { formatEmail } from "@/utils/mask"

import { Divider, Portlet } from "@/components"

import useStyles from "@/pages/Auth/styles"

import logoImg from "@/assets/images/logos/letalk-logo.svg"
import useDidMount from "@/hooks/useDidMount"

type SignInDataType = {
	email: string
	password: string
}

const SignIn = () => {
	const [visiblePassword, setVisiblePassword] = useState<boolean>(false)
	const [signInData, setSignInData] = useState<SignInDataType>({} as SignInDataType)
	const [loadingSignIn, setLoadingSignIn] = useState<boolean>(false)

	const {
		validation,
		clearValidation,
		triggerValidation
	} = useValidation()
	const classes = useStyles()
	const history = useHistory()

	const handleSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		setLoadingSignIn(true)

		try {
			await AccountAdminApi
				.post("/auth/signin", {
					email: signInData.email,
					password: signInData.password
				})

			history.push("/")
		} catch (error) {
			triggerValidation(error)
		}

		setLoadingSignIn(false)
	}

	const handleSignInDataChange = (type: string, value: string) => {
		setSignInData({ ...signInData, [type]: value })

		clearValidation(type)
		clearValidation("signin_form")
	}

	const handleClickShowPassword = () => {
		setVisiblePassword(!visiblePassword)
	}

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault()
	}

	const checkIfLoggedIn = async () => {
		const isLoggedIn = await AuthService
			.isLoggedIn()

		if (isLoggedIn) {
			history.push("/inbox")
		}
	}

	useDidMount(() => {
		checkIfLoggedIn()
	})

	return (
		<Container maxWidth={"md"}>
			<Grid container justify="center" className={classes.authContainer}>
				<Grid item xs={12} sm={10} md={9}>
					<Grid container justify="center" className={classes.authContent}>
						<Grid item xs={12} sm={10} md={9} className={classes.authWrapper}>
							<Portlet
								elevation={1}
								className={classes.portlet}>

								<img src={logoImg} alt="Letalk" className={classes.logoImg} />

								<Divider size={4} orientation="horizontal" />
								<form onSubmit={handleSignIn}>
									<TextField
										id="input-email"
										name="email"
										value={signInData.email}
										onChange={
											({ target }) => handleSignInDataChange("email", formatEmail(target.value))
										}
										variant="outlined"
										label="Email"
										fullWidth
										helperText={validation.email}
										error={Boolean(validation.email)}
									/>

									<Divider orientation="horizontal" size={2}/>

									<TextField
										id="input-password"
										type={visiblePassword ? "text" : "password"}
										value={signInData.password}
										onChange={
											({ target }) => handleSignInDataChange("password", target.value)
										}
										label="Senha"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
														edge="end"
													>
														{visiblePassword ? <ShowPassword /> : <HiddenPassword />}
													</IconButton>
												</InputAdornment>
											)
										}}
										fullWidth
										variant="outlined"
										helperText={validation.password}
										error={Boolean(validation.password)}
									/>

									<Divider orientation="horizontal" size={4}/>

									<Button
										color="primary"
										variant="contained"
										type='submit'
										disabled={loadingSignIn}
										endIcon={loadingSignIn && (
											<CircularProgress size={20} color="inherit" />
										)}
										fullWidth
									>
									Entrar
									</Button>

									<FormHelperText
										error={validation.signin_form}
										id="form-helper-text"
										variant="outlined"
										className={classes.signInFormHelperText}
									>
										{validation.signin_form}
									</FormHelperText>
								</form>
							</Portlet>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	)
}

export default SignIn
