import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	paper: {
		borderRadius: theme.spacing(1)
	},
	dialogTitle: {
		padding: theme.spacing(4)
	},
	dialogContent: {
		position: "relative",
		padding: theme.spacing(3)
	},
	closeIcon: {
		position: "absolute",
		top: 0,
		right: 0
	}
}))

export default useStyles
