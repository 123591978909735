import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"
import newColors from "@/styles/newColors"

const useStyles = makeStyles({
	avatar: {
		width: 70,
		height: 70
	},
	title: {
		color: colors.grayScale[1],
		textTransform: "uppercase",
		fontWeight: "bold"
	},
	listItem: {
		paddingTop: 0,
		paddingBottom: 0
	},
	tableRow: {
		cursor: "auto",
		textDecoration: "none !important"
	},
	listPrimaryText: {
		fontWeight: "bold"
	},
	listSecondaryText: {
		fontSize: "16px",
		fontWeight: 500,
		lineHeight: "24px"
	},
	clearSessionBtn: {
		color: colors.palette.error
	},
	clusterStatusContainer: {
		position: "relative"
	},
	reloadButton: {
		padding: "0px"
	},
	instanceLink: {
		textDecoration: "none !important",
		color: colors.palette.link
	},
	accountLink: {
		display: "flex",
		alignItems: "center",
		gap: "2px",
		textDecoration: "none !important",
		color: colors.palette.link
	},
	copyInviteLinkButton: {
		width: "100%",
		"&:hover": {
			backgroundColor: newColors.getPrimaryColor(),
			color: newColors.grey[0],
			opacity: 0.9
		}
	},
	moduleItem: {
		border: "1px solid #EEEEF0",
		padding: "16px",
		borderRadius: "8px"
	}
})

export default useStyles
