import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(() => ({
	filterInput: {
		backgroundColor: colors.grayScale[11],
		"& fieldset": {
			borderRadius: 3
		}
	}
}))

export default useStyles
