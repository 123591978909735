import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid } from "@material-ui/core"

import { Divider } from "@/components"

const WhatsappPodUsageDialogSkeleton = () => {
	return (
		<Grid
			container
			spacing={6}
		>
			<Grid
				item
				xs={12}
			>
				<Grid
					container
					direction="column"
				>
					<Skeleton
						variant="rect"
						width="14%"
						height={24}
					/>

					<Divider orientation="horizontal" size={1} />

					<Skeleton
						variant="rect"
						width="40%"
						height={32}
					/>
				</Grid>
			</Grid>

			<Grid
				item
				xs={12}
			>
				<Grid
					container
					direction="column"
				>
					<Skeleton
						variant="rect"
						width="25%"
						height={24}
					/>

					<Divider orientation="horizontal" size={1} />

					<Skeleton
						variant="rect"
						width="60%"
						height={24}
					/>

					<Divider orientation="horizontal" size={1} />

					<Skeleton
						variant="rect"
						width="55%"
						height={24}
					/>
				</Grid>
			</Grid>

			<Grid
				item
				xs={12}
			>
				<Grid
					container
					direction="column"
				>
					<Skeleton
						variant="rect"
						width="35%"
						height={24}
					/>

					<Divider orientation="horizontal" size={1} />

					<Skeleton
						variant="rect"
						width="100%"
						height={120}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default WhatsappPodUsageDialogSkeleton
