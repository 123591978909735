import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"
import { lighten } from "@material-ui/core/styles"

export const navbarHeight = "56px"

const useStyles = makeStyles(theme => ({
	navbarContainer: {
		backgroundColor: colors.palette.primary,
		height: navbarHeight,
		color: colors.grayScale[11]
	},
	titleText: {
		fontSize: "15px"
	},
	nameText: {
		textTransform: "uppercase"
	},
	emailText: {
		fontWeight: "normal"
	},
	profile: {
		cursor: "pointer"
	},
	iconButton: {
		padding: "6px"
	},
	menuIcon: {
		fontSize: theme.spacing(4)
	},
	logoImg: {
		width: 87
	},
	search: {
		"& > div": {
			backgroundColor: lighten(colors.palette.primary, 0.15)
		},
		"& > div::before": {
			borderColor: "white"
		},
		"& input": {
			color: "white"
		},
		"& svg": {
			color: "white"
		}
	}
}))

export default useStyles
