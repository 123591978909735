import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles({
	avatar: {
		width: 70,
		height: 70
	},
	title: {
		color: colors.grayScale[1],
		textTransform: "uppercase",
		fontWeight: "bold"
	},
	listItem: {
		paddingTop: 0,
		paddingBottom: 0
	},
	listPrimaryText: {
		fontWeight: "bold"
	},
	listSecondaryText: {
		fontSize: "16px",
		fontWeight: 500,
		lineHeight: "24px"
	},
	clearSessionBtn: {
		margin: "0 auto",
		color: colors.palette.error,
		fontWeight: 500,
		display: "flex",
		backgroundColor: colors.grayScale[11],
		borderColor: colors.palette.error,
		"&:hover": {
			boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
			opacity: 0.7
		}
	},
	clusterStatusContainer: {
		position: "relative"
	},
	reloadButton: {
		padding: "0px"
	},
	instanceLink: {
		textDecoration: "none !important"
	},
	instanceList: {
		maxHeight: "245px",
		overflow: "auto"
	},
	tableRow: {
		cursor: "auto",
		textDecoration: "none !important"
	},
	tableCell: {
		display: "flex",
		justifyContent: "flex-end"
	},
	launchIcon: {
		padding: "5px"
	},
	tabContent: {
		paddingTop: "30px"
	}
})

export default useStyles
