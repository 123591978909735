import { InfoDialog } from "@/components"
import { Grid, MenuItem, MenuList, Select, Switch, Typography } from "@material-ui/core"
import { InboxChannel, InboxChannelLibraryClientType } from "@/pages/Inbox/protocols/ChannelProtocol"
import React, { useState } from "react"
import { InboxAdminApi, InboxWhatsappChannelApi } from "@/services/Api"
import useValidation, { ErrorType } from "@/hooks/useValidation"

type InboxChannelSettingsStatesType = {
	isStaging?: boolean
	libraryClientType?: InboxChannelLibraryClientType
	localLabels?: string[]
}
interface InboxChannelSettingsProps {
	inboxChannel?: InboxChannel
	inboxChannelSettingsInitialData: InboxChannelSettingsStatesType
	getInboxChannel?: () => Promise<void>
	inboxChannelIntegrationId: string
}

const InboxChannelSettings: React.FC<InboxChannelSettingsProps> = (props) => {
	const { inboxChannel, getInboxChannel, children, inboxChannelSettingsInitialData, inboxChannelIntegrationId } = props

	const [inboxChannelSettingsStates, setInboxChannelSettingsStates] = useState<InboxChannelSettingsStatesType>(inboxChannelSettingsInitialData)
	const [openDialog, setOpenDialog] = useState(false)

	const { triggerValidation } = useValidation()

	const restartWhatsAppSession = async (integrationId: string) => {
		try {
			await InboxWhatsappChannelApi.get(`/whatsapp/session/${integrationId}/kill`)

			/**
			 * Add a delay to make sure the session is killed in order
			 * to start it over.
			 */
			await new Promise(resolve => setTimeout(resolve, 4000))

			await InboxWhatsappChannelApi.get(`/whatsapp/session/${integrationId}/start`)

			getInboxChannel && await getInboxChannel()
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
	}

	const handleUpdateInboxChannelExtraData = async (extraData: InboxChannel["extraData"], inboxChannelId: number | undefined) => {
		try {
			if (inboxChannelId) {
				await InboxAdminApi.put(`/inbox/channel/${inboxChannelId}/update-extra-data`, {
					...extraData
				})
			}
		} catch (error) {
			getInboxChannel && getInboxChannel()
			triggerValidation(error as ErrorType)
		}
	}

	const handleAddOrRemoveInboxChannelFromStaging = async () => {
		const filteredLabels = inboxChannelSettingsStates?.localLabels?.filter(value => value !== "whatsapp-api-staging")
		let finalLabels: string[] = []

		if (!inboxChannelSettingsStates?.isStaging) {
			if (filteredLabels) {
				finalLabels = [
					...filteredLabels,
					"whatsapp-api-staging"
				]
			}
		} else {
			if (filteredLabels) {
				finalLabels = [
					...filteredLabels
				]
			}
		}

		setInboxChannelSettingsStates({
			...inboxChannelSettingsStates,
			isStaging: !inboxChannelSettingsStates?.isStaging,
			localLabels: finalLabels
		})

		await handleUpdateInboxChannelExtraData({
			labels: [
				...finalLabels
			]
		}, inboxChannel?.id)

		await restartWhatsAppSession(inboxChannelIntegrationId)
	}

	const handleLibSelectOnChange = (event: React.ChangeEvent<{ name?: string, value: unknown }>) => {
		const newValue = event.target.value as InboxChannelLibraryClientType

		let labels: string[] | undefined = inboxChannelSettingsStates?.localLabels

		if (newValue === "baileys") {
			const filteredLabels = labels?.filter(value => value !== "whatsapp-api-baileys")

			labels = filteredLabels ? [
				...filteredLabels,
				"whatsapp-api-baileys"
			] : ["whatsapp-api-baileys"]
		}

		handleUpdateInboxChannelExtraData({
			labels,
			libraryClientType: newValue
		}, inboxChannel?.id)

		setInboxChannelSettingsStates(
			{
				...inboxChannelSettingsStates,
				libraryClientType: newValue,
				localLabels: labels
			}
		)
	}

	return <>
		{React.cloneElement(children as React.ReactElement, {
			onClick: () => setOpenDialog(true)
		})}
		<InfoDialog
			title="Configurações do Canal"
			openDialog={openDialog}
			fullWidth
			onClose={() => setOpenDialog(false)}
		>
			<MenuList>
				<MenuItem
					onClick={handleAddOrRemoveInboxChannelFromStaging}
					disableGutters
				>
					<Grid container alignItems="center">
						<Grid xs={10}>
							Incluir Canal no Staging
						</Grid>
						<Grid xs={2}>
							<Switch
								checked={inboxChannelSettingsStates.isStaging}
							/>
						</Grid>
					</Grid>
				</MenuItem>
				<Typography style={{ padding: "5px 0px" }} variant="subtitle1">
					<Grid container alignItems="center">
						<Grid xs={9}>
							Biblioteca Utilizada Neste Canal
						</Grid>
						<Grid xs={3}>
							<Select
								style={{ height: "45px" }}
								fullWidth
								value={inboxChannelSettingsStates.libraryClientType}
								onChange={handleLibSelectOnChange}
								variant="outlined"
							>
								<MenuItem value="baileys"> Baileys </MenuItem>
							</Select>
						</Grid>
					</Grid>
				</Typography>
			</MenuList>
		</InfoDialog>
	</>
}

export default InboxChannelSettings
