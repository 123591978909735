import { makeStyles } from "@material-ui/core"
import { isSmallScreen } from "@/utils/checkDevice"

const useStyles = makeStyles(theme => ({
	paper: {
		margin: theme.spacing(2),
		borderRadius: theme.spacing(1)
	},
	drawerPaper: {
		width: `${isSmallScreen && "100vw"}`
	},
	paperFullWidth: {
		width: "calc(100% - 32px)"
	},
	dialogContainer: {
		position: "relative",
		padding: theme.spacing(3)
	},
	closeIcon: {
		position: "absolute",
		top: 0,
		right: 0
	},
	dialogActions: {
		marginTop: theme.spacing(2)
	}
}))

export default useStyles
