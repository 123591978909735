import React from "react"
import { Divider as MaterialDivider, useTheme } from "@material-ui/core"

import useStyles from "@/components/Divider/styles"

type DividerProps = {
	orientation: "horizontal" | "vertical"
	size: number
}

const Divider: React.FC<DividerProps> = (props) => {
	const { orientation, size } = props

	const theme = useTheme()

	const customProps = {
		...(orientation === "horizontal" ? { height: theme.spacing(size), width: "100%" } : {}),
		...(orientation === "vertical" ? { height: "auto", width: theme.spacing(size) } : {})
	}

	const classes = useStyles()

	return (
		<MaterialDivider
			orientation={orientation}
			className={classes.divider}
			style={{ ...customProps }}
		/>
	)
}

export default Divider
