import React, { CSSProperties, ReactNode, useState } from "react"

import {
	Button,
	FormControl,
	Grid, IconButton, Input, InputAdornment,
	MenuItem,
	Paper, Select, Table, TableBody,
	TableCell, TableCellProps, TableContainer,
	TableHead, TablePagination, TableRow,
	TextField, Typography
} from "@material-ui/core"

import {
	Search as SearchIcon,
	Close as CloseIcon,
	Refresh as ReloadIcon,
	Sync as SyncIcon
} from "@material-ui/icons"

import { AccountAdminApi } from "@/services/Api"

import useDebounce from "@/hooks/useDebounce"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import useDidMount from "@/hooks/useDidMount"

import { Divider, Loading, Portlet } from "@/components"

import useStyles from "@/pages/Account/SpreadsheetCustomers/styles"
import CardSkeleton from "@/skeletons/CardSkeleton"
import { formatDateAndTimeBrazilianDate } from "@/utils/time"

import { formatBrazilianCurrency, formatCnpj, formatStringToPascalCase } from "@/utils/mask"
import { GetSpreadsheetCustomersDataResponse, PopulateValidationsData, SpreadsheetCustomer } from "@/pages/Account/protocols/SpreadsheetCustomersProtocol"

import { Link } from "react-router-dom"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { ptBR } from "date-fns/locale"
import DateFnsUtils from "@date-io/date-fns"
import { isSmallScreen } from "@/utils/checkDevice"
import useCustomStyles from "@/styles/custom"
import { Alert } from "@material-ui/lab"
import { orange, red } from "@material-ui/core/colors"

type SpreadsheetCustomersColumnsNames = keyof SpreadsheetCustomer

type SpreadsheetCustomersColumns = {
	name: SpreadsheetCustomersColumnsNames,
	format?: (value: string) => string
	style?: React.CSSProperties
	align?: TableCellProps["align"]
	render?: (value: any) => ReactNode
}

type SpreadsheetCustomersColumnsConfig = Record<string, {
	format?: (value: string) => string
	style?: React.CSSProperties
	align?: TableCellProps["align"]
	render?: (value: any) => ReactNode
}>

const TableColumnsConfig: SpreadsheetCustomersColumnsConfig = {
	document: {
		format: (value: string) => formatCnpj(value),
		render: (value: any) => {
			return <Link
				target="_blank"
				rel="noopener noreferrer"
				to={`/account?cnpj=${value}`}
			>
				{value}
			</Link>
		}
	},
	instance_id: {
		render: (value: any) => {
			return <Link
				target="_blank"
				rel="noopener noreferrer"
				to={`/inbox/instance/${value}`}
			>
				{value}
			</Link>
		}
	},
	crm: {
		render: (value: any) => {
			return <Link
				target="_blank"
				rel="noopener noreferrer"
				to={{
					pathname: value
				}}
			>
				{value}
			</Link>
		}
	},
	customer_mrr: {
		format: (value: string) => formatBrazilianCurrency(Number(value)) as string
	},
	subscription_amount_or_addon_without_discount: {
		format: (value: string) => formatBrazilianCurrency(Number(value)) as string
	},
	subscription_mrr: {
		format: (value: string) => formatBrazilianCurrency(Number(value)) as string
	},
	created_at: {
		format: (value: string) => formatDateAndTimeBrazilianDate(value)?.dateAndTime || " - "
	},
	updated_at: {
		format: (value: string) => formatDateAndTimeBrazilianDate(value)?.dateAndTime || " - "
	}
}

const conferencesList: Array<Record<string, string>> = []

type SpreadsheetCustomersWhereData = {
	page: number
	rowsPerPage: number
	search?: string
	startCreated?: string | null
	endCreated?: string | null
	conference?: string
	startRemainingDaysToPay?: number | null
	endRemainingDaysToPay?: number | null
}

const SpreadsheetCustomers = () => {
	const [customers, setCustomers] = useState<GetSpreadsheetCustomersDataResponse>({} as GetSpreadsheetCustomersDataResponse)
	const [loading, setLoading] = useState<boolean>(true)

	const [whereData, setWhereData] = useState<SpreadsheetCustomersWhereData>({
		page: 0,
		rowsPerPage: 50
	})

	const classes = useStyles()

	const [conferenceHelpText, setConferenceHelpText] = useState("")
	const [tableColumns, setTableColumns] = useState<Array<SpreadsheetCustomersColumns>>([])
	const [spreadsheetCustomerLabels, setSpreadsheetCustomerLabels] = useState<Record<keyof SpreadsheetCustomer, string>>()
	const [populateValidations, setPopulateValidations] = useState<PopulateValidationsData>()

	const customClasses = useCustomStyles()

	const {
		triggerValidation
	} = useValidation()

	const getSpreadsheetCustomers = async (newWhereData?: Partial<SpreadsheetCustomersWhereData>) => {
		setLoading(true)
		try {
			const { data } =
				await AccountAdminApi.get<GetSpreadsheetCustomersDataResponse>(
					"/spreadsheet-customers",
					{
						params: {
							...whereData,
							...newWhereData
						}
					}
				)

			if (newWhereData?.conference) {
				setConferenceHelpText("Os seguintes clientes não passaram na conferência selecionada:")
			} else {
				setConferenceHelpText("")
			}

			setSpreadsheetCustomerLabels(data?.labels)

			const keys = Object.keys(data?.labels)

			const columns: SpreadsheetCustomersColumns[] = []

			keys.forEach((key) => {
				const config = TableColumnsConfig[key]

				const format = config?.format
				const render = config?.render

				const result: SpreadsheetCustomersColumns = {
					name: key as SpreadsheetCustomersColumnsNames,
					format: format,
					render: render
				}

				columns.push(result)
			})

			setTableColumns(columns)

			setCustomers(data)
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setLoading(false)
	}

	const syncSpreadsheetCustomers = async () => {
		setLoading(true)
		try {
			const { data } = await AccountAdminApi
				.post("/spreadsheet-customers/populate", {})

			getSpreadsheetCustomers({})
			const validationsData = data?.validations

			setPopulateValidations(validationsData)
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setLoading(false)
	}

	const handleWhereDataChange = (newData: Partial<SpreadsheetCustomersWhereData>) => {
		setWhereData((currentState) => (
			{
				...currentState,
				...newData
			}))
	}

	const handleSearchChange = async (search: string) => {
		handleWhereDataChange({
			search,
			page: 0
		})
	}

	useDebounce(
		async () => await getSpreadsheetCustomers(whereData),
		whereData.conference,
		1250
	)

	useDebounce(
		async () => await getSpreadsheetCustomers(whereData),
		whereData.search,
		1250
	)

	useDebounce(
		async () => await getSpreadsheetCustomers(whereData),
		whereData.startCreated,
		1250
	)

	useDebounce(
		async () => await getSpreadsheetCustomers(whereData),
		whereData.endCreated,
		1250
	)

	useDebounce(
		async () => await getSpreadsheetCustomers(whereData),
		whereData.startRemainingDaysToPay,
		1250
	)

	useDebounce(
		async () => await getSpreadsheetCustomers(whereData),
		whereData.endRemainingDaysToPay,
		1250
	)

	const handlePageChange = async (event: unknown, page: number) => {
		handleWhereDataChange({
			page
		})

		await getSpreadsheetCustomers(whereData)
	}

	const handleRowsPerPageChange = async (rowsPerPage: number) => {
		handleWhereDataChange({
			rowsPerPage
		})

		await getSpreadsheetCustomers(whereData)
	}

	useDidMount(() => {
		getSpreadsheetCustomers()
	})

	const renderTableColumnName = (name: string) => {
		if (spreadsheetCustomerLabels) {
			let label = spreadsheetCustomerLabels[name as SpreadsheetCustomersColumnsNames]

			if (!label) {
				label = formatStringToPascalCase(name.replace("_", " "))
			}

			return label
		}

		return ""
	}

	const renderNotFoundColumnValidations = () => {
		return <Alert severity="warning" title="Colunas Não Encontradas">
			<Typography variant="body2" >
				<b>Colunas Não Encontradas</b>
			</Typography>
			<Divider size={2} orientation="horizontal" />
			<Grid container direction="column" spacing={1}>
				{
				populateValidations?.notFoundColumns?.map(item => {
					return <Grid item>
						<Typography variant="body2">
							- {item}
						</Typography>
					</Grid>
				})
				}
			</Grid>
		</Alert>
	}

	return (
		<Grid container>
			<Grid item xs={12}>
				<Grid container justifyContent="space-between" alignItems="center">
					<Grid item>
						<Typography color="textPrimary" className={classes.title}>
							Clientes da Planilha do Google Sheets
						</Typography>
					</Grid>
					<Grid item>
						<Button startIcon={<SyncIcon />} onClick={syncSpreadsheetCustomers} variant="contained" color="primary" disabled={loading}>
							Sincronizar Dados
						</Button>
					</Grid>
				</Grid>

				<Divider size={1} orientation="horizontal" />

				<Portlet elevation={1}>
					<Grid container>
						<Grid item xs={12}>
							<Grid container spacing={3} justify="space-between" alignItems="center">
								<Grid item xs={12} md={6}>
									<Grid
										container
										alignItems="center"
										justify="space-evenly"
									>
										<Grid item xs>
											<FormControl className={classes.conferencesSelect}>
												<Select
													labelId="demo-mutiple-chip-label"
													id="demo-mutiple-chip"
													displayEmpty
													value={whereData?.conference}
													onChange={({ target }) =>
														handleWhereDataChange({
															conference: target.value as string
														})
													}
													input={<Input id="select-multiple-chip" />}
													renderValue={(selected: any) => {
														if (!selected) {
															return <Typography>Selecione uma Conferência</Typography>
														}

														const value = conferencesList.find(conference => conference.name === selected)

														return (
															<Typography>{value?.label}</Typography>
														)
													}}
													variant="filled"
												>
													<MenuItem disabled value="">
														<em>Selecione a Conferência</em>
													</MenuItem>

													{conferencesList.map((option, index) => (
														<MenuItem key={index} value={option.name} >
															{option.label}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
									</Grid>
								</Grid>

								<Grid item xs={12} md={5}>
									<TextField
										value={whereData.search}
										onChange={({ target }) =>
											handleSearchChange(target.value)
										}
										placeholder="Pesquisar"
										variant="outlined"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SearchIcon />
												</InputAdornment>
											),
											endAdornment: whereData.search && (
												<IconButton
													size="small"
													onClick={() => handleWhereDataChange({
														search: ""
													})}
												>
													<CloseIcon fontSize="small" />
												</IconButton>
											)
										}}
										fullWidth
										size="small"
									/>
								</Grid>

								<Grid item >
									<Grid container direction="row" justifyContent="center">
										<Grid item>
											<IconButton disabled={loading} onClick={() => getSpreadsheetCustomers()}>
												<ReloadIcon />
											</IconButton>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12}>
							<Divider size={3} orientation="horizontal" />
							<Grid container spacing={3}>
								<Grid item xs={12} md={6}>
									<Typography variant="body2">Filtrar por Data de Ativação: </Typography>
									<Divider size={1} orientation="horizontal" />
									<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
										<Grid spacing={2} container alignItems="center" justify="flex-start">
											<Grid item xs={12} md={6}>
												<Grid
													container
													alignItems="center"
													justifyContent="space-evenly"
												>
													<Grid item xs>
														<DatePicker
															id="startCreated"
															variant={isSmallScreen ? "dialog" : "inline"}
															inputVariant="outlined"
															disableFuture
															format="dd/MM/yyyy"
															animateYearScrolling
															views={["year", "month", "date"]}
															value={whereData?.startCreated || null}
															onChange={(date) =>
																handleWhereDataChange({
																	startCreated: date?.toISOString()
																})
															}
															maxDate={whereData?.endCreated || undefined}
															maxDateMessage="Não pode ser maior que a data final"
															color="primary"
															className={customClasses.datePicker}
															InputProps={{
																className: customClasses.datePickerInput
															}}
															cancelLabel="Cancelar"
															autoOk
															emptyLabel="Digite a data inicial"
														/>
													</Grid>

													{whereData?.startCreated && (
														<Grid item>
															<IconButton
																className={classes.inputsDeleteIcon}
																onClick={() =>
																	handleWhereDataChange({
																		startCreated: null
																	})
																}
															>
																<CloseIcon fontSize="small" />
															</IconButton>
														</Grid>
													)}
												</Grid>
											</Grid>

											<Grid item xs={12} md={6}>
												<Grid
													container
													alignItems="center"
													justify="space-evenly"
												>
													<Grid item xs>
														<DatePicker
															id="endCreated"
															variant={isSmallScreen ? "dialog" : "inline"}
															inputVariant="outlined"
															disableFuture
															format="dd/MM/yyyy"
															animateYearScrolling
															views={["year", "month", "date"]}
															value={whereData?.endCreated || null}
															onChange={(date) =>
																handleWhereDataChange({
																	endCreated: date?.toISOString()
																})
															}
															minDate={whereData?.startCreated || undefined}
															minDateMessage="Não pode ser maior que a data inicial"
															color="primary"
															className={customClasses.datePicker}
															InputProps={{
																className: customClasses.datePickerInput
															}}
															cancelLabel="Cancelar"
															autoOk
															emptyLabel="Digite a data final"
														/>
													</Grid>

													{whereData?.endCreated && (
														<Grid item>
															<IconButton
																className={classes.inputsDeleteIcon}
																onClick={() =>
																	handleWhereDataChange({
																		endCreated: null
																	})
																}
															>
																<CloseIcon fontSize="small" />
															</IconButton>
														</Grid>
													)}
												</Grid>
											</Grid>
										</Grid>
									</MuiPickersUtilsProvider>
								</Grid>

								<Grid item xs={12} md={6}>
									<Typography variant="body2">Filtrar por Dias Restantes Pra Pagar: </Typography>
									<Divider size={1} orientation="horizontal" />
									<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
										<Grid spacing={2} container alignItems="center" justify="flex-start">
											<Grid item xs={12} md={6}>
												<Grid
													container
													alignItems="center"
													justifyContent="space-evenly"
												>
													<Grid item xs>
														<TextField
															id="startRemainingDaysToPay"
															variant="outlined"
															type="number"
															value={whereData?.startRemainingDaysToPay}
															onChange={({ target }) => {
																handleWhereDataChange({
																	startRemainingDaysToPay: Number(target.value)
																})
															}}
															placeholder="De"
															color="primary"
															fullWidth
															size="small"
														/>
													</Grid>

													{isFinite(Number(whereData?.startRemainingDaysToPay)) && (
														<Grid item>
															<IconButton
																className={classes.inputsDeleteIcon}
																onClick={() =>
																	handleWhereDataChange({
																		startRemainingDaysToPay: undefined
																	})
																}
															>
																<CloseIcon fontSize="small" />
															</IconButton>
														</Grid>
													)}
												</Grid>
											</Grid>

											<Grid item xs={12} md={6}>
												<Grid
													container
													alignItems="center"
													justify="space-evenly"
												>
													<Grid item xs>
														<TextField
															id="endRemainingDaysToPay"
															variant="outlined"
															type="number"
															value={whereData?.endRemainingDaysToPay}
															onChange={({ target }) => {
																handleWhereDataChange({
																	endRemainingDaysToPay: Number(target.value)
																})
															}}
															placeholder="Até"
															color="primary"
															fullWidth
															size="small"
														/>
													</Grid>

													{isFinite(Number(whereData?.endRemainingDaysToPay)) && (
														<Grid item>
															<IconButton
																className={classes.inputsDeleteIcon}
																onClick={() =>
																	handleWhereDataChange({
																		endRemainingDaysToPay: undefined
																	})
																}
															>
																<CloseIcon fontSize="small" />
															</IconButton>
														</Grid>
													)}
												</Grid>
											</Grid>
										</Grid>
									</MuiPickersUtilsProvider>
								</Grid>
							</Grid>
						</Grid>

						<Divider size={1} orientation="horizontal" />

						{
							!!populateValidations?.notFoundColumns?.length && <Grid item xs={12}>
								<Divider size={3} orientation="horizontal" />
								{renderNotFoundColumnValidations()}
							</Grid>
						}

						<Divider size={3} orientation="horizontal" />

						{conferenceHelpText && <Typography variant="h4">{conferenceHelpText}</Typography>}

						<Divider size={1} orientation="horizontal" />

						<Grid item xs={12}>
							<Loading
								customLoadingElement={
									<CardSkeleton cardHeight={200} />}
								loading={loading}>

								{
									customers.count > 0
										? <Paper className={`${classes.paper}`}>
											<TableContainer className={classes.tableFather}>
												<Table stickyHeader aria-label="sticky table" className={classes.tableContent}>
													<TableHead>
														<TableRow>
															{tableColumns
																.map((column, index) => (
																	<TableCell key={index} style={{ minWidth: "200px" }}>
																		{renderTableColumnName(column.name)}
																	</TableCell>
																))}
														</TableRow>
													</TableHead>
													<TableBody>
														{
															customers.rows.map((customer, index) => {
																const stylesByState = {
																	canceled: {
																		backgroundColor: red[300],
																		color: "white"
																	},
																	defaultPayment: {
																		backgroundColor: orange[600],
																		color: "white"
																	}
																}

																let customStyle: CSSProperties | undefined

																if (customer?.remaining_days_to_pay !== undefined && customer.remaining_days_to_pay < 0) {
																	customStyle = stylesByState.defaultPayment
																}

																if (customer.cancellation_request_date) {
																	customStyle = stylesByState.canceled
																}

																return (
																	<TableRow
																		hover
																		tabIndex={-1}
																		key={index}
																		className={classes.tableRow}
																		style={customStyle}
																	>
																		{tableColumns
																			.map((column, index) => {
																				let value = customer[column.name] as string

																				if (column?.format) {
																					value = column.format(value || "")
																				}

																				if (column?.render) {
																					return <TableCell
																						key={index}
																						style={column?.style}
																						align={column?.align}
																					>
																						{column?.render(value)}
																					</TableCell>
																				}

																				return (
																					<TableCell
																						key={index}
																						style={{
																							...column?.style,
																							...customStyle
																						}}
																						align={column?.align}
																					>
																						{column.name === "payment_email"
																							? <Link
																								target="_blank"
																								rel="noopener noreferrer"
																								to={`/spreadsheet-customers/${customer.id}`}
																							>
																								{value}
																							</Link> : value}

																					</TableCell>
																				)
																			})
																		}
																	</TableRow>
																)
															})}
													</TableBody>
												</Table>
											</TableContainer>

											<TablePagination
												rowsPerPageOptions={[20, 50, 100, 200]}
												component="div"
												count={customers.count}
												rowsPerPage={whereData.rowsPerPage}
												page={whereData.page}
												onPageChange={handlePageChange}
												onRowsPerPageChange={({ target }) => handleRowsPerPageChange(+target.value)}
												labelRowsPerPage={"Resultados por página:"}
												labelDisplayedRows={e =>
													`Página ${e.page + 1} de ${Math.ceil(e.count / whereData.rowsPerPage)} | Total de resultados: ${customers?.count}`
												}
											/>
										</Paper>
										: (
											<>
												<Divider size={3} orientation="horizontal" />
												<Typography align="center" variant="h2">
													Nenhum <b>Customer</b> Cadastrado
												</Typography>
											</>)
								}
							</Loading>
						</Grid>
					</Grid>
				</Portlet>
			</Grid>
		</Grid>
	)
}

export default SpreadsheetCustomers
