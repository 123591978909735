import {
	ChannelType,
	InboxChannel,
	InboxChannelBrokerExtraData,
	InboxChannelStatus
} from "@/pages/Inbox/protocols/ChannelProtocol"

export type InstanceAdminProps = {
	id: number
	account_instance_id: number
	created_at: Date
	updated_at: Date
	owner_user_id: number
	owner_user_name: string
	owner_user_email: string
	owner_user_account_id: number
	owner_user_phone_number: string
	inbox_channel_status: InboxChannelStatus
	inbox_channel_extra_data: InboxChannel["extraData"]
	cnpj?: string
	responsible_phone?: string
	current_channel_type: ChannelType
	broker_extra_data: InboxChannelBrokerExtraData
	nickname: string
}

export type GetInstancesResponseProps = {
	count: number,
	data: InstanceAdminProps[]
}

export type ChannelChatStatus = "queue" | "on-going"

export enum SequelizeOperator {
	Between = "between",
	BeforeOrEqual = "lte",
	AfterOrEqual = "gte"
}
export type ProcedureRequest = "finish"
