import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"
import newColors from "@/styles/newColors"

const useStyles = makeStyles({
	danger: {
		color: colors.palette.error
	},
	iconButton: {
		padding: "5px",
		backgroundColor: "transparent",
		"&:hover": {
			backgroundColor: newColors.grey[100]
		}
	}
})

export default useStyles
