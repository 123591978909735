import React, { useState } from "react"
import {
	Grid
} from "@material-ui/core"

import { InboxAdminApi } from "@/services/Api"

import ChannelConnectionMetrics from "@/pages/Inbox/Instances/components/ChannelConnectionMetrics"
import useValidation from "@/hooks/useValidation"
import useDidMount from "@/hooks/useDidMount"
import { ChannelType, DailyChannelConnectionMetrics, InboxChannel } from "@/pages/Inbox/protocols/ChannelProtocol"
import { Divider } from "@/components"

type CustomInboxChannel = Omit<InboxChannel, "whatsappChannelConnectionMetrics"> & {
	channelConnectionMetrics: DailyChannelConnectionMetrics
}

const WhatsAppConnectionMetrics: React.FC = () => {
	const [channels, setChannels] = useState<CustomInboxChannel[]>([])
	const { triggerValidation } = useValidation()

	const getAndUpdateChannels = async () => {
		try {
			const { data } = await InboxAdminApi.get("/channel/index")
			setChannels(data)
		} catch (error) {
			triggerValidation(error)
		}
	}

	useDidMount(() => {
		getAndUpdateChannels()
	})

	return (
		<>
			<Grid item xs={12}>
				{channels.map(channel => (
					<Grid container spacing={1} key={channel.integrationId}>
						<Grid item xs={12}>
							Id: {channel.id}, InstanceId: {channel.instanceId}, Title: {channel.title}
						</Grid>

						<Grid item xs={12}>
							<ChannelConnectionMetrics
								channelConnectionMetrics={channel.channelConnectionMetrics}
								channelType={channel.channelType as ChannelType}
							/>
						</Grid>

						<Divider orientation="horizontal" size={2} />
					</Grid>
				))}
			</Grid>
		</>
	)
}

export default WhatsAppConnectionMetrics
