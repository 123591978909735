import React from "react"
import { Redirect, Route } from "react-router-dom"

import SignIn from "@/pages/Auth/SignIn"

const PublicRoutes = [
	<Route
		exact
		key="home"
		path="/"
		render={() => (
			<Redirect to="/inbox" />
		)}
	/>,
	<Route
		exact
		key="auth-signin"
		path="/signin"
		component={SignIn}
	/>
]

export default PublicRoutes
