import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles({
	customDisabled: {
		backgroundColor: colors.grayScale[9],
		fontWeight: "bold"
	}
})

export default useStyles
