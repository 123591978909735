import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import qs from "qs"
import {
	Box,
	FormControl,
	useTheme,
	Grid,
	IconButton,
	Input,
	InputAdornment,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Typography,
	Theme
} from "@material-ui/core"
import {
	Search as SearchIcon,
	Close as CloseIcon,
	Refresh as ReloadIcon
} from "@material-ui/icons"

import { ptBR } from "date-fns/locale"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"

import { InboxAdminApi } from "@/services/Api"

import useDebounce from "@/hooks/useDebounce"
import useValidation, { ErrorType } from "@/hooks/useValidation"

import { Divider, Loading, Notification, PopConfirm, Portlet } from "@/components"

import { GetInstancesResponseProps } from "@/pages/Inbox/protocols/InstanceProtocol"
import {
	ChannelType,
	InboxChannelStatus,
	InboxWhatsappChannelStatus
} from "@/pages/Inbox/protocols/ChannelProtocol"

import WhatsappChipSessionStatus from "@/channels/Whatsapp/components/WhatsappChipSessionStatus"

import CardSkeleton from "@/skeletons/CardSkeleton"

import { WhatsappChipOptions } from "@/channels/Whatsapp/utils/channel"
import { isSmallScreen } from "@/utils/checkDevice"

import useStyles from "@/pages/Inbox/Instances/styles"
import useCustomStyles from "@/styles/custom"
import InstanceListItem, { TableColumns } from "@/pages/Inbox/Instances/components/InstanceListItem"

type InstanceWhereData = {
	page: number
	rowsPerPage: number
	search?: string
	searchStatus?: InboxChannelStatus[]
	searchChannelType?: ChannelType[]
	startCreated?: Date
	endCreated?: Date
}

const MENU_ITEM_HEIGHT = 48
const MENU_ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: MENU_ITEM_HEIGHT * 4.5 + MENU_ITEM_PADDING_TOP,
			width: 250
		}
	}
}

const InboxInstances = () => {
	const theme = useTheme()

	const location = useLocation()
	const initialSearch = String(qs.parse(location.search, { ignoreQueryPrefix: true }).search || "")

	const [instances, setInstances] = useState<GetInstancesResponseProps>(
		{} as GetInstancesResponseProps
	)
	const [loading, setLoading] = useState<boolean>(true)

	const [whereData, setWhereData] = useState<InstanceWhereData>({
		page: 0,
		rowsPerPage: 50,
		search: initialSearch
	})

	const [selectStatusOpen, setSelectStatusOpen] = useState(false)

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const { triggerValidation } = useValidation()

	const getInstances = async (newWhereData?: Partial<InstanceWhereData>) => {
		setLoading(true)

		try {
			const { data } = await InboxAdminApi
				.get("/instances", {
					params: {
						...whereData,
						...newWhereData
					}
				})

			setInstances(data)
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setLoading(false)
	}

	const handleWhereDataChange = (newData: Partial<InstanceWhereData>) => {
		setWhereData((currentState) => (
			{
				...currentState,
				...newData
			}))
	}

	const handleSearchChange = async (search: string) => {
		handleWhereDataChange({
			search,
			page: 0
		})
	}

	const handleSearchStatusChange = async (searchStatus: InboxChannelStatus[]) => {
		handleWhereDataChange({
			searchStatus,
			page: 0
		})

		await getInstances({ searchStatus })
	}

	const handlePageChange = async (event: unknown, page: number) => {
		handleWhereDataChange({
			page
		})

		await getInstances({ page })
	}

	const handleRowsPerPageChange = async (rowsPerPage: number) => {
		handleWhereDataChange({
			rowsPerPage
		})
		await getInstances({ rowsPerPage })
	}

	const handleDateFilterChange = async (
		type: "startCreated" | "endCreated",
		date: Date | null
	) => {
		const newDate = date ? new Date(date).toLocaleDateString("en-US") : null

		handleWhereDataChange({
			[type as string]: newDate
		})

		await getInstances({ [type as string]: newDate })
	}

	const deleteOneInstance = async (instanceId: number) => {
		try {
			const { data } = await InboxAdminApi.delete(`/instance/${instanceId}`)
			const wasDeleted = Boolean(data)

			if (wasDeleted) {
				Notification.success({ message: `Instância de id ${instanceId} deletada com sucesso` })
			} else {
				Notification.warning({ message: `Houve um erro ao deletar instância de id ${instanceId}` })
			}

			await getInstances()
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
	}

	const handleDeleteInstance = async (instanceId: number) => {
		const props = {
			description: `Deseja excluir a instância ${instanceId}?`,
			confirmButtonText: "EXCLUIR",
			onConfirm: () => deleteOneInstance(instanceId)
		}
		PopConfirm.open(props)
	}

	const getStylesMenuStatus = (name: InboxChannelStatus, personName: InboxChannelStatus[], theme: Theme) => {
		return {
			fontWeight:
				personName.indexOf(name) === -1
					? theme.typography.fontWeightRegular
					: theme.typography.fontWeightMedium
		}
	}

	useDebounce(
		async () => await getInstances({ search: whereData?.search }),
		whereData.search,
		1250
	)

	useEffect(() => {
		handleSearchChange(initialSearch)
		// eslint-disable-next-line
	}, [initialSearch])

	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography
					color="textPrimary"
					className={classes.title}
				>
					Instâncias
				</Typography>

				<Divider size={1} orientation="horizontal" />

				<Portlet
					elevation={1}
				>
					<Grid container>
						<Grid item xs={12}>
							<Grid container spacing={3} justify="space-between" alignItems="center">
								<Grid item xs={12} md={6}>
									<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
										<Grid spacing={2} container alignItems="center" justify="flex-start">
											<Grid item xs={12} md={6}>
												<Grid
													container
													alignItems="center"
													justify="space-evenly"
												>
													<Grid item xs>
														<DatePicker
															id="startCreated"
															variant={isSmallScreen ? "dialog" : "inline"}
															inputVariant="outlined"
															disableFuture
															format="dd/MM/yyyy"
															animateYearScrolling
															views={["year", "month", "date"]}
															value={whereData?.startCreated || null}
															onChange={(date) =>
																handleDateFilterChange("startCreated", date as Date)
															}
															maxDate={whereData?.endCreated || undefined}
															maxDateMessage="Não pode ser maior que a data final"
															color="primary"
															className={customClasses.datePicker}
															InputProps={{
																className: customClasses.datePickerInput
															}}
															cancelLabel="Cancelar"
															autoOk
															emptyLabel="Digite a data inicial"
														/>
													</Grid>

													{whereData?.startCreated && (
														<Grid item>
															<IconButton
																className={classes.dateDeleteIcon}
																onClick={() =>
																	handleDateFilterChange("startCreated", null)
																}
															>
																<CloseIcon fontSize="small" />
															</IconButton>
														</Grid>
													)}
												</Grid>
											</Grid>

											<Grid item xs={12} md={6}>
												<Grid
													container
													alignItems="center"
													justify="space-evenly"
												>
													<Grid item xs>
														<DatePicker
															id="endCreated"
															variant={isSmallScreen ? "dialog" : "inline"}
															inputVariant="outlined"
															disableFuture
															format="dd/MM/yyyy"
															animateYearScrolling
															views={["year", "month", "date"]}
															value={whereData?.endCreated || null}
															onChange={(date) =>
																handleDateFilterChange("endCreated", date as Date)
															}
															minDate={whereData?.startCreated || undefined}
															minDateMessage="Não pode ser maior que a data inicial"
															color="primary"
															className={customClasses.datePicker}
															InputProps={{
																className: customClasses.datePickerInput
															}}
															cancelLabel="Cancelar"
															autoOk
															emptyLabel="Digite a data final"
														/>
													</Grid>

													{whereData?.endCreated && (
														<Grid item>
															<IconButton
																className={classes.dateDeleteIcon}
																onClick={() =>
																	handleDateFilterChange("endCreated", null)
																}
															>
																<CloseIcon fontSize="small" />
															</IconButton>
														</Grid>
													)}
												</Grid>
											</Grid>
										</Grid>
									</MuiPickersUtilsProvider>
								</Grid>

								<Grid item xs={12} md={5}>
									<TextField
										value={whereData.search}
										onChange={({ target }) =>
											handleSearchChange(target.value)
										}
										placeholder="Pesquisar"
										variant="outlined"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SearchIcon />
												</InputAdornment>
											),
											endAdornment: whereData.search && (
												<IconButton
													size="small"
													onClick={() => handleWhereDataChange({
														search: ""
													})}
												>
													<CloseIcon
														fontSize="small"
													/>
												</IconButton>
											)
										}}
										fullWidth
										size="small"
									/>
								</Grid>

								<Grid item xs={1}>
									<IconButton disabled={loading} onClick={() => getInstances()}>
										<ReloadIcon />
									</IconButton>
								</Grid>

								<Grid item xs={12}>
									<Grid spacing={2} container alignItems="center" justify="flex-start">
										<Grid item xs={12} md={6}>
											<Grid
												container
												alignItems="center"
												justifyContent="space-evenly"
												spacing={2}
											>
												<Grid item xs>
													<FormControl className={classes.statusSelect}>
														<Select
															labelId="demo-mutiple-chip-label"
															id="demo-mutiple-chip"
															multiple
															displayEmpty
															value={whereData?.searchStatus || []}
															onChange={({ target }) =>
																handleSearchStatusChange(target.value as InboxChannelStatus[])
															}
															input={<Input id="select-multiple-chip" />}
															renderValue={(selected) => {
																if (!(selected as InboxChannelStatus[]).length) {
																	return <Typography>Selecione o status</Typography>
																}

																return (
																	<Box className={classes.chips}>
																		{(selected as InboxChannelStatus[]).map((value, index) => {
																			return (
																				<Box style={{ margin: 3 }} key={index}>
																					<WhatsappChipSessionStatus status={value as InboxWhatsappChannelStatus} />
																				</Box>
																			)
																		})}
																	</Box>
																)
															}}
															MenuProps={MenuProps}
															variant="filled"
															open={selectStatusOpen}
															onClick={() => setSelectStatusOpen(!selectStatusOpen)}
														>
															<MenuItem disabled value="">
																<em>Selecione o status</em>
															</MenuItem>

															{Object.entries(WhatsappChipOptions).map((option, index) => (
																<MenuItem key={index} value={option[0]} style={getStylesMenuStatus(
																	option[0] as InboxChannelStatus,
																	whereData?.searchStatus || [],
																	theme
																)}>
																	{option[1].label}
																</MenuItem>
															))}
														</Select>
													</FormControl>
												</Grid>

												{whereData?.searchStatus && (
													<Grid item>
														<IconButton
															className={classes.dateDeleteIcon}
															onClick={() =>
																handleSearchStatusChange(null as unknown as InboxChannelStatus[])
															}
														>
															<CloseIcon fontSize="small" />
														</IconButton>
													</Grid>
												)}
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Divider size={3} orientation="horizontal" />

						<Grid item xs={12}>
							<Loading
								customLoadingElement={
									<CardSkeleton
										cardHeight={200}
									/>}
								loading={loading}>

								{
									instances.count > 0
										? (
											<Paper className={classes.paper}>
												<TableContainer>
													<Table stickyHeader aria-label="sticky table">
														<TableHead>
															<TableRow>
																{TableColumns
																	.map((column, index) => (
																		<TableCell
																			key={index}
																		>
																			{column.label}
																		</TableCell>
																	))}
																<TableCell align="center">
																	Ações
																</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{instances.data.map((instance, index) => {
																return <InstanceListItem instance={instance} onDelete={handleDeleteInstance} key={index} />
															})}
														</TableBody>
													</Table>
												</TableContainer>

												<TablePagination
													rowsPerPageOptions={[20, 50, 100, 200]}
													component="div"
													count={instances?.count}
													rowsPerPage={whereData.rowsPerPage}
													page={whereData.page}
													onPageChange={handlePageChange}
													onRowsPerPageChange={({ target }) => handleRowsPerPageChange(+target.value)}
													labelRowsPerPage={"Resultados por página:"}
													labelDisplayedRows={e =>
														`Página ${e.page + 1} de ${Math.ceil(e.count / whereData.rowsPerPage)} | Total de resultados: ${instances?.count}`
													}
												/>
											</Paper>
										) : (
											<>
												<Divider size={3} orientation="horizontal" />
												<Typography align="center" variant="h2">
													Nenhuma instância cadastrada
												</Typography>
											</>
										)}
							</Loading>
						</Grid>
					</Grid>
				</Portlet>
			</Grid>
		</Grid>
	)
}

export default InboxInstances
