import React, { useState } from "react"

import { Grid, Typography, Select, MenuItem, Button, InputLabel, FormControl } from "@material-ui/core"
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers"

import ptBR from "date-fns/locale/pt-BR"
import DateFnsUtils from "@date-io/date-fns"

import { ActionDialog, Notification, Divider } from "@/components"

import { InboxChannelProps } from "@/pages/Inbox/protocols/ChannelProtocol"
import { ProcedureRequest, ChannelChatStatus, SequelizeOperator } from "@/pages/Inbox/protocols/InstanceProtocol"
import { GetUsersInInstanceResponseProps } from "@/pages/Inbox/protocols/UserInInstanceProtocol"

import { InboxAdminApi } from "@/services/Api"

import { formatDateAndTimeBrazilianDate } from "@/utils/time"
import {
	sequelizeOperatorToDateRange,
	sequelizeOperatorValues,
	sequelizeOperatorToString
} from "@/utils/sequelizeUtils"

type FinishChatsDataType = {
	integrationId?: string
	inboxChannelChatStatus: ChannelChatStatus
	operator: SequelizeOperator
	before?: Date
	after?: Date
	procedure: ProcedureRequest
	userId?: number
}

type ManageInstanceChatsProps = {
	inboxChannel: InboxChannelProps
	usersInInstance: GetUsersInInstanceResponseProps
}

const ManageInstanceChats: React.FC<ManageInstanceChatsProps> = (props) => {
	const { inboxChannel, usersInInstance } = props

	const [openDialog, setOpenDialog] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)

	const [finishChatsData, setFinishChatsData] = useState<FinishChatsDataType>({
		before: new Date(),
		after: new Date(),
		inboxChannelChatStatus: "queue",
		operator: SequelizeOperator.Between,
		procedure: "finish",
		integrationId: inboxChannel?.rows[0]?.integrationId
	})

	const inboxChannelChatStatus: ChannelChatStatus[] = ["queue", "on-going"]
	const procedure: ProcedureRequest[] = ["finish"]

	const chatStatusToString: Record<ChannelChatStatus, string> = {
		"on-going": "Em atendimento",
		queue: "Novos"
	}

	const procedureRequestToString: Record<ProcedureRequest, string> = {
		finish: "Finalizar"
	}

	const handleUpdateFinishChatsData = (value: string | Date, field: keyof FinishChatsDataType) => {
		const updatedFinishChatsData: FinishChatsDataType = {
			...finishChatsData,
			[field]: value
		}

		const isCurrentAssignmentReferenceToQueue = field === "inboxChannelChatStatus" && value === "queue"

		const shouldOnlyUseBeforeDate = field === "operator" && value === "lte"
		const shouldOnlyUseAfterDate = field === "operator" && value === "gte"
		const shouldUseAfterDateAndBeforeDate = field === "operator" && value === "between"

		if (isCurrentAssignmentReferenceToQueue) {
			updatedFinishChatsData.userId = undefined
		} else if (shouldOnlyUseBeforeDate) {
			updatedFinishChatsData.after = undefined
			updatedFinishChatsData.before = new Date()
		} else if (shouldOnlyUseAfterDate) {
			updatedFinishChatsData.before = undefined
			updatedFinishChatsData.after = new Date()
		} else if (shouldUseAfterDateAndBeforeDate) {
			updatedFinishChatsData.after = new Date()
			updatedFinishChatsData.before = new Date()
		}

		setFinishChatsData(lastState => ({
			...lastState,
			...updatedFinishChatsData
		}))
	}

	const handleBulkFinishChats = async () => {
		setLoading(true)

		try {
			const data = await InboxAdminApi.post("channel/chats/attendances/clear", { finishChatsData })

			if (data.status === 200) {
				Notification.success({ message: "Chats finalizados" })
			}
		} catch (error) {
			Notification.error({ message: `Houve um erro ao finalizar os chats da aba ${chatStatusToString[finishChatsData.inboxChannelChatStatus]}` })
		}

		setLoading(false)
	}

	const selectedDateRangeOperator = sequelizeOperatorToDateRange[finishChatsData.operator]

	const turnDataInActionSummary = () => {
		const {
			operator,
			before,
			after,
			procedure,
			inboxChannelChatStatus
		} = finishChatsData

		const userName = usersInInstance?.data?.find((user) => user.id === finishChatsData.userId)?.name

		const selectedOperator = sequelizeOperatorToString[operator]?.toLowerCase() || ""

		const linkedWithAttendant = userName ? `, vinculados ao atendente ${userName}` : ""

		const isBetweenDateRangeOperator = operator === "between"
		const formattedBeforeDate = formatDateAndTimeBrazilianDate(before).dateAndTime
		const formattedAfterDate = formatDateAndTimeBrazilianDate(after).dateAndTime

		const dateRangeBySingleDate = `${selectedOperator} ${operator === "lte" ? formattedBeforeDate : formattedAfterDate}`
		const dateRangeByBetweenDate = `a partir de ${formattedAfterDate} até ${formattedBeforeDate}`

		const inDateRange = isBetweenDateRangeOperator ? dateRangeByBetweenDate : dateRangeBySingleDate

		const action = procedureRequestToString[procedure] || ""
		const attendanceChatStatus = chatStatusToString[inboxChannelChatStatus] || ""

		return `${action} atendimentos da aba ${attendanceChatStatus}${linkedWithAttendant} que possuem a data da última mensagem transacionada ${inDateRange}`
	}

	const handleOpenDialog = () => {
		setOpenDialog(true)
	}

	const handleCloseDialog = () => {
		setOpenDialog(false)
	}

	return (
		<>
			<Button onClick={handleOpenDialog}>
				ADMINISTRAR CONTATOS DA INSTÂNCIA
			</Button>
			<ActionDialog
				title="ADMINISTRAR CONTATOS DA INSTÂNCIA"
				onSave={handleBulkFinishChats}
				openDialog={openDialog}
				onClose={handleCloseDialog}
				saveText="Limpar"
				loading={loading}
			>
				<Grid item style={{ width: 550 }}>
					<Grid container spacing={2} direction="row">
						<Grid item>
							<FormControl fullWidth variant="outlined" size="small" required={true}>
								<InputLabel>
									Ação
								</InputLabel>
								<Select
									label="Ação"
									fullWidth
									value={finishChatsData.procedure}
									onChange={(event) => {
										handleUpdateFinishChatsData(event.target.value as string, "procedure")
									}}
								>
									{procedure.map((status) => {
										return <MenuItem
											key={status}
											value={status}
										>
											{procedureRequestToString[status]}
										</MenuItem>
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item>
							<FormControl fullWidth variant="outlined" size="small" required={true}>
								<InputLabel>
									Aba
								</InputLabel>
								<Select
									variant="outlined"
									label="Aba"
									fullWidth
									defaultValue={finishChatsData.inboxChannelChatStatus}
									onChange={(event) => {
										handleUpdateFinishChatsData(event.target.value as string, "inboxChannelChatStatus")
									}}
								>
									{inboxChannelChatStatus.map((status) => {
										return <MenuItem
											key={status}
											value={status}
										>
											{chatStatusToString[status]}
										</MenuItem>
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item>
							{finishChatsData.inboxChannelChatStatus === "on-going" &&
								<FormControl fullWidth variant="outlined" size="small" required={true}>
									<InputLabel>
										Atendente
									</InputLabel>
									<Select
										variant="outlined"
										label="Atendente"
										style={{ width: "150px" }}
										value={finishChatsData?.userId}
										error={!finishChatsData?.userId}
										onChange={(event) => {
											handleUpdateFinishChatsData(event.target.value as string, "userId")
										}}
									>
										{usersInInstance.data?.map((user) => {
											return <MenuItem
												key={user.id}
												value={user.id}
											>
												{user.name}
											</MenuItem>
										})}
									</Select>
								</FormControl>
							}
						</Grid>
					</Grid>
				</Grid>
				<Divider orientation="horizontal" size={2} />
				<Grid container spacing={2}>
					<Grid item>
						<FormControl fullWidth variant="outlined" size="small" required={true}>
							<InputLabel>
								Período
							</InputLabel>
							<Select
								variant="outlined"
								fullWidth
								label="Período"
								value={finishChatsData.operator}
								onChange={(event) => {
									handleUpdateFinishChatsData(event.target.value as string, "operator")
								}}
							>
								{sequelizeOperatorValues?.map((operator) => {
									return <MenuItem
										key={operator}
										value={operator}
									>
										{sequelizeOperatorToString[operator]}
									</MenuItem>
								})}
							</Select>
						</FormControl>
					</Grid>
					{selectedDateRangeOperator?.map((dateRange) => {
						return (
							<Grid item>
								<MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR} >
									<DateTimePicker
										ampm={false}
										size="small"
										format="dd/MM/yyyy HH:mm"
										animateYearScrolling
										label={dateRange.label}
										views={["year", "month", "date", "hours", "minutes"]}
										variant="inline"
										value={finishChatsData[dateRange.field as keyof FinishChatsDataType]}
										inputVariant="outlined"
										fullWidth
										onChange={(date) => handleUpdateFinishChatsData(date || new Date(), dateRange.field as keyof FinishChatsDataType)}
										autoOk
									/>
								</MuiPickersUtilsProvider>
							</Grid>
						)
					})}
				</Grid>
				<Divider orientation="horizontal" size={2} />

				<Grid item>
					<Typography variant="subtitle2">
						{turnDataInActionSummary()}
					</Typography>
				</Grid>
			</ActionDialog >
		</>
	)
}

export default ManageInstanceChats
