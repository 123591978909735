import { InfoDialog, JsonEditor, Notification } from "@/components"
import { Grid } from "@material-ui/core"
import React from "react"
import { PlanData, PlanLimits } from "@/pages/Account/protocols/PlanProtocol"
import { AccountAdminApi } from "@/services/Api"

interface UpdateLimitsFormDialogProps{
	planData: PlanData
	showDialog?: boolean
	setShowDialog?: (value: boolean) => void
	getPlans?: (searchValue?: string) => Promise<void>
}

const UpdateLimitsFormDialog = (props: UpdateLimitsFormDialogProps) => {
	const { planData, showDialog, setShowDialog, getPlans } = props

	const handleUpdateLimits = async (limits: PlanLimits) => {
		await AccountAdminApi.put(`/plans/${planData.id}`, {
			...planData,
			limits
		})
		setShowDialog && setShowDialog(false)
		getPlans && await getPlans()
		Notification.success({ message: "Limites atualizados." })
	}

	return <InfoDialog
		title="Atualizar limites"
		onClose={() => setShowDialog && setShowDialog(false)}
		openDialog={showDialog}
		fullWidth
	>
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<JsonEditor
					value={planData.limits as string}
					height="300px"
					onSave={ async (data) => {
						await handleUpdateLimits(data as PlanLimits)
					}}
				/>
			</Grid>
		</Grid>
	</InfoDialog>
}

export default UpdateLimitsFormDialog
