import { Divider, Loading, Notification, Portlet } from "@/components"
import { Grid, List, ListItem, ListItemText, Typography } from "@material-ui/core"
import React, { useState } from "react"
import useStyles from "@/pages/Account/SpreadsheetCustomers/Details/styles"
import CardSkeleton from "@/skeletons/CardSkeleton"
import { SpreadsheetInfoCard } from "@/pages/Account/SpreadsheetCustomers/Details/components/SpreadsheetInfoCard"
import { SpreadsheetCustomer } from "@/pages/Account/protocols/SpreadsheetCustomersProtocol"
import { formatBrazilianCurrency, formatCnpj, formatStringToPascalCase } from "@/utils/mask"
import { match } from "react-router-dom"
import { AccountAdminApi } from "@/services/Api"
import useDidMount from "@/hooks/useDidMount"
import { formatDateAndTimeBrazilianDate } from "@/utils/time"

type SpreadsheetCustomersDetailsProps = {
	match: match & {
		params: {
			id: number
		}
	}
}

const SpreadsheetCustomersDetails = (props: SpreadsheetCustomersDetailsProps) => {
	const {
		match
	} = props

	const classes = useStyles()

	const [data, setData] = useState<SpreadsheetCustomer>()
	const [pageLoading, setPageLoading] = useState<boolean>(true)

	const getSpreadsheetCustomer = async () => {
		setPageLoading(true)
		try {
			const { data } = await AccountAdminApi
				.get("/spreadsheet-customers/" + match.params.id, {
				})

			setData(data)
		} catch (error) {
			Notification.error({
				message: "Houve um erro ao tentar carregar os dados!"
			})
		}

		setPageLoading(false)
	}

	useDidMount(() => {
		getSpreadsheetCustomer()
	})

	return <Grid item xs={12}>
		<Grid container spacing={3}>
			<Grid item xs={12} md={6}>
				<Typography
					variant="h2"
					className={classes.title}
				>
				Informações Principais
				</Typography>

				<Divider size={2} orientation="horizontal" />

				<Loading
					customLoadingElement={
						<CardSkeleton
							cardHeight={200}
						/>}
					loading={pageLoading}
				>
					<Portlet
						style={{
							padding: "8px 16px",
							minHeight: "220px"
						}}
						elevation={1}
					>
						<Grid spacing={1} container justify="center" alignItems="center">
							<Grid item xs>
								<List>
									<ListItem
										className={classes.listItem}
									>
										<ListItemText
											primary={<Typography className={classes.listPrimaryText} variant="caption">CNPJ</Typography>}
											secondary={
												<SpreadsheetInfoCard
													name={formatCnpj(data?.document || "")}
												/>
											} />
									</ListItem>
									<ListItem
										className={classes.listItem}
									>
										<ListItemText
											primary={<Typography className={classes.listPrimaryText} variant="caption">Email de Pagamento</Typography>}
											secondary={
												<SpreadsheetInfoCard
													name={data?.payment_email}
												/>
											} />
									</ListItem>

									<ListItem
										className={classes.listItem}
									>
										<ListItemText
											primary={<Typography className={classes.listPrimaryText} variant="caption">Email da Conta</Typography>}
											secondary={
												<SpreadsheetInfoCard
													name={data?.account_email}
												/>
											} />
									</ListItem>

									<ListItem
										className={classes.listItem}
									>
										<ListItemText
											primary={<Typography className={classes.listPrimaryText} variant="caption">Bloqueado na Plataforma?</Typography>}
											secondary={
												<SpreadsheetInfoCard
													name={formatStringToPascalCase(data?.block_on_platform || "")}
												/>
											}
										/>
									</ListItem>

									<ListItem
										className={classes.listItem}
									>
										<ListItemText
											primary={<Typography className={classes.listPrimaryText} variant="caption">Data da Ativação</Typography>}
											secondary={
												<SpreadsheetInfoCard
													name={formatDateAndTimeBrazilianDate(data?.created_at || "").dateAndTime}
												/>
											}
										/>
									</ListItem>
								</List>
							</Grid>

						</Grid>
					</Portlet>
				</Loading>

			</Grid>
			<Grid item xs={12} md={6}>
				<Typography
					variant="h2"
					className={classes.title}
				>
				Informações Financeiras
				</Typography>

				<Divider size={2} orientation="horizontal" />

				<Loading
					customLoadingElement={
						<CardSkeleton
							cardHeight={200}
						/>}
					loading={pageLoading}
				>
					<Portlet
						style={{
							padding: "8px 16px",
							minHeight: "220px"
						}}
						elevation={1}
					>
						<Grid spacing={1} container justify="center" alignItems="center">
							<Grid item xs>
								<List>
									{/* <ListItem
										className={classes.listItem}
									>
										<ListItemText
											primary={<Typography className={classes.listPrimaryText} variant="caption">Data do Último Pagamento</Typography>}
											secondary={
												<SpreadsheetInfoCard
													name={formatDateAndTimeBrazilianDate(data?.last_payment_date || "").dateAndTime}
												/>
											} />
									</ListItem>
									<ListItem
										className={classes.listItem}
									>
										<ListItemText
											primary={<Typography className={classes.listPrimaryText} variant="caption">Data do Próximo Pagamento</Typography>}
											secondary={
												<SpreadsheetInfoCard
													name={formatDateAndTimeBrazilianDate(data?.next_payment_date || "").dateAndTime}
												/>
											} />
									</ListItem> */}

									<ListItem
										className={classes.listItem}
									>
										<ListItemText
											primary={<Typography className={classes.listPrimaryText} variant="caption">Método de Pagamento</Typography>}
											secondary={
												<SpreadsheetInfoCard
													name={formatStringToPascalCase(data?.payment_method || "")}
												/>
											} />
									</ListItem>

									<ListItem
										className={classes.listItem}
									>
										<ListItemText
											primary={<Typography className={classes.listPrimaryText} variant="caption">Periodicidade</Typography>}
											secondary={
												<SpreadsheetInfoCard
													name={formatStringToPascalCase(data?.periodicity || "")}
												/>
											}
										/>
									</ListItem>

									<ListItem
										className={classes.listItem}
									>
										<ListItemText
											primary={<Typography className={classes.listPrimaryText} variant="caption">MRR do Cliente</Typography>}
											secondary={
												<SpreadsheetInfoCard
													name={formatBrazilianCurrency(data?.customer_mrr || 0)}
												/>
											}
										/>
									</ListItem>

									<ListItem
										className={classes.listItem}
									>
										<ListItemText
											primary={<Typography className={classes.listPrimaryText} variant="caption">MRR da Assinatura</Typography>}
											secondary={
												<SpreadsheetInfoCard
													name={formatBrazilianCurrency(data?.subscription_mrr || 0)}
												/>
											}
										/>
									</ListItem>
								</List>
							</Grid>

						</Grid>
					</Portlet>
				</Loading>

			</Grid>
		</Grid>
	</Grid>
}

export default SpreadsheetCustomersDetails
