import React, { useEffect, useState } from "react"

import {
	Launch as LaunchIcon,
	KeyboardArrowDown as ArrowDownIcon,
	KeyboardArrowUp as ArrowUpIcon,
	Edit as EditIcon,
	Star as StarIcon
} from "@material-ui/icons"
import { UserAuthentication, UserProps, UserRoleProps, UserStatus, UserStatusMap } from "@/pages/Inbox/protocols/UserProtocol"
import { AccountAdminApi, InboxAdminApi } from "@/services/Api"
import { Box, Button, CircularProgress, Collapse, Grid, IconButton, Switch, Table, TableBody, TableCell, TableCellProps, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core"
import useInstancesStyles from "@/pages/Inbox/Instances/styles"
import { formatDateAndTimeBrazilianDate } from "@/utils/time"
import UserInInstanceCard from "@/pages/Inbox/Instances/components/UserInInstanceCard"
import { inboxAppUrl } from "@/config/url"
import { ActionDialog, Divider, InfoDialog, JsonEditor, Loading, Notification } from "@/components"
import { EditUserDialogDataType, UsersInInstanceWhereData } from "@/pages/Inbox/Instances/Details/UsersTable"
import useStyles from "@/pages/Inbox/Instances/Details/UsersTable/UserRow/style"
import hardcoded from "@/shared/utils/hardcoded"
import MoreOptionsMenu from "@/components/MoreOptionsMenu"
import { getErrorCodeMessages } from "@/utils/response"
import { ErrorType } from "@/hooks/useValidation"

const USER_STATUS_MAP: UserStatusMap = {
	active: "Ativo",
	blocked: "Bloqueado",
	invited: "Convidado"
}

type InstanceColumn = {
	name: "id"
	| "account_id"
	| "name"
	| "email"
	| "status"
	| "created_at"
	| "role"
	label: string
	align?: TableCellProps["align"]
	style?: React.CSSProperties
	formatDate?: (value: string) => string
	getRoleName?: (userRole: UserRoleProps) => string
	formatStatus?: (userRole: UserStatus) => string
}

export const TableColumns: InstanceColumn[] = [
	{
		name: "id",
		label: "ID"
	},
	{
		name: "created_at",
		label: "Criado em",
		formatDate: (value: string): string => formatDateAndTimeBrazilianDate(value)?.dateAndTime || " - "
	},
	{
		name: "name",
		label: "Nome"
	},
	{
		name: "email",
		label: "Email",
		style: {
			wordBreak: "break-word"
		}
	},
	{
		name: "status",
		label: "Status",
		formatStatus: (userStatus: UserStatus) => USER_STATUS_MAP[userStatus]
	},
	{
		name: "role",
		label: "Tipo",
		getRoleName: (userRole: UserRoleProps) => userRole.name
	}
]

type OpenedInviteInfo = {
	name: string
	email: string
	invitedRole: string
	invitationLink: string
}

type UserRowProps = {
	appInstanceId: number
	instanceId: number
	userData: UserProps
	isInstanceOwner: boolean
	setEditUserDialogData: React.Dispatch<React.SetStateAction<EditUserDialogDataType>>
	getUsersInInstance: (newWhereData?: Partial<UsersInInstanceWhereData>) => Promise<void>
	getInstanceInfo: () => Promise<void>
}

export const UserRow: React.FC<UserRowProps> = (props) => {
	const {
		userData,
		instanceId,
		appInstanceId,
		isInstanceOwner,
		getInstanceInfo,
		getUsersInInstance,
		setEditUserDialogData
	} = props

	const classes = useStyles()
	const instancesClasses = useInstancesStyles()
	const [collapseOpen, setCollapseOpen] = useState<boolean>(false)
	const [isJsonDialogOpened, setIsJsonDialogOpened] = useState<boolean>(false)
	const [isPasswordRenewDialogOpened, setIsPasswordRenewDialogOpened] = useState<boolean>(false)
	const [isUserAuthsDialogOpened, setIsUserAuthsDialogOpened] = useState<boolean>(false)

	const [logInUserUrlIdLoading, setLogInUserUrlIdLoading] = useState<boolean>(false)
	const [isUserLoginAuthLoading, setIsUserLoginAuthLoading] = useState<boolean>(false)
	const [isInviteDialogOpened, setIsInviteDialogOpened] = useState<boolean>(false)

	const [userInvitationInfo, setUserInvitationInfo] = useState<OpenedInviteInfo>()
	const [userLoginAuthentication, setUserLoginAuthentication] = useState<UserAuthentication>({
		id: 0,
		active: false,
		type: "email-login"
	})
	const actualUserLoginAuthentication = userData.authentications?.find(authentication => authentication.type === "email-login")

	const userHasPasswordRenewInfoFullfiled = Boolean(userData?.reset_password_token && userData?.reset_password_sent_at)

	const canEditAuthentications = hardcoded.canChangeUserMfa()

	const handleOpenPasswordRenewDialog = () => {
		setIsPasswordRenewDialogOpened(true)
	}

	const handleClosePasswordRenewDialog = () => {
		setIsPasswordRenewDialogOpened(false)
	}

	const handleOpenUserAuthsDialog = () => {
		setIsUserAuthsDialogOpened(true)
	}

	const handleCloseUserAuthsDialog = () => {
		setIsUserAuthsDialogOpened(false)
	}

	const handleOpenJsonDialog = () => {
		setIsJsonDialogOpened(true)
	}

	const handleJsonDialogClose = () => {
		setIsJsonDialogOpened(false)
	}

	const handleLogInUser = async () => {
		setLogInUserUrlIdLoading(true)
		try {
			const { data } = await InboxAdminApi.get(`/auth/login-user/${userData.id}/${instanceId}`)

			window.open(data.urlRedirect, "_blank")
		} catch (error) {
			Notification.error({
				message: "Houve um erro ao realizar login como usuário, por favor tente novamente"
			})
		}
		setLogInUserUrlIdLoading(false)
	}

	const handleMfaChange = async () => {
		setIsUserLoginAuthLoading(true)

		try {
			await InboxAdminApi.put("/authentication/change", {
				type: "email-login",
				isActive: userLoginAuthentication.active,
				userId: userData.id
			})

			setUserLoginAuthentication({
				...userLoginAuthentication,
				active: !userLoginAuthentication.active
			})

			Notification.success({ message: `MFA ${userLoginAuthentication.active ? "Desabilitado" : "Habilitado"} com sucesso!` })
		} catch (err) {
			Notification.error({ message: "Erro ao alterar MFA, busque o suporte" })
		}

		setIsUserLoginAuthLoading(false)
	}

	const handleChangeOwner = async () => {
		try {
			await AccountAdminApi.put(`/app-instance/change-owner/${appInstanceId}`, {
				inboxInstanceId: instanceId,
				targetInboxOwnerUserId: userData.id,
				targetInboxOwnerAccountId: userData.account_id,
				targetInboxOwnerUserName: userData.name,
				targetInboxOwnerUserEmail: userData.email,
				appCode: "inbox_app"
			})

			await getInstanceInfo?.()
			await getUsersInInstance?.()

			Notification.success({ message: "Dono alterado com sucesso!" })
		} catch (err) {
			const codeMessages = getErrorCodeMessages(err as ErrorType)

			if (codeMessages?.ownerChange === "unable_change_instance_owner") {
				Notification.error({ message: `Não foi possível alterar o dono da instância ${instanceId}` })
				return
			}

			Notification.error({ message: `Erro ao alterar dono da instância ${instanceId}` })
		}
	}

	const handleOpenEditUserDialog = (userData: UserProps) => {
		setEditUserDialogData({
			open: true,
			user: {
				...userData,
				email: userData.email,
				id: userData.id
			}
		})
	}

	const getUserMenuOptions = () => {
		const options = [
			{
				title: "Editar",
				icon: <EditIcon />,
				showIcon: true,
				onClick: () => { handleOpenEditUserDialog(userData) }
			}
		]

		if (!isInstanceOwner && hardcoded.canChangeInstanceOwner()) {
			options.push({
				title: "Tornar dono",
				icon: <StarIcon
					style={{
						color: "#E809AE"
					}}
				/>,
				showIcon: true,
				onClick: handleChangeOwner
			})
		}

		return options
	}

	const copyInvitationLink = () => {
		navigator.clipboard.writeText(userInvitationInfo?.invitationLink || "")
		Notification.info({
			message: "Convite copiado para área de transferência"
		})
	}

	const handleOpenInviteDialog = (inviteInfo: OpenedInviteInfo) => {
		setUserInvitationInfo(inviteInfo)
		setIsInviteDialogOpened(true)
	}

	const handleCloseInviteDialog = () => {
		setIsInviteDialogOpened(false)
	}

	useEffect(() => {
		if (actualUserLoginAuthentication) {
			setUserLoginAuthentication(actualUserLoginAuthentication)
		}
	}, [actualUserLoginAuthentication])

	return (
		<>
			<TableRow
				className={instancesClasses.tableRow}
			>
				<TableCell>
					<IconButton aria-label="expand row" size="small" onClick={() => setCollapseOpen(!collapseOpen)}>
						{collapseOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
					</IconButton>
				</TableCell>

				{TableColumns
					.map((column, index) => {
						let value = userData[column.name]

						if (column?.formatDate) {
							value = column.formatDate(value as string)
						}

						if (column?.getRoleName) {
							value = column.getRoleName(value as UserRoleProps)
						}

						if (column?.formatStatus) {
							value = column.formatStatus(value as UserStatus)
						}

						if (isInstanceOwner) {
							return (
								<TableCell
									style={column?.style}
									key={index}
									align={column?.align}
								>
									{
										column.label === "Tipo"
											? <>
												{value}
												<UserInInstanceCard
													role_code="admin"
												/>
											</>
											: value
									}
								</TableCell>
							)
						} else {
							return (
								<TableCell
									style={column?.style}
									key={index}
									align={column?.align}
								>
									{value}
								</TableCell>
							)
						}
					})}

				<TableCell align="center">
					<Tooltip
						title={"Autenticar com esse usuário"}
					>
						<IconButton
							disabled={logInUserUrlIdLoading}
							onClick={handleLogInUser}
						>
							{
								logInUserUrlIdLoading
									? <CircularProgress size={20} color="inherit" />
									: <LaunchIcon />
							}
						</IconButton>
					</Tooltip>
					<MoreOptionsMenu
						options={getUserMenuOptions()}
					/>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
					<Collapse in={collapseOpen} timeout="auto" unmountOnExit>
						<Box style={{ padding: "16px" }} margin={1}>
							<Typography variant="h6" gutterBottom component="div">
								Informações adicionais
							</Typography>

							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell>Extras</TableCell>
										<TableCell>Convite - Info.</TableCell>
										<TableCell>Red. de senha - Info.</TableCell>
										<TableCell>Autenticações - Info.</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell>
											<Grid spacing={2} container>
												<Grid item>
													<Button
														onClick={handleOpenJsonDialog}
														color="primary"
														variant="outlined"
													>
														Ver
													</Button>
												</Grid>
											</Grid>
										</TableCell>

										<TableCell>
											<Grid spacing={2} container>
												<Tooltip
													title={userData.status !== "invited" ? "Usuário precisa estar \"convidado\"" : ""}
												>
													<Grid item>
														<Button
															onClick={() => {
																handleOpenInviteDialog({
																	email: userData.email,
																	name: userData.name,
																	invitedRole: userData.invitation_extra_data.user_in_instance_type || "",
																	invitationLink: `${inboxAppUrl.cpURL}/invite/${userData.invitation_extra_data.invitation_code}`
																})
															}}
															color="primary"
															variant="outlined"
															disabled={userData.status !== "invited"}
														>
															Ver
														</Button>
													</Grid>
												</Tooltip>
											</Grid>
										</TableCell>

										<TableCell>
											<Grid spacing={2} container>
												<Tooltip
													title={userHasPasswordRenewInfoFullfiled ? "Abrir informações de redefinição de senha" : "Usuário não possui dados de redefinição de senha"}
												>
													<Grid item>
														<Button
															onClick={handleOpenPasswordRenewDialog}
															color="primary"
															variant="outlined"
															disabled={!userHasPasswordRenewInfoFullfiled}
														>
															Ver
														</Button>
													</Grid>
												</Tooltip>
											</Grid>
										</TableCell>

										<TableCell>
											<Grid spacing={2} container>
												<Tooltip
													title={canEditAuthentications ? "" : "Seu usuário não possui esta permissão"}
												>
													<Grid item>
														<Button
															onClick={handleOpenUserAuthsDialog}
															color="primary"
															variant="outlined"
															disabled={!canEditAuthentications}
														>
															Ver
														</Button>
													</Grid>
												</Tooltip>
											</Grid>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>

			<InfoDialog
				title={"Informações de autenticação"}
				openDialog={isUserAuthsDialogOpened}
				onClose={handleCloseUserAuthsDialog}
			>
				<Grid
					container
					className={classes.mfaContainer}
				>
					<Grid item>
						<Typography style={{ fontWeight: 700 }}>
							{`${userLoginAuthentication.active ? "Desabilitar" : "Habilitar"} MFA`}
						</Typography>
					</Grid>
					<Grid item>
						{
							isUserLoginAuthLoading ? (
								<Loading
									loading={true}
								></Loading>
							) : (
								<IconButton>
									<Switch
										checked={userLoginAuthentication.active}
										onClick={handleMfaChange}
									/>
								</IconButton>
							)
						}
					</Grid>
				</Grid>
			</InfoDialog>

			<InfoDialog
				title={"Informações de redefinição de senha"}
				openDialog={isPasswordRenewDialogOpened}
				onClose={handleClosePasswordRenewDialog}
			>
				<Grid
					item
					xs
				>
					<Typography variant="body1">
						<strong>Token:</strong> {userData?.reset_password_token || " - "}
					</Typography>
					<Typography variant="body1">
						<strong>Data de envio do token:</strong> {formatDateAndTimeBrazilianDate(userData?.reset_password_sent_at)?.dateAndTime || " - "}
					</Typography>
				</Grid>
			</InfoDialog>

			<InfoDialog
				title={"Visualizar"}
				openDialog={isJsonDialogOpened}
				onClose={handleJsonDialogClose}
			>
				{
					userData.extra_data &&
					<JsonEditor
						value={userData.extra_data as unknown as string}
						readOnly={true}
						height="350px"
					/>
				}
			</InfoDialog>

			<ActionDialog
				title={"Informações de convite"}
				hideSaveButton
				hideCancelButton
				onClose={handleCloseInviteDialog}
				openDialog={isInviteDialogOpened}
				fullWidth
			>
				<Grid xs>
					<Grid
						item
						xs
					>
						<Typography variant="body1">
							<strong>Email do usuário:</strong> {userInvitationInfo?.email}
						</Typography>
						<Typography variant="body1">
							<strong>Nome do usuário:</strong> {userInvitationInfo?.name}
						</Typography>
						<Typography variant="body1">
							<strong>Usuário convidado com o tipo:</strong> {userInvitationInfo?.invitedRole}
						</Typography>
						<Typography variant="body1">
							<strong>Link para aceitar convite:</strong> {userInvitationInfo?.invitationLink}
						</Typography>
					</Grid>

					<Divider orientation="horizontal" size={2} />

					<Grid
						item
						xs
					>
						<Button
							onClick={copyInvitationLink}
							color="primary"
							variant="outlined"
							className={classes.copyInviteLinkButton}
						>
							Copiar link de convite
						</Button>
					</Grid>
				</Grid>
			</ActionDialog>
		</>
	)
}
