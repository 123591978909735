import axios from "axios"
import axiosRetry from "axios-retry"

import AuthService from "@/services/Auth"

import apiConfig from "@/config/api"

export const AccountAdminApi = axios.create({
	baseURL: apiConfig.accountAdminApiUrl,
	withCredentials: true
})

axiosRetry(AccountAdminApi, {
	retries: 5,
	retryDelay: (retryCount) => 1000 * retryCount
})

AccountAdminApi.interceptors.request.use(config => {
	config.headers[AuthService.authAdminTokenKey] = AuthService.authAdminTokenKey

	return config
})

export const InboxAdminApi = axios.create({
	baseURL: apiConfig.inboxAdminApiUrl,
	withCredentials: true
})

axiosRetry(InboxAdminApi, {
	retries: 5,
	retryDelay: (retryCount) => 1000 * retryCount
})

InboxAdminApi.interceptors.request.use(config => {
	config.headers[AuthService.authAdminTokenKey] = AuthService.authAdminTokenKey

	return config
})

export const InboxWhatsappChannelApi = axios.create({
	baseURL: apiConfig.inboxWhatsappChannelApiUrl,
	withCredentials: true
})

axiosRetry(InboxWhatsappChannelApi, {
	retries: 5,
	retryDelay: (retryCount) => 1000 * retryCount
})

InboxWhatsappChannelApi.interceptors.request.use(config => {
	config.headers[AuthService.authAdminTokenKey] = AuthService.authAdminTokenKey

	return config
})
