import { ActionDialog, CustomTextField, Notification } from "@/components"
import { Grid } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { PlanData } from "@/pages/Account/protocols/PlanProtocol"
import { AccountAdminApi } from "@/services/Api"
import useValidation from "@/hooks/useValidation"

interface CreatePlanFormDialogProps{
	showCreatePlanDialog?: boolean
	setShowCreatePlanDialog?: (value: boolean) => void
	getPlans?: (searchValue?: string) => Promise<void>
}

const CreatePlanFormDialog = (props: CreatePlanFormDialogProps) => {
	const { showCreatePlanDialog, setShowCreatePlanDialog, getPlans } = props
	const [formData, setFormData] = useState<PlanData>({} as PlanData)
	const [actionDialogLoading, setActionDialogLoading] = useState<boolean>(false)

	const { validation, triggerValidation, clearValidation } = useValidation()

	const handleCreatePlan = async (data: PlanData) => {
		setActionDialogLoading(true)
		try {
			await AccountAdminApi.post("/plans", {
				...data,
				app_id: 2,
				organization_type_id: 1,
				is_finished: false,
				limits: {}
			})

			setFormData({} as PlanData)
			setShowCreatePlanDialog && setShowCreatePlanDialog(false)

			getPlans && await getPlans()
			Notification.success({ message: "Plano adicionado com sucesso." })
		} catch (error) {
			Notification.error({ message: "Houve um erro." })
			triggerValidation(error)
		}
		setActionDialogLoading(false)
	}

	const handleInputPlanChange = (name: string, value: string) => {
		clearValidation(name)

		const isInvalidNumber = !+value
		const isStringCharacter = value !== "0"

		const isPossiblyStringValue = isInvalidNumber && isStringCharacter

		if (isPossiblyStringValue) {
			setFormData({ ...formData, [name]: value })
		} else {
			setFormData({ ...formData, [name]: +value })
		}
	}

	useEffect(() => {
		setFormData({} as PlanData)
	}, [showCreatePlanDialog])

	return <ActionDialog
		title="Criar novo plano"
		onSave={() => handleCreatePlan(formData)}
		saveText="Pronto"
		onClose={() => setShowCreatePlanDialog && setShowCreatePlanDialog(false)}
		loading={actionDialogLoading}
		openDialog={showCreatePlanDialog}
		fullWidth
	>
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<CustomTextField
					value={formData.code}
					onChange={
						({ target }) => handleInputPlanChange("code", target.value)
					}
					variant="outlined"
					placeholder="Código"
					fullWidth
					helperText={validation.code}
					error={!!validation.code}
				/>
			</Grid>

			<Grid item xs={12}>
				<CustomTextField
					value={formData.display_name}
					onChange={
						({ target }) => handleInputPlanChange("display_name", target.value)
					}
					variant="outlined"
					placeholder="Nome"
					fullWidth
					helperText={validation.display_name}
					error={!!validation.display_name}
				/>
			</Grid>

			<Grid item xs={12}>
				<CustomTextField
					value={formData.price_in_cents}
					onChange={
						({ target }) => handleInputPlanChange("price_in_cents", target.value)
					}
					variant="outlined"
					placeholder="Preço(em centavos)"
					fullWidth
					helperText={validation.price_in_cents}
					error={!!validation.price_in_cents}
				/>
			</Grid>
		</Grid>
	</ActionDialog>
}

export default CreatePlanFormDialog
