import React from "react"

import { InboxWABAChannelStatus } from "@/pages/Inbox/protocols/ChannelProtocol"
import { Chip, Tooltip } from "@material-ui/core"
import { getWABAChannelStatusData } from "@/channels/WABA/utils/channel"

type WABAChipSessionStatusProps = {
	status: InboxWABAChannelStatus
}

const WABAChipSessionStatus = (props: WABAChipSessionStatusProps) => {
	const {
		status
	} = props

	const {
		description,
		label,
		customStyle,
		icon
	} = getWABAChannelStatusData(status)

	return (
		<>
			<Tooltip
				title={description}
			>
				<Chip
					label={label}
					style={{
						...customStyle,
						fontWeight: "bold"
					}}
					icon={icon}
				/>
			</Tooltip>
		</>
	)
}

export default WABAChipSessionStatus
