import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles({
	title: {
		color: colors.grayScale[1],
		textTransform: "uppercase",
		fontWeight: "bold"
	},
	paper: {
		width: "100%"
	},
	tableRow: {
		cursor: "auto",
		textDecoration: "none !important"
	},
	deleteIcon: {
		padding: "5px"
	},
	editIcon: {
		padding: "5px"
	}
})

export default useStyles
