import React from "react"
import {
	Wifi as ConnectedIcon,
	WifiOff as DisconnectedIcon,
	ReportProblemOutlined as RestrictedIcon,
	BlockOutlined as BannedIcon
} from "@material-ui/icons"

import {
	InboxChannelBrokerType,
	InboxWABAChannelStatus
} from "@/pages/Inbox/protocols/ChannelProtocol"

import { InboxChannelStatusData } from "@/utils/channelStatus"

import newColors from "@/styles/newColors"

export const WABAStatusData: Record<InboxWABAChannelStatus, InboxChannelStatusData> = {
	connected: {
		label: "Conectado",
		icon: <ConnectedIcon style={{ width: 16, height: 16, color: newColors.grey[50] }} />,
		description: "O canal WABA está configurado e integrado com o inbox.",
		customStyle: {
			backgroundColor: newColors.green[600],
			color: newColors.grey[50]
		}
	},
	restricted: {
		label: "Canal restringido",
		icon: <RestrictedIcon style={{ width: 16, height: 16, color: newColors.grey[50] }} />,
		description: "O canal WABA está configurado e integrado com o inbox.",
		customStyle: {
			backgroundColor: newColors.yellow[500],
			color: newColors.grey[50]
		}
	},
	banned: {
		label: "Canal banido",
		icon: <BannedIcon style={{ width: 16, height: 16, color: newColors.grey[50] }} />,
		description: "O canal WABA está configurado e integrado com o inbox.",
		customStyle: {
			backgroundColor: newColors.red[600],
			color: newColors.grey[50]
		}
	},
	disconnected: {
		label: "Nenhum canal conectado",
		icon: <DisconnectedIcon style={{ width: 16, height: 16, color: newColors.grey[500] }} />,
		description: "O canal WABA está configurado e integrado com o inbox.",
		customStyle: {
			backgroundColor: newColors.grey[100],
			color: newColors.grey[500]
		}
	}
}

export const getWABAChannelStatusData = (status: InboxWABAChannelStatus): InboxChannelStatusData => {
	return {
		customStyle: WABAStatusData[status]?.customStyle,
		icon: WABAStatusData[status]?.icon,
		label: WABAStatusData[status]?.label,
		description: WABAStatusData[status]?.description
	}
}

export const getWABAChannelStatusPlatformName = (wabaBrokerType: InboxChannelBrokerType): string => {
	const wabaInboxChannelLibraryClientTypeToPlatformName: Record<InboxChannelBrokerType, string> = {
		gupshup: "Gupshup"
	}

	const platformName = wabaInboxChannelLibraryClientTypeToPlatformName[wabaBrokerType] || "Canal não configurado"

	return `WABA - ${platformName}`
}

export const getWABAChannelStatusFilterLabel = (status: InboxWABAChannelStatus): string => {
	const {
		label
	} = getWABAChannelStatusData(status)

	return `WABA - ${label}`
}
