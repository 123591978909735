import React, { createContext, useContext, useState } from "react"

type UserData = {
	id?: number,
	name?: string,
	email?: string
}

export interface GlobalStateContextData {
	userData: UserData
	setUserData: (data?: UserData) => void
}

const GlobalStateStore = createContext<GlobalStateContextData>({} as GlobalStateContextData)

export const useGlobalStateStore = () => useContext(GlobalStateStore)

const GlobalStateProvider: React.FC = (props) => {
	const { children } = props

	const [userData, setUser] = useState<UserData>({} as UserData)

	const setUserData = (
		data?: UserData
	) => {
		setUser(lastState => ({
			...lastState,
			...data
		}))
	}

	GlobalStateStore.displayName = "GlobalStateStore"

	return (
		<GlobalStateStore.Provider
			value={{
				userData,
				setUserData
			}}
		>
			{children}
		</GlobalStateStore.Provider>
	)
}

export default GlobalStateProvider
