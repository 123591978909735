import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"

import {
	Grid, IconButton, InputAdornment,
	Paper, Table, TableBody,
	TableCell, TableCellProps, TableContainer,
	TableHead, TablePagination, TableRow,
	TextField, Typography, FormGroup,
	FormControlLabel, Checkbox
} from "@material-ui/core"

import {
	Search as SearchIcon,
	Close as CloseIcon,
	Delete as DeleteIcon,
	Refresh as ReloadIcon
} from "@material-ui/icons"

import { ptBR } from "date-fns/locale"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"

import { AccountAdminApi } from "@/services/Api"

import useDebounce from "@/hooks/useDebounce"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import useDidMount from "@/hooks/useDidMount"

import { Divider, Notification, Loading, Portlet, PopConfirm } from "@/components"

import useStyles from "@/pages/Account/Account/styles"
import CardSkeleton from "@/skeletons/CardSkeleton"
import { isSmallScreen } from "@/utils/checkDevice"
import { formatDateAndTimeBrazilianDate } from "@/utils/time"

import { formatCnpj, formatPhoneNumber } from "@/utils/mask"
import { GetAccountDataResponse } from "@/pages/Account/protocols/AccountProtocol"
import useCustomStyles from "@/styles/custom"
import HardCodedUtil from "@/shared/utils/hardcoded"
import useQuery from "@/hooks/useQuery"

type AccountColumn = {
	name: "id"
	| "name"
	| "email"
	| "phone_number"
	| "created_at"
	| "cnpj"
	label: string
	formatDate?: (value: string) => string
	formatPhoneNumber?: (value: string) => string
	formatCnpj?: (value: string) => string
	style?: React.CSSProperties
	align?: TableCellProps["align"]
}

const TableColumns: AccountColumn[] = [
	{
		name: "id",
		label: "ID"
	},
	{
		name: "name",
		label: "Razão Social"
	},
	{
		name: "cnpj",
		label: "CNPJ",
		formatCnpj: (value: string) => formatCnpj(value)
	},
	{
		name: "email",
		label: "Email"
	},
	{
		name: "phone_number",
		label: "Celular",
		formatPhoneNumber: (value: string) => formatPhoneNumber(value) as string
	},
	{
		name: "created_at",
		label: "Cadastro em",
		formatDate: (value: string) => formatDateAndTimeBrazilianDate(value)?.dateAndTime || " - "
	}
]

type AccountWhereData = {
	showDeletedAccounts: boolean,
	page: number
	rowsPerPage: number
	search?: string
	startCreated?: Date
	endCreated?: Date
}

const Accounts = () => {
	const [accounts, setAccounts] = useState<GetAccountDataResponse>(
		{} as GetAccountDataResponse
	)
	const [loading, setLoading] = useState<boolean>(true)

	const [showDeletedAccounts, setShowDeletedAccounts] = useState<boolean>(false)

	const [whereData, setWhereData] = useState<AccountWhereData>({
		showDeletedAccounts,
		page: 0,
		rowsPerPage: 50
	})

	const classes = useStyles()
	const customClasses = useCustomStyles()

	// const history = useHistory()
	const query = useQuery()

	const cnpjQueryParam = query.get("cnpj")

	const {
		triggerValidation
	} = useValidation()

	const getAccounts = async (newWhereData?: Partial<AccountWhereData>) => {
		setLoading(true)
		try {
			const { data } = await AccountAdminApi
				.get("/accounts", {
					params: {
						...whereData,
						...newWhereData,
						cnpj: cnpjQueryParam || undefined
					}
				})

			// if (data.count === 1) {
			// 	const firstRow = data?.rows?.shift()
			// 	history.push("/account/" + firstRow?.id)
			// }

			setAccounts(data)
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setLoading(false)
	}

	const handleWhereDataChange = (newData: Partial<AccountWhereData>) => {
		setWhereData((currentState) => (
			{
				...currentState,
				...newData
			}))
	}

	const handleSearchChange = async (search: string) => {
		handleWhereDataChange({
			search,
			page: 0
		})
	}

	useDebounce(
		async () => await getAccounts({ search: whereData?.search }),
		whereData.search,
		1250
	)

	const handlePageChange = async (event: unknown, page: number) => {
		handleWhereDataChange({
			page
		})

		await getAccounts({ page })
	}

	const handleRowsPerPageChange = async (rowsPerPage: number) => {
		handleWhereDataChange({
			rowsPerPage
		})
		await getAccounts({ rowsPerPage })
	}

	const handleDateFilterChange = async (
		type: "startCreated" | "endCreated",
		date: Date | null
	) => {
		const newDate = date ? new Date(date).toLocaleDateString("en-US") : null

		handleWhereDataChange({
			[type as string]: newDate
		})

		await getAccounts({ [type as string]: newDate })
	}

	const deleteOneAccount = async (accountId: number) => {
		try {
			const { data } = await AccountAdminApi.delete(`/account/${accountId}`)
			const wasDeleted = Boolean(data)

			if (wasDeleted) {
				Notification.success({ message: `Conta de id ${accountId} deletada com sucesso` })
			} else {
				Notification.warning({ message: `Houve um erro ao deletar conta de id ${accountId}` })
			}

			await getAccounts()
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
	}

	const handleDeleteAccount = async (accountId: number) => {
		const props = {
			description: "Deseja deletar esta conta?",
			confirmButtonText: "EXCLUIR",
			onConfirm: () => deleteOneAccount(accountId)
		}
		PopConfirm.open(props)
	}

	const handleCheckedFilterChange = async () => {
		setLoading(true)
		if (showDeletedAccounts) {
			setShowDeletedAccounts(false)
		} else {
			setShowDeletedAccounts(true)
		}
	}
	useEffect(() => {
		setWhereData((currentState) => ({
			...currentState, showDeletedAccounts
		}))
	}, [showDeletedAccounts])

	useDebounce(
		async () => await getAccounts({ showDeletedAccounts }),
		showDeletedAccounts,
		0
	)

	useDidMount(() => {
		getAccounts()
	})

	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography color="textPrimary" className={classes.title}>
					Accounts
				</Typography>

				<Divider size={1} orientation="horizontal" />

				<Portlet elevation={1}>
					<Grid container>
						<Grid item xs={12}>
							<Grid container spacing={3} justify="space-between" alignItems="center">
								<Grid item xs={12} md={6}>
									<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
										<Grid spacing={2} container alignItems="center" justify="flex-start">
											<Grid item xs={12} md={6}>
												<Grid container alignItems="center" justify="space-evenly">
													<Grid item xs>
														<DatePicker
															id="startCreated"
															variant={isSmallScreen ? "dialog" : "inline"}
															inputVariant="outlined"
															disableFuture
															format="dd/MM/yyyy"
															animateYearScrolling
															views={["year", "month", "date"]}
															value={whereData?.startCreated || null}
															onChange={(date) =>
																handleDateFilterChange("startCreated", date as Date)
															}
															maxDate={whereData?.endCreated || undefined}
															maxDateMessage="Não pode ser maior que a data final"
															color="primary"
															className={customClasses.datePicker}
															InputProps={{
																className: customClasses.datePickerInput
															}}
															cancelLabel="Cancelar"
															autoOk
															emptyLabel="Digite a data inicial"
														/>
													</Grid>

													{whereData?.startCreated && (
														<Grid item>
															<IconButton
																className={classes.dateDeleteIcon}
																onClick={() =>
																	handleDateFilterChange("startCreated", null)
																}
															>
																<CloseIcon fontSize="small" />
															</IconButton>
														</Grid>
													)}
												</Grid>
											</Grid>

											<Grid item xs={12} md={6}>
												<Grid
													container
													alignItems="center"
													justify="space-evenly"
												>
													<Grid item xs>
														<DatePicker
															id="endCreated"
															variant={isSmallScreen ? "dialog" : "inline"}
															inputVariant="outlined"
															disableFuture
															format="dd/MM/yyyy"
															animateYearScrolling
															views={["year", "month", "date"]}
															value={whereData?.endCreated || null}
															onChange={(date) =>
																handleDateFilterChange("endCreated", date as Date)
															}
															minDate={whereData?.startCreated || undefined}
															minDateMessage="Não pode ser maior que a data inicial"
															color="primary"
															className={customClasses.datePicker}
															InputProps={{
																className: customClasses.datePickerInput
															}}
															cancelLabel="Cancelar"
															autoOk
															emptyLabel="Digite a data final"
														/>
													</Grid>

													{whereData?.endCreated && (
														<Grid item>
															<IconButton
																className={classes.dateDeleteIcon}
																onClick={() =>
																	handleDateFilterChange("endCreated", null)
																}
															>
																<CloseIcon fontSize="small" />
															</IconButton>
														</Grid>
													)}
												</Grid>
											</Grid>
										</Grid>
									</MuiPickersUtilsProvider>
								</Grid>

								<Grid item xs={12} md={5}>
									<TextField
										value={whereData.search}
										onChange={({ target }) =>
											handleSearchChange(target.value)
										}
										placeholder="Pesquisar"
										variant="outlined"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<SearchIcon />
												</InputAdornment>
											),
											endAdornment: whereData.search && (
												<IconButton
													size="small"
													onClick={() => handleWhereDataChange({
														search: ""
													})}
												>
													<CloseIcon fontSize="small" />
												</IconButton>
											)
										}}
										fullWidth
										size="small"
									/>
								</Grid>

								<Grid item xs={1}>
									<IconButton disabled={loading} onClick={() => getAccounts()}>
										<ReloadIcon />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>

						<Divider size={1} orientation="horizontal" />

						<Grid item xs={12}>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={showDeletedAccounts}
											onChange={handleCheckedFilterChange}
										/>}
									label="Mostrar contas deletadas"
								/>
							</FormGroup>
						</Grid>

						<Divider size={3} orientation="horizontal" />

						<Grid item xs={12}>
							<Loading
								customLoadingElement={
									<CardSkeleton cardHeight={200} />}
								loading={loading}>

								{
									accounts.count > 0
										? <Paper className={classes.paper}>
											<TableContainer>
												<Table stickyHeader aria-label="sticky table">
													<TableHead>
														<TableRow>
															{TableColumns
																.map((column, index) => (
																	<TableCell key={index}>
																		{column.label}
																	</TableCell>
																))}
															<TableCell align="center">
																Ações
															</TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{
															accounts.rows.map((account, index) => {
																return (
																	<TableRow
																		hover
																		tabIndex={-1}
																		key={index}
																		className={classes.tableRow}
																	>
																		{TableColumns
																			.map((column, index) => {
																				let value = account[column.name] as string
																				let deletedAtValue = account.deleted_at as unknown as string
																				let extraDataValue: string | undefined

																				if (column?.formatDate) {
																					value = column.formatDate(value)
																					deletedAtValue = column.formatDate(deletedAtValue)
																				}

																				if (column?.formatPhoneNumber) {
																					value = column.formatPhoneNumber(value)
																				}

																				if (column?.formatCnpj) {
																					value = column.formatCnpj(value || "")
																				}

																				if (column?.name === "id") {
																					extraDataValue = account.extra_data?.payment_identification?.id_integration
																				}

																				if (column?.name === "name") {
																					extraDataValue = account.extra_data?.payment_identification?.name
																				}

																				if (column?.name === "email") {
																					extraDataValue = account.extra_data?.payment_identification?.email
																				}

																				if (column?.name === "phone_number") {
																					extraDataValue = account.extra_data?.payment_identification?.phone
																				}

																				return (
																					<TableCell
																						key={index}
																						style={column?.style}
																						align={column?.align}
																					>
																						{column.name === "created_at" &&
																							account.deleted_at !== null
																							? value &&
																							<div>
																								<div>
																									{value}
																								</div>
																								<div className={classes.deletedAtDate} >
																									{deletedAtValue}
																								</div>
																							</div>
																							: column.name === "name"
																								? <Link
																									target="_blank"
																									rel="noopener noreferrer"
																									to={`/account/${account.id}`}
																								>
																									{value}<br />
																									<Typography variant="body2" > {extraDataValue || "---"} </Typography>
																								</Link>
																								: column.name !== "created_at" ? <div>
																									{value} <br />
																									<Typography variant="body2"> <i>{extraDataValue || "---"}</i> </Typography>
																								</div> : value
																						}
																					</TableCell>
																				)
																			})
																		}
																		{!account.deleted_at
																			? <TableCell
																				align="center"
																			>
																				<IconButton
																					disabled={!HardCodedUtil.canDeleteAccountAndInstanceOnCascade()}
																					onClick={(event) => {
																						event.preventDefault()
																						handleDeleteAccount(account.id)
																					}}
																					key={index}
																					className={classes.deleteIcon}>
																					<DeleteIcon fontSize="small" />
																				</IconButton>
																			</TableCell>
																			: <TableCell align="center">
																				{null}
																			</TableCell>
																		}
																	</TableRow>
																)
															})}
													</TableBody>
												</Table>
											</TableContainer>

											<TablePagination
												rowsPerPageOptions={[20, 50, 100, 200]}
												component="div"
												count={accounts.count}
												rowsPerPage={whereData.rowsPerPage}
												page={whereData.page}
												onPageChange={handlePageChange}
												onRowsPerPageChange={({ target }) => handleRowsPerPageChange(+target.value)}
												labelRowsPerPage={"Resultados por página:"}
												labelDisplayedRows={e =>
													`Página ${e.page + 1} de ${Math.ceil(e.count / whereData.rowsPerPage)} | Total de resultados: ${accounts?.count}`
												}
											/>
										</Paper>
										: (
											<>
												<Divider size={3} orientation="horizontal" />
												<Typography align="center" variant="h2">
													Nenhum <b>account</b> cadastrado
												</Typography>
											</>)
								}
							</Loading>
						</Grid>
					</Grid>
				</Portlet>
			</Grid>
		</Grid>
	)
}

export default Accounts
