import React from "react"
import { Redirect, Route } from "react-router-dom"

import InboxInstances from "@/pages/Inbox/Instances"
import InboxInstanceDetails from "@/pages/Inbox/Instances/Details"
import MailingOldBume from "@/pages/Inbox/MailingOldBume"
import WhatsAppConnectionMetrics from "@/pages/Inbox/Metrics"
import Usage from "@/pages/Inbox/Usage"
import InboxInstanceMetrics from "@/pages/Inbox/InstanceMetrics"
import EditablePlatformText from "@/pages/Inbox/EditablePlatformText"

const InboxRoutes = [
	<Route
		key="inbox"
		exact
		path="/inbox"
		render={() => <Redirect to={"/inbox/instances"} />}
	/>,
	<Route
		key="inbox-instances"
		exact
		path="/inbox/instances"
		component={InboxInstances}
	/>,
	<Route
		key="inbox-instances-details"
		exact
		path="/inbox/instance/:instanceId"
		component={InboxInstanceDetails}
	/>,
	<Route
		key="inbox-mailing-old-bume"
		exact
		path="/mailing-old-bume"
		component={MailingOldBume}
	/>,
	<Route
		key="metrics"
		exact
		path="/inbox/whatsapp-metrics"
		component={WhatsAppConnectionMetrics}
	/>,
	<Route
		key="metrics"
		exact
		path="/instance-metrics"
		component={InboxInstanceMetrics}
	/>,
	<Route
		key="usage"
		exact
		path="/message-usage"
		component={Usage}
	/>,
	<Route
		key="editable-platform-text"
		exact
		path="/editable-platform-text"
		component={EditablePlatformText}
	/>
]

export default InboxRoutes
