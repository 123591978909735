import React, { useState } from "react"
import {
	Typography,
	Grid
} from "@material-ui/core"

import { ChannelType, DailyChannelConnectionMetrics } from "@/pages/Inbox/protocols/ChannelProtocol"

import ChannelConnectionMetrics from "@/pages/Inbox/Instances/components/ChannelConnectionMetrics"
import { getChannelConnectionMetricsTitle } from "@/utils/channelStatus"

type ChannelConnectionMetricsListProps = {
	channelConnectionMetrics?: DailyChannelConnectionMetrics[]
	instanceId: number
	channelType: ChannelType
}

const ChannelConnectionMetricsList: React.FC<ChannelConnectionMetricsListProps> = (props) => {
	const {
		channelConnectionMetrics,
		instanceId,
		channelType
	} = props

	const [currentHoveredConnectionMetric, setCurrentHoveredConnectionMetric] = useState<number | null>()

	return (
		<>
			<Typography
				variant="h6"
				gutterBottom
				component="div"
			>
				{getChannelConnectionMetricsTitle(channelType)}
			</Typography>

			<Grid
				container
				spacing={2}
			>
				{channelConnectionMetrics?.map((channelConnectionMetric, index) => (
					<Grid
						item
						xs={12}
						key={index}
					>
						<Grid
							container
						>
							<ChannelConnectionMetrics
								channelConnectionMetrics={channelConnectionMetric}
								onHintOpened={() => setCurrentHoveredConnectionMetric(channelConnectionMetric.startTimestamp)}
								onHintClosed={() => setCurrentHoveredConnectionMetric(null)}
								disableHint={channelConnectionMetric.startTimestamp !== currentHoveredConnectionMetric}
								showHintBelowPlot={index <= 1}
								instanceId={instanceId}
								channelType={channelType}
							/>
						</Grid>
					</Grid>
				))}
			</Grid>
		</>
	)
}

export default ChannelConnectionMetricsList
