import React, { useState } from "react"

import {
	Button,
	CircularProgress,
	Grid,
	TextField,
	Typography
} from "@material-ui/core"

import { InboxAdminApi } from "@/services/Api"

import useValidation from "@/hooks/useValidation"
import useDidMount from "@/hooks/useDidMount"

import { Divider, Loading } from "@/components"

import CardSkeleton from "@/skeletons/CardSkeleton"
import { formatEmail } from "@/utils/mask"

const MailingOldBume = () => {
	const [mailingList, setMailingList] = useState<string>()
	const [loading, setLoading] = useState<boolean>(true)

	const [updateLoading, setUpdateLoading] = useState<boolean>(false)

	const {
		triggerValidation
	} = useValidation()

	const sanitizeEmailList = (email_list: string) => {
		const sanitizedEmailList = formatEmail(email_list)

		// sanitizedEmailList = sanitizedEmailList.replace(/,/gim, ",\n\n")

		return sanitizedEmailList
	}

	const getMailingList = async () => {
		setLoading(true)

		try {
			const { data } = await InboxAdminApi
				.get("/mailing-old-bume")

			const sanitizedEmailList = sanitizeEmailList(data)

			setMailingList(sanitizedEmailList)
		} catch (error) {
			triggerValidation(error)
		}

		setLoading(false)
	}

	const updateMailingList = async () => {
		setUpdateLoading(true)
		try {
			await InboxAdminApi
				.put("/mailing-old-bume", {
					email_list: mailingList
				})

			await getMailingList()
		} catch (error) {
			triggerValidation(error)
		}
		setUpdateLoading(false)
	}

	const handleChangeMailingList = async (email_list: string) => {
		const sanitizedEmailList = sanitizeEmailList(email_list)

		setMailingList(sanitizedEmailList)
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		await updateMailingList()
	}

	useDidMount(() => {
		getMailingList()
	})

	return (
		<>

			<Typography
				variant="h3"
				color="textPrimary"
			>
				Lista de Email - BUME 1.0
			</Typography>

			<Divider size={2} orientation="horizontal"/>

			<Typography
				variant="body1"
			>
				Insira os emails das contas BUME 1.0.
			</Typography>

			<Divider size={1} orientation="horizontal"/>

			<Typography>
				<b>* Separe por vírgula</b>
			</Typography>

			<Divider size={3} orientation="horizontal"/>

			<Loading
				customLoadingElement={
					<CardSkeleton
						cardHeight={200}
					/>}
				loading={loading}
			>

				<Grid
					container
					component="form"
					onSubmit={handleSubmit}
				>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							multiline
							rows={8}
							rowsMax={15}
							value={mailingList}
							onChange={({ target }) => handleChangeMailingList(target.value as string)}
							style={{
								width: "60%"
							}}
						/>
					</Grid>

					<Divider size={2} orientation="horizontal"/>

					<Grid item xs={12}>
						<Button
							disableElevation
							color="primary"
							variant="contained"
							type="submit"
							disabled={updateLoading}
							startIcon={
								updateLoading &&
                <CircularProgress size={16} color="inherit"/>
							}
						>
              Salvar
						</Button>
					</Grid>
				</Grid>

			</Loading>

		</>
	)
}

export default MailingOldBume
