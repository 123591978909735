const colors = {
	palette: {
		primary: "#7E0DD6",
		confirm: "#5CC395",
		error: "#D84910",
		warning: "#F1C34E",
		tag1: "#5CC395",
		tag2: "#5BD1D7",
		tag3: "#F1C34E",
		link: "#007CBA",
		background: "#F8F9FA",
		button1: "#03DAC5"
	},
	grayScale: {
		1: "#212429",
		2: "#DDE2E5",
		3: "#ACB5BD",
		4: "#495057",
		5: "#969696",
		6: "#ABABAB",
		7: "#BFBFBF",
		8: "#D0D0D0",
		9: "#E1E1E1",
		10: "#F0F0F0",
		11: "#FFFFFF"
	}
}

export default colors
