import React, { useState } from "react"

import {
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	Typography,
	IconButton,
	TextField,
	InputAdornment,
	TableRow
} from "@material-ui/core"
import {
	Divider,
	Loading,
	Portlet,
	ActionDialog,
	Notification
} from "@/components"
import {
	UserRow,
	TableColumns as UserTableColumns
} from "@/pages/Inbox/Instances/Details/UsersTable/UserRow/index"
import CardSkeleton from "@/skeletons/CardSkeleton"

import {
	Search as SearchIcon,
	Close as CloseIcon
} from "@material-ui/icons"
import useInstancesStyles from "@/pages/Inbox/Instances/styles"
import useStyles from "@/pages/Inbox/Instances/Details/styles"
import useCustomStyles from "@/styles/custom"
import { isSmallScreen } from "@/utils/checkDevice"

import { ptBR } from "date-fns/locale"
import DateFnsUtils from "@date-io/date-fns"
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"

import { InboxAdminApi } from "@/services/Api"

import useValidation, { ErrorType } from "@/hooks/useValidation"
import useDebounce from "@/hooks/useDebounce"

import {
	GetUsersInInstanceResponseProps
} from "@/pages/Inbox/protocols/UserInInstanceProtocol"
import {
	UserProps
} from "@/pages/Inbox/protocols/UserProtocol"

type UsersTableProps = {
	usersInInstance: GetUsersInInstanceResponseProps
	usersInInstanceLoading: boolean
	getUsersInInstance: (newWhereData?: Partial<UsersInInstanceWhereData>) => Promise<void>
	getInstanceInfo: () => Promise<void>
	instanceId: number
	instanceOwnerUserId: number
	appInstanceId: number
}

export type UsersInInstanceWhereData = {
	page: number
	rowsPerPage: number
	search?: string
	startCreated?: Date
	endCreated?: Date
}

export type EditUserDialogDataType = {
	open: boolean,
	loading?: boolean,
	user: Partial<UserProps>
}

const UsersTable: React.FC<UsersTableProps> = (props) => {
	const {
		usersInInstance,
		usersInInstanceLoading,
		getUsersInInstance,
		getInstanceInfo,
		instanceId,
		instanceOwnerUserId,
		appInstanceId
	} = props

	const [whereData, setWhereData] = useState<UsersInInstanceWhereData>({
		page: 0,
		rowsPerPage: 50
	})

	const [editUserDialogData, setEditUserDialogData] = useState<EditUserDialogDataType>({
		open: false,
		loading: false,
		user: {
			email: ""
		}
	})

	const { triggerValidation, validation, clearAllValidations, clearValidation } = useValidation()
	const instancesClasses = useInstancesStyles()
	const customClasses = useCustomStyles()
	const classes = useStyles()

	const handleWhereDataChange = (newData: Partial<UsersInInstanceWhereData>) => {
		setWhereData((currentState) => (
			{
				...currentState,
				...newData
			}))
	}

	const handlePageChange = async (event: unknown, page: number) => {
		handleWhereDataChange({
			page
		})

		await getUsersInInstance({ page })
	}

	const handleChangeRowsPerPage = async (rowsPerPage: number) => {
		handleWhereDataChange({
			rowsPerPage
		})
		await getUsersInInstance({ rowsPerPage })
	}

	const handleSearchChange = async (search: string) => {
		handleWhereDataChange({
			search,
			page: 0
		})
	}

	const handleSaveEditedUser = async () => {
		clearAllValidations()
		setEditUserDialogData({
			...editUserDialogData,
			loading: true
		})
		try {
			await InboxAdminApi.put(`/user/${editUserDialogData.user.id}`, {
				email: editUserDialogData.user.email
			})
			Notification.success(
				{
					message: "Informações alteradas com sucesso"
				}
			)
			setEditUserDialogData({
				...editUserDialogData,
				open: false,
				loading: false
			})

			await getUsersInInstance()
		} catch (error) {
			setEditUserDialogData({
				...editUserDialogData,
				loading: false
			})
			triggerValidation(error as ErrorType)
			Notification.error({
				message: "Houve um erro ao tentar alterar os dados"
			})
		}
	}

	const handleEditUserInputChange = (field: string, value: string | number) => {
		clearValidation(field)
		setEditUserDialogData({
			...editUserDialogData,
			user: {
				...editUserDialogData.user,
				[field]: value
			}
		})
	}

	useDebounce(
		async () => await getUsersInInstance({ search: whereData?.search }),
		whereData.search,
		1250
	)

	const handleDateFilterChange = async (
		type: "startCreated" | "endCreated",
		date: Date | null
	) => {
		const newDate = date ? new Date(date).toLocaleDateString("en-US") : null

		handleWhereDataChange({
			[type as string]: newDate
		})

		await getUsersInInstance({ [type as string]: newDate })
	}

	return (
		<>
			<Grid item xs={12}>
				<Typography
					variant="h2"
					className={classes.title}
				>
					Usuários
				</Typography>

				<Divider size={2} orientation="horizontal" />

				<Portlet
					elevation={1}
				>
					<Grid container>
						<Grid item xs={12}>
							<Grid container spacing={3} justify="space-between">
								<Grid item xs={12} md={6}>
									<MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
										<Grid spacing={2} container alignItems="center" justify="flex-start">
											<Grid item xs={6}>
												<Grid
													container
													alignItems="center"
													justify="space-evenly"
												>
													<Grid item xs>
														<DatePicker
															id="startCreated"
															variant={isSmallScreen ? "dialog" : "inline"}
															inputVariant="outlined"
															disableFuture
															format="dd/MM/yyyy"
															animateYearScrolling
															views={["year", "month", "date"]}
															value={whereData?.startCreated || null}
															onChange={(date) =>
																handleDateFilterChange("startCreated", date as Date)
															}
															maxDate={whereData?.endCreated || undefined}
															maxDateMessage="Não pode ser maior que a data final"
															color="primary"
															className={customClasses.datePicker}
															InputProps={{
																className: customClasses.datePickerInput
															}}
															cancelLabel="Cancelar"
															autoOk
															emptyLabel="Digite a data inicial"
														/>
													</Grid>

													{whereData?.startCreated && (
														<Grid item>
															<IconButton
																className={instancesClasses.dateDeleteIcon}
																onClick={() =>
																	handleDateFilterChange("startCreated", null)
																}
															>
																<CloseIcon fontSize="small" />
															</IconButton>
														</Grid>
													)}
												</Grid>
											</Grid>

											<Grid item xs={6}>
												<Grid
													container
													alignItems="center"
													justify="space-evenly"
												>
													<Grid item xs>
														<DatePicker
															id="endCreated"
															variant={isSmallScreen ? "dialog" : "inline"}
															inputVariant="outlined"
															disableFuture
															format="dd/MM/yyyy"
															animateYearScrolling
															views={["year", "month", "date"]}
															value={whereData?.endCreated || null}
															onChange={(date) =>
																handleDateFilterChange("endCreated", date as Date)
															}
															minDate={whereData?.startCreated || undefined}
															minDateMessage="Não pode ser maior que a data inicial"
															color="primary"
															className={customClasses.datePicker}
															InputProps={{
																className: customClasses.datePickerInput
															}}
															cancelLabel="Cancelar"
															autoOk
															emptyLabel="Digite a data final"
														/>
													</Grid>

													{whereData?.endCreated && (
														<Grid item>
															<IconButton
																className={instancesClasses.dateDeleteIcon}
																onClick={() =>
																	handleDateFilterChange("endCreated", null)
																}
															>
																<CloseIcon fontSize="small" />
															</IconButton>
														</Grid>
													)}
												</Grid>
											</Grid>

										</Grid>
									</MuiPickersUtilsProvider>
								</Grid>

								<Grid item xs={12} md={6}>
									<Grid container alignItems="center" justify="flex-end">
										<Grid item xs={12}>
											<TextField
												value={whereData.search}
												onChange={({ target }) =>
													handleSearchChange(target.value)
												}
												placeholder="Pesquise por nome ou email"
												variant="outlined"
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">
															<SearchIcon />
														</InputAdornment>
													),
													endAdornment: whereData.search && (
														<IconButton
															size="small"
															onClick={() => handleWhereDataChange({
																search: ""
															})}
														>
															<CloseIcon
																fontSize="small"
															/>
														</IconButton>
													)
												}}
												fullWidth
												size="small"
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Divider size={3} orientation="horizontal" />

						<Grid item xs={12}>
							<Loading
								customLoadingElement={
									<CardSkeleton
										cardHeight={200}
									/>}
								loading={usersInInstanceLoading}
							>
								<>

									<Divider size={2} orientation="horizontal" />

									{
										usersInInstance.count > 0 ? (
											<Paper className={instancesClasses.paper}>
												<TableContainer>
													<Table stickyHeader aria-label="sticky table">
														<TableHead>
															<TableRow>
																<TableCell />
																{
																	UserTableColumns.map((column, index) => (
																		<TableCell
																			key={index}
																		>
																			{column.label}
																		</TableCell>
																	))
																}

																<TableCell align="center">
																	Ações
																</TableCell>
															</TableRow>
														</TableHead>

														<TableBody>
															{
																usersInInstance.data.map((user, index) => (
																	<UserRow
																		key={index}
																		userData={user}
																		instanceId={instanceId}
																		setEditUserDialogData={setEditUserDialogData}
																		isInstanceOwner={instanceOwnerUserId === user.id}
																		appInstanceId={appInstanceId}
																		getUsersInInstance={getUsersInInstance}
																		getInstanceInfo={getInstanceInfo}
																	/>
																))
															}
														</TableBody>
													</Table>
												</TableContainer>

												<TablePagination
													rowsPerPageOptions={[20, 50, 100, 200]}
													component="div"
													count={usersInInstance?.count}
													rowsPerPage={whereData.rowsPerPage}
													page={whereData.page}
													onPageChange={handlePageChange}
													onRowsPerPageChange={({ target }) => handleChangeRowsPerPage(+target.value)}
													labelRowsPerPage={"Resultados por página:"}
													labelDisplayedRows={e =>
														`Página ${e.page + 1} de ${Math.ceil(e.count / whereData.rowsPerPage)} | Total de resultados: ${usersInInstance?.count}`
													}
												/>
											</Paper>
										) : (
											<>
												<Divider size={3} orientation="horizontal" />

												<Typography align="center" variant="h2">
													Nenhum usuário cadastrado na instância
												</Typography>
											</>
										)
									}
								</>
							</Loading>
						</Grid>

						<ActionDialog
							title="Alterar dados do usuário"
							openDialog={editUserDialogData.open}
							onSave={async () => {
								await handleSaveEditedUser()
							}}
							onClose={() => {
								setEditUserDialogData({ ...editUserDialogData, open: false })
							}}
							loading={editUserDialogData.loading}
							fullWidth
						>
							<Grid xs>
								<Grid item xs>
									<Typography variant="h4">
										Email do usuário
									</Typography>

									<Divider orientation="horizontal" size={1} />

									<TextField
										variant="outlined"
										placeholder="email@letalk.com"
										value={editUserDialogData.user.email}
										onChange={({ target }) => handleEditUserInputChange("email", target.value)}
										helperText={validation.email}
										type={"email"}
										error={!!validation.email}
										fullWidth
									/>
								</Grid>
							</Grid>
						</ActionDialog>
					</Grid>
				</Portlet>

			</Grid>
		</>
	)
}

export default UsersTable
