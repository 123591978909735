import { Divider, Loading, Portlet, Notification } from "@/components"
import useDidMount from "@/hooks/useDidMount"
import { AccountAdminApi } from "@/services/Api"
import CardSkeleton from "@/skeletons/CardSkeleton"
import { formatDateAndTimeBrazilianDate } from "@/utils/time"

import {
	Button,
	Grid,
	IconButton,
	ListItemText,
	Menu,
	MenuItem,
	Paper, Table, TableBody,
	TableCell, TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@material-ui/core"

import {
	MoreVert as OptionsIcon
} from "@material-ui/icons"

import React, { useState } from "react"
import { PlanAddonsInAccountColumns, PlanAddonsInAccountData } from "@/pages/Account/protocols/PlanAddonsInAccountProtocol"
import useStyles from "@/pages/Account/Account/Details/components/Addons/styles"
import useCustomStyles from "@/styles/custom"
import CreatePlanAddonsInAccountFormDialog from "@/pages/Account/Account/Details/components/Addons/CreatePlanAddonInAccountFormDialog"

type PlanAddonsInAccountsProps = {
	accountId: number
	appInstanceId: number
}

const ActiveTableColumns: PlanAddonsInAccountColumns[] = [
	{
		name: "id",
		label: "ID"
	},
	{
		name: "type",
		label: "Tipo de adicional"
	},
	{
		name: "display_name",
		label: "Adicional"
	},
	{
		name: "created_at",
		label: "Ativação",
		formatDate: (value: string) => formatDateAndTimeBrazilianDate(value)?.dateAndTime || " - "
	}
]

const FinishedTableColumns: PlanAddonsInAccountColumns[] = [
	{
		name: "id",
		label: "ID"
	},
	{
		name: "type",
		label: "Tipo de adicional"
	},
	{
		name: "display_name",
		label: "Adicional"
	},
	{
		name: "created_at",
		label: "Ativação",
		formatDate: (value: string) => formatDateAndTimeBrazilianDate(value)?.dateAndTime || " - "
	},
	{
		name: "updated_at",
		label: "Encerrado em",
		formatDate: (value: string) => formatDateAndTimeBrazilianDate(value)?.dateAndTime || " - "
	}
]

const PlanAddonsInAccounts = (props: PlanAddonsInAccountsProps) => {
	const { accountId, appInstanceId } = props
	const [planAddonsInAccount, setPlanAddonsInAccounts] = useState<PlanAddonsInAccountData[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [showCreatePlanAddonsInAccountDialog, setShowCreatePlanAddonsInAccountDialog] = useState<boolean>(false)
	const [menuOpenedItemId, setMenuOpenedItemId] = useState<number>(0)

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const activePlanAddonsInAccount: PlanAddonsInAccountData[] = []
	const finishedPlanAddonsInAccount: PlanAddonsInAccountData[] = []

	planAddonsInAccount.forEach(planAddonInAccount => {
		if (planAddonInAccount.status === "active") {
			activePlanAddonsInAccount.push(planAddonInAccount)
		} else if (planAddonInAccount.status === "finished") {
			finishedPlanAddonsInAccount.push(planAddonInAccount)
		}
	})

	const menuOpened = Boolean(anchorEl)

	const handleClickMenu = (event: React.MouseEvent<HTMLElement>, planAddonInAccountId: number) => {
		setAnchorEl(event.currentTarget)
		setMenuOpenedItemId(planAddonInAccountId)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	const getPlanAddonsInAccounts = async () => {
		setLoading(true)
		try {
			const { data } = await AccountAdminApi.get(`/plan-addons-in-account/${accountId}/${appInstanceId}`)
			setPlanAddonsInAccounts(data)
		} catch (error) {
			Notification.error({ message: "Houve um erro." })
		}

		setLoading(false)
	}

	const handleFinishPlanAddonsInAccount = async (planAddonInAccountId: number) => {
		try {
			await AccountAdminApi.put(`/plan-addons-in-account/${planAddonInAccountId}/finish`)

			await getPlanAddonsInAccounts()
			Notification.success({ message: "Adicional encerrado!" })
		} catch (error) {
			Notification.error({ message: "Houve um erro." })
		}
	}

	useDidMount(() => {
		getPlanAddonsInAccounts()
	})

	return (
		<>
			<Grid>
				<Grid item xs={12}>
					<Grid item>
						<Button
							variant="contained"
							className={customClasses.buttonAction}
							onClick={() => {
								setShowCreatePlanAddonsInAccountDialog(true)
							}}
						>
							NOVO ADICIONAL
						</Button>
					</Grid>

					<Divider size={1} orientation="horizontal" />

					<Typography
						color="textPrimary"
						className={classes.title}
					>
						Adicionais Ativos
					</Typography>

					<Divider size={1} orientation="horizontal" />

					<Portlet
						elevation={1}
					>
						<Divider size={3} orientation="horizontal" />

						<Grid container>
							<Grid item xs={12}>
								<Loading
									customLoadingElement={
										<CardSkeleton
											cardHeight={200}
										/>}
									loading={loading}>
									{
										activePlanAddonsInAccount.length > 0
											? <Paper className={classes.paper}>
												<TableContainer>
													<Table stickyHeader aria-label="sticky table">
														<TableHead>
															<TableRow>
																{ActiveTableColumns
																	.map((column, index) => (
																		<TableCell
																			key={index}
																		>
																			{
																				column.label
																			}
																		</TableCell>
																	))}
																<TableCell align="center">
																	{/* Actions column */}
																</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{
																activePlanAddonsInAccount.map((planAddonInAccount, index) => {
																	return (
																		<TableRow
																			hover
																			tabIndex={-1}
																			key={index}
																			className={classes.tableRow}
																		>
																			{ActiveTableColumns
																				.map((column, index) => {
																					let value = planAddonInAccount[column.name] as string

																					if (column?.formatDate) {
																						value = column.formatDate(value)
																					}

																					return (
																						<TableCell
																							key={index}
																							style={column?.style}
																							align={column?.align}
																						>
																							{value}
																						</TableCell>
																					)
																				}
																				)
																			}
																			<TableCell
																				align="center"
																				style={{ display: "flex", justifyContent: "center" }}
																			>
																				<IconButton
																					onClick={event => handleClickMenu(event, planAddonInAccount.id)}
																					className={classes.iconButton}
																				>
																					<OptionsIcon/>
																				</IconButton>
																			</TableCell>
																		</TableRow>
																	)
																})}
														</TableBody>
													</Table>
												</TableContainer>

											</Paper>
											: (
												<>
													<Divider size={3} orientation="horizontal" />
													<Typography align="center" variant="h2">
														Nenhum <b>adicional ativo</b>
													</Typography>
												</>)
									}
								</Loading>
								<CreatePlanAddonsInAccountFormDialog
									accountId={accountId}
									appInstanceId={appInstanceId}
									showCreatePlanAddonsInAccountDialog={showCreatePlanAddonsInAccountDialog}
									setShowCreatePlanAddonsInAccountDialog={setShowCreatePlanAddonsInAccountDialog}
									getPlanAddonsInAccounts={getPlanAddonsInAccounts}
								/>
							</Grid>
						</Grid>
					</Portlet>

					<Divider size={3} orientation="horizontal" />

					<Typography
						color="textPrimary"
						className={classes.title}
					>
						Adicionais Encerrados
					</Typography>

					<Divider size={1} orientation="horizontal" />

					<Portlet
						elevation={1}
					>
						<Divider size={3} orientation="horizontal" />

						<Grid container>
							<Grid item xs={12}>
								<Loading
									customLoadingElement={
										<CardSkeleton
											cardHeight={200}
										/>}
									loading={loading}>
									{
										finishedPlanAddonsInAccount.length > 0
											? <Paper className={classes.paper}>
												<TableContainer>
													<Table stickyHeader aria-label="sticky table">
														<TableHead>
															<TableRow>
																{FinishedTableColumns
																	.map((column, index) => (
																		<TableCell
																			key={index}
																		>
																			{
																				column.label
																			}
																		</TableCell>
																	))
																}
															</TableRow>
														</TableHead>
														<TableBody>
															{
																finishedPlanAddonsInAccount.map((planAddonInAccount, index) => {
																	return (
																		<TableRow
																			hover
																			tabIndex={-1}
																			key={index}
																			className={classes.tableRow}
																		>
																			{FinishedTableColumns
																				.map((column, index) => {
																					let value = planAddonInAccount[column.name] as string

																					if (column?.formatDate) {
																						value = column.formatDate(value)
																					}

																					return (
																						<TableCell
																							key={index}
																							style={column?.style}
																							align={column?.align}
																						>
																							{value}
																						</TableCell>
																					)
																				}
																				)
																			}
																		</TableRow>
																	)
																})}
														</TableBody>
													</Table>
												</TableContainer>

											</Paper>
											: (
												<>
													<Divider size={3} orientation="horizontal" />
													<Typography align="center" variant="h2">
														Nenhum <b>adicional encerrado</b>
													</Typography>
												</>)
									}
								</Loading>
							</Grid>
						</Grid>
					</Portlet>

					<Divider size={1} orientation="horizontal" />
				</Grid>
			</Grid>
			<Menu
				id="header-menu"
				anchorEl={anchorEl}
				keepMounted
				open={menuOpened}
				onClose={handleCloseMenu}
				autoFocus={false}
				PaperProps={{
					style: {
						marginTop: 40
					}
				}}
			>
				<MenuItem
					onClick={() => handleFinishPlanAddonsInAccount(menuOpenedItemId)}
				>
					<ListItemText primary="Encerrar adicional" />
				</MenuItem>
			</Menu>
		</>
	)
}

export default PlanAddonsInAccounts
