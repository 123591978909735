import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	roleChip: {
		marginLeft: "3%",
		background: colors.grayScale[10],
		height: theme.spacing(2.5),
		border: "none",
		color: "#ffff"
	}
}))

export default useStyles
