import React, { useEffect, useState } from "react"
import { Switch, useLocation } from "react-router-dom"
import { Container, Grid } from "@material-ui/core"

import { Navbar, Menu } from "@/routes/private/Components"

import { Divider } from "@/components"

import InboxRoutes from "@/pages/Inbox/routes"

import useStyles from "@/routes/private/styles"
import AccountRoutes from "@/pages/Account/routes"
import ToolsRoutes from "@/pages/Tools/routes"

const Routes = [
	...InboxRoutes,
	...AccountRoutes,
	...ToolsRoutes
]

const routesToAvoidMaxContentWidth = [
	"/message-usage",
	"/spreadsheet-customers"
]

const PrivateRoutes = () => {
	const [openDrawer, setOpenDrawer] = useState(false)

	const classes = useStyles({ openDrawer })
	const location = useLocation()

	const handleDrawerOpen = () => {
		setOpenDrawer(true)
	}

	const handleDrawerClose = () => {
		setOpenDrawer(false)
	}

	useEffect(() => {
		handleDrawerClose()
	}, [location.pathname])

	return (
		<Grid
			container
			direction="column"
			wrap="nowrap"
		>
			<Navbar
				onOpen={handleDrawerOpen}
			/>

			<Grid
				container
				className={classes.adminContainer}
			>
				<Menu
					openDrawer={openDrawer}
					onClose={handleDrawerClose}
				/>

				<Grid
					container
					justify="center"
					className={classes.routesContainer}
				>
					<Container maxWidth={routesToAvoidMaxContentWidth.indexOf(location.pathname) !== -1 ? "xl" : "lg"} className={classes.routesContent}>

						<Divider size={4} orientation="horizontal" />

						<Switch>
							{Routes}
						</Switch>

						<Divider size={4} orientation="horizontal" />

					</Container>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default PrivateRoutes
