import React, { useState } from "react"
import { Collapse, Grid, IconButton, Tab, TableCell, TableRow, Tabs } from "@material-ui/core"
import {
	KeyboardArrowDown as ArrowDownIcon,
	KeyboardArrowUp as ArrowUpIcon,
	Launch as LaunchIcon
} from "@material-ui/icons"

import useAccountClasses from "@/pages/Account/Account/styles"
import { Link } from "react-router-dom"
import useStyles from "@/pages/Account/Account/Details/components/AppInstanceRow/styles"
import SubscriptionTable from "@/pages/Account/Account/Details/components/SubscriptionTable"
import Addons from "@/pages/Account/Account/Details/components/Addons"

type PossibleTabs = "subscriptions" | "addons"

type AppInstanceRowData = {
	appInstanceId: number,
	instanceId: number,
	accountId: number
}

const AppInstanceRow = (props: {
	appInstanceData: AppInstanceRowData
}) => {
	const {
		appInstanceData
	} = props

	const [actualTab, setActualTab] = useState<PossibleTabs>("subscriptions")
	const accountClasses = useAccountClasses()
	const classes = useStyles()

	const [collapseOpen, setCollapseOpen] = useState<boolean>(false)

	return (
		<>
			<TableRow
				className={accountClasses.tableRow}
			>
				<TableCell>
					{appInstanceData.instanceId}
				</TableCell>

				<TableCell
					align="center"
					className={classes.tableCell}
				>
					<IconButton aria-label="expand row" size="small" onClick={() => setCollapseOpen(!collapseOpen)}>
						{collapseOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
					</IconButton>

					<Link
						to={`/inbox/instance/${appInstanceData.instanceId}`}
					>
						<IconButton
							className={classes.launchIcon}
						>
							<LaunchIcon fontSize="small" />
						</IconButton>
					</Link>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
					<Collapse in={collapseOpen} timeout="auto" unmountOnExit>
						<Grid item xs={12} className={classes.childrenTable}>
							<Tabs
								indicatorColor="primary"
								value={actualTab}
								onChange={(event, newTab) => {
									setActualTab(newTab as PossibleTabs)
								}}
							>
								<Tab
									value={"subscriptions"}
									style={{ fontSize: "14px" }}
									label={"Assinaturas"}
								/>
								<Tab
									value={"addons"}
									style={{ fontSize: "14px" }}
									label={"Adicionais"}
								/>
							</Tabs>
							<Grid
								className={classes.tabContent}
							>
								{
									actualTab === "subscriptions" &&
									<SubscriptionTable
										accountId={appInstanceData.accountId}
										appInstanceId={appInstanceData.appInstanceId}
									/>
								}
								{
									actualTab === "addons" &&
									<Addons
										accountId={appInstanceData.accountId}
										appInstanceId={appInstanceData.appInstanceId}
									/>
								}
							</Grid>
						</Grid>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	)
}

export default AppInstanceRow
