import { ActionDialog, CustomTextField, Notification } from "@/components"
import { Grid } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { AddonData } from "@/pages/Account/protocols/AddonProtocol"
import { AccountAdminApi } from "@/services/Api"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import { formatIntNumber } from "@/utils/mask"
import useStyles from "@/pages/Account/PlansAndAddons/Addons/CreateAddonFormDialog/styles"

interface CreateAddonFormDialogProps{
	addonTypeId?: number
	addonTypeDisplayName?: string
	showCreateAddonDialog?: boolean
	setShowCreateAddonDialog?: (value: boolean) => void
	getAddons?: () => Promise<void>
}

const CreateAddonFormDialog = (props: CreateAddonFormDialogProps) => {
	const { addonTypeId, addonTypeDisplayName, showCreateAddonDialog, setShowCreateAddonDialog, getAddons } = props
	const [formData, setFormData] = useState<AddonData>({} as AddonData)
	const [actionDialogLoading, setActionDialogLoading] = useState<boolean>(false)

	const classes = useStyles()
	const { validation, triggerValidation, clearValidation } = useValidation()

	const handleCreateAddon = async (data: AddonData) => {
		setActionDialogLoading(true)
		try {
			await AccountAdminApi.post("/plan-addons", {
				...data,
				plan_addons_type_id: addonTypeId
			})

			setFormData({} as AddonData)

			if (setShowCreateAddonDialog) {
				setShowCreateAddonDialog(false)
			}

			await getAddons?.()
			Notification.success({ message: "Adicional adicionado com sucesso." })
		} catch (error) {
			Notification.error({ message: "Houve um erro." })
			triggerValidation(error as ErrorType)
		}
		setActionDialogLoading(false)
	}

	const handleInputAddonChange = (name: string, value: string) => {
		clearValidation(name)

		const inputHandlerRules: Record<string, () => (string | number)> = {
			price_in_cents: function () {
				return formatIntNumber(value)
			},
			default: function () {
				return value
			}
		}
		const rule = inputHandlerRules[name] ? name : "default"
		const ruleHandleFunction = inputHandlerRules[rule]

		setFormData({ ...formData, [name]: ruleHandleFunction() })
	}

	useEffect(() => {
		setFormData({} as AddonData)
	}, [showCreateAddonDialog])

	return <ActionDialog
		title="Criar novo adicional"
		onSave={() => handleCreateAddon(formData)}
		saveText="Pronto"
		onClose={() => setShowCreateAddonDialog && setShowCreateAddonDialog(false)}
		loading={actionDialogLoading}
		openDialog={showCreateAddonDialog}
		fullWidth
	>
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<CustomTextField
					value={addonTypeDisplayName}
					placeholder="Tipo de adicional"
					className={classes.customDisabled}
					variant="outlined"
					fullWidth
					disabled
				/>
			</Grid>

			<Grid item xs={12}>
				<CustomTextField
					value={formData.display_name}
					onChange={
						({ target }) => handleInputAddonChange("display_name", target.value)
					}
					variant="outlined"
					placeholder="Nome"
					fullWidth
					helperText={validation.display_name}
					error={!!validation.display_name}
				/>
			</Grid>

			<Grid item xs={12}>
				<CustomTextField
					value={formData.price_in_cents}
					onChange={
						({ target }) => handleInputAddonChange("price_in_cents", target.value)
					}
					variant="outlined"
					placeholder="Preço(em centavos)"
					fullWidth
					helperText={validation.price_in_cents}
					error={!!validation.price_in_cents}
				/>
			</Grid>
		</Grid>
	</ActionDialog>
}

export default CreateAddonFormDialog
