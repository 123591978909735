import React, { useState, useMemo } from "react"
import {
	Drawer as MaterialDrawer,
	DrawerProps as MaterialDrawerProps
} from "@material-ui/core"

import { useAccessibleDrawerStateStore, OnMobileCloseFunction } from "@/store/AccessibleDrawerState"

import { isSmallScreen } from "@/utils/checkDevice"

type AccessibleDrawerProps = MaterialDrawerProps & {
	onMobileBackButtonPress: OnMobileCloseFunction
	withoutTriggerHistoryBackEvent?: boolean
}

/**
 * That drawer is capable of being closed by back button press
 * when opened by mobile devices.
 */
const AccessibleDrawer: React.FC<AccessibleDrawerProps> = (props) => {
	const { withoutTriggerHistoryBackEvent, onMobileBackButtonPress, ...rest } = props

	const drawerStateStore = useAccessibleDrawerStateStore()

	const [drawerId] = useState(String(Date.now()))

	const handleMobileClose = () => {
		drawerStateStore.onMobileClose(drawerId, !withoutTriggerHistoryBackEvent)
	}

	const handleMobileOpen = () => {
		drawerStateStore.onMobileOpen(drawerId, onMobileBackButtonPress)
	}

	useMemo(() => {
		if (isSmallScreen) {
			if (props.open) {
				handleMobileOpen()
			} else {
				handleMobileClose()
			}
		}
	// eslint-disable-next-line
	}, [props.open])

	return (
		<MaterialDrawer
			{...rest}
		/>
	)
}

export default AccessibleDrawer
