/* eslint-disable react/display-name */
import React, { ReactNode, useState } from "react"
import { Chip, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import {
	KeyboardArrowDown as ArrowDownIcon,
	KeyboardArrowUp as ArrowUpIcon,
	Error as ErrorIcon,
	CheckCircle as CheckIcon
} from "@material-ui/icons"

import { formatDateInBrazilianDate, formatDateAndTimeBrazilianDate, formatTimeInBrazilianDate } from "@/utils/time"

import { SubscriptionData, SubscriptionsColumn } from "@/pages/Account/protocols/SubscriptionProtocol"
import useAccountClasses from "@/pages/Account/Account/styles"
import colors from "@/styles/colors"

const TrialChip = (value: boolean): ReactNode => (
	<>
		{value ? (
			<Chip
				label={"Trial"}
				style={{
					backgroundColor: colors.palette.tag3,
					color: colors.grayScale[11],
					fontWeight: "bold"
				}}
			/>
		) : (
			<></>
		)}
	</>
)

const FinishedChip = (value: boolean): ReactNode => (
	<>
		{value ? (
			<Chip
				label={"X"}
				style={{
					backgroundColor: colors.palette.error,
					color: colors.grayScale[11],
					fontWeight: "bold"
				}}
			/>
		) : (
			<></>
		)}
	</>
)

const ActivationChip = (date: Date): ReactNode => {
	const formatDate = `${formatDateInBrazilianDate(date)} às ${formatTimeInBrazilianDate(date)}`
	return (
		<>
			<Chip
				label={formatDate}
				style={{
					backgroundColor: colors.palette.link,
					color: colors.grayScale[11],
					fontWeight: "bold"
				}}
				icon={<CheckIcon style={{ color: "inherit" }} />}
			/>
		</>
	)
}

const DateChip = (date: Date, isFinish?: boolean): ReactNode => {
	const formatDate = `${formatDateInBrazilianDate(date)} às ${formatTimeInBrazilianDate(date)}`
	const currentDate = new Date()

	const dateIsInPastOrFinish = Boolean(isFinish) || currentDate > new Date(date)

	const backgroundColor = dateIsInPastOrFinish ? colors.palette.error : colors.palette.confirm
	const icon = dateIsInPastOrFinish
		? <ErrorIcon style={{ color: "inherit" }} />
		: <CheckIcon style={{ color: "inherit" }} />

	return (
		<>
			<Chip
				label={formatDate}
				style={{
					backgroundColor: backgroundColor,
					color: colors.grayScale[11],
					fontWeight: "bold"
				}}
				icon={icon}
			/>
		</>
	)
}

export const SubscriptionsTableColumns: SubscriptionsColumn[] = [
	{
		name: "id",
		label: "ID"
	},
	{
		name: "plan_display_name",
		label: "Plano"
	},
	{
		name: "app_display_name",
		label: "App"
	},
	{
		name: "is_trial",
		label: "Trial",
		formatBoolean: (value: boolean): ReactNode => {
			return TrialChip(value)
		}
	},
	{
		name: "is_finished",
		label: "Finalizado",
		formatBoolean: (value: boolean): ReactNode => {
			return FinishedChip(value)
		}
	},
	{
		name: "activation_date",
		label: "Ativação",
		formatViewDate: (value: Date): ReactNode => ActivationChip(value)
	},
	{
		name: "expiration_date",
		label: "Expiração",
		formatViewDate: (value: Date, isFinish?: boolean): ReactNode => DateChip(value, isFinish)
	},
	{
		name: "tolerance_date",
		label: "Tolerância",
		formatViewDate: (value: Date, isFinish?: boolean): ReactNode => DateChip(value, isFinish)
	}
]

export const SubscriptionsDetailsTableColumns: SubscriptionsColumn[] = [
	{
		name: "organization_id",
		label: "ID -Organiza."
	},
	{
		name: "workspace_id",
		label: "ID -Workspace."
	},
	{
		name: "billing_account_id",
		label: "ID - Account Pagante"
	},
	{
		name: "plan_id",
		label: "ID - Plano"
	},
	{
		name: "created_at",
		label: "Criado em",
		formatDate: (value: string): string => formatDateAndTimeBrazilianDate(value)?.dateAndTime || " - "
	},
	{
		name: "updated_at",
		label: "Atualizado em",
		formatDate: (value: string): string => formatDateAndTimeBrazilianDate(value)?.dateAndTime || " - "
	}
]

const SubscriptionRow = (props: {
	subscriptionData: SubscriptionData
}) => {
	const {
		subscriptionData
	} = props

	const subscription = {
		...subscriptionData,
		plan_display_name: subscriptionData.owned_plan?.display_name,
		app_display_name: subscriptionData.owned_plan?.related_app?.name
	}

	const accountClasses = useAccountClasses()

	const [collapseOpen, setCollapseOpen] = useState<boolean>(false)

	return (
		<>
			<TableRow
				className={accountClasses.tableRow}
			>
				<TableCell>
					<IconButton aria-label="expand row" size="small" onClick={() => setCollapseOpen(!collapseOpen)}>
						{collapseOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
					</IconButton>
				</TableCell>

				{SubscriptionsTableColumns
					.map((column, index) => {
						let value = subscription[column.name] as ReactNode

						if (column?.formatDate) {
							value = column.formatDate(value as string)
						}

						if (column?.formatViewDate) {
							value = column.formatViewDate(value as unknown as Date, Boolean(subscription?.is_finished))
						}

						if (column?.formatBoolean) {
							value = column.formatBoolean(value as unknown as boolean)
						}

						return (
							<TableCell style={column?.style} key={index} align={column?.align}>
								{value}
							</TableCell>
						)
					})}
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
					<Collapse in={collapseOpen} timeout="auto" unmountOnExit>
						<TableContainer style={{ padding: "16px", margin: "10px" }}>
							<Table>
								<TableHead>
									<TableRow>
										{SubscriptionsDetailsTableColumns
											.map((column, index) => (
												<TableCell
													key={index}
												>
													{column.label}
												</TableCell>
											))}

										{/* <TableCell>
											Ações
										</TableCell> */}
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow
										className={accountClasses.tableRow}
									>
										{SubscriptionsDetailsTableColumns
											.map((column, index) => {
												let value = subscription[column.name]

												if (column?.formatDate) {
													value = column.formatDate(value as string)
												}

												return (
													<TableCell style={column?.style} key={index} align={column?.align}>
														{value}
													</TableCell>
												)
											})}
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	)
}

export default SubscriptionRow
