import React from "react"
import { Link, Typography } from "@material-ui/core"
import clsx from "clsx"
import useStyles from "@/components/PhoneNumberRedirect/styles"
import { formatPhoneNumber } from "@/utils/mask"

export type PhoneNumberRedirectProps = {
	phoneNumber: string
	chatUrl: string
	customizedClass?: string
	customLinkTarget?: string
}

const PhoneNumberRedirect: React.FC<PhoneNumberRedirectProps> = (props) => {
	const { phoneNumber, chatUrl, customizedClass, customLinkTarget } = props
	const classes = useStyles()
	return (
		<>
			{
				<Link
					onClick={() => {
						window.open(chatUrl, (customLinkTarget || "_blank"))
					}}
				>
					<Typography
						className={`${clsx({
							[classes.contact]: true
						})} ${customizedClass}`}
					>
						{formatPhoneNumber(phoneNumber)}
					</Typography>
				</Link>
			}
		</>
	)
}

export default PhoneNumberRedirect
