import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles({
	title: {
		color: colors.grayScale[1],
		textTransform: "uppercase",
		fontWeight: "bold"
	}
})

export default useStyles
