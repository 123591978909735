import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const drawerWidth = 240

const useStyles = makeStyles({
	drawer: {
		height: "100%",
		background: colors.palette.background,
		width: drawerWidth
	},
	paper: {
		position: "relative",
		borderRight: "1px solid #E0E6ED"
	},
	goToAttendanceButton: {
		fontSize: "14px"
	},
	title: {
		fontWeight: "normal"
	},
	logo: {
		width: 115,
		display: "flex"
	},
	closeIcon: {
		color: colors.grayScale[1]
	}
})

export default useStyles
