import React, { useState } from "react"

import {
	Grid,
	Tab,
	Tabs
} from "@material-ui/core"

import useStyles from "@/pages/Account/PlansAndAddons/styles"

import Plans from "@/pages/Account/PlansAndAddons/Plans"
import Addons from "@/pages/Account/PlansAndAddons/Addons"

type PossibleTabs = "plans" | "addons"

const PlansAndAddons = () => {
	const classes = useStyles()
	const [actualTab, setActualTab] = useState<PossibleTabs>("plans")

	return (
		<>
			<Tabs
				indicatorColor="primary"
				value={actualTab}
				onChange={(event, newTab) => {
					setActualTab(newTab as PossibleTabs)
				}}
			>
				<Tab
					value={"plans"}
					label={"Planos"}
				/>
				<Tab
					value={"addons"}
					label={"Adicionais"}
				/>
			</Tabs>
			<Grid
				className={classes.tabContent}
			>
				{actualTab === "addons" && <Addons/>}
				{actualTab === "plans" && <Plans/>}
			</Grid>
		</>
	)
}

export default PlansAndAddons
