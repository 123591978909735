import React from "react"

import {
	Grid,
	IconButton
} from "@material-ui/core"
import {
	DeviceHub as AccountIcon,
	Chat as InboxIcon,
	Close as CloseIcon,
	ShopTwo as PlansIcon,
	AccountBalanceWallet as HotmartIcon,
	BuildRounded as ToolsIcon,
	BarChart as InstanceMetricsIcon,
	DataUsage as MessageUsageIcon,
	TextFieldsRounded as EditablePlatformTextIcon,
	Group as SpreadsheetCustomersIcon
} from "@material-ui/icons"

import { ListItemMenu, Divider, AccessibleDrawer } from "@/components"

import { isSmallScreen } from "@/utils/checkDevice"

import logoImg from "@/assets/images/logos/letalk-logo.svg"

import useStyles from "@/routes/private/Components/Menu/styles"

type MenuProps = {
	openDrawer: boolean
	onClose: () => void
}

const Menu = (props: MenuProps) => {
	const {
		openDrawer,
		onClose
	} = props

	const classes = useStyles()

	const handleDrawerClose = () => {
		onClose()
	}

	return (
		<AccessibleDrawer
			variant={isSmallScreen ? "temporary" : "permanent"}
			open={!isSmallScreen || openDrawer}
			anchor="left"
			onClose={handleDrawerClose}
			onMobileBackButtonPress={handleDrawerClose}
			withoutTriggerHistoryBackEvent
			className={!isSmallScreen ? classes.drawer : ""}
			classes={{
				paper: !isSmallScreen ? classes.paper : ""
			}}
		>
			<Grid
				container
				justify="center"
				alignItems="center"
			>
				{
					isSmallScreen &&
					<Grid item xs={11}>

						<Divider size={2} orientation="horizontal" />
						<Grid container justify="space-between" alignItems="center">
							<Grid item xs>
								<img
									alt="Letalk"
									src={logoImg}
									className={classes.logo}
								/>
							</Grid>

							<Grid item>
								<IconButton
									color="inherit"
									onClick={handleDrawerClose}
								>
									<CloseIcon className={classes.closeIcon} />
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				}

				<Divider size={2} orientation="horizontal" />

				<ListItemMenu
					icon={<AccountIcon />}
					text="Account"
					to={"/account"}
				/>

				<ListItemMenu
					icon={<InboxIcon />}
					text="Instâncias"
					to={"/inbox"}
				/>

				{/* <ListItemMenu
					icon={<ListIcon />}
					text="Bume 1.0"
					to={"/mailing-old-bume"}
				/> */}

				<ListItemMenu
					icon={<PlansIcon />}
					text="Planos"
					to={"/plans"}
				/>

				<ListItemMenu
					icon={<EditablePlatformTextIcon />}
					text="Textos editáveis"
					to={"/editable-platform-text"}
				/>

				<ListItemMenu
					icon={<HotmartIcon />}
					text="Hotmart"
					to={"/hotmart"}
				/>

				<ListItemMenu
					icon={<SpreadsheetCustomersIcon />}
					text="Planilha de Assinaturas"
					to={"/spreadsheet-customers"}
				/>

				<ListItemMenu
					icon={<ToolsIcon />}
					text="Ferramentas"
					to={"/tools"}
				/>

				<ListItemMenu
					icon={<InstanceMetricsIcon />}
					text="Métricas"
					to={"/instance-metrics"}
				/>

				<ListItemMenu
					icon={<MessageUsageIcon />}
					text="Utilização"
					to={"/message-usage"}
				/>
			</Grid>

		</AccessibleDrawer>
	)
}

export default Menu
