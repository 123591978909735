import React, { useState } from "react"
import {
	Grid,
	Typography,
	Divider as MuiDivider,
	Box
} from "@material-ui/core"
import {
	HorizontalBarSeries,
	FlexibleWidthXYPlot,
	Hint
} from "react-vis"

import { Divider, PhoneModelSearch } from "@/components"

import {
	millisecondsToHumanReadableTime,
	formatDateAndTimeBrazilianDate,
	formatDateInBrazilianDate,
	isToday
} from "@/utils/time"
import { formatPhoneNumber } from "@/utils/mask"

import {
	ChannelConnectionMetric,
	InboxChannelLibraryClientType
} from "@/pages/Inbox/protocols/ChannelProtocol"
import {
	ChannelConnectionMetricsProps,
	ChartData,
	FormattedInfo,
	HoverData
} from "@/pages/Inbox/protocols/ChannelConnectionProtocol"

import { getInboxChannelStatusData } from "@/utils/channelStatus"

import useStyles, { BAR_HEIGHT } from "@/pages/Inbox/Instances/components/ChannelConnectionMetrics/styles"

const ChannelConnectionMetrics: React.FC<ChannelConnectionMetricsProps> = (props) => {
	const {
		channelConnectionMetrics,
		onHintClosed,
		onHintOpened,
		showHintBelowPlot,
		disableHint,
		instanceId,
		channelType
	} = props

	const classes = useStyles()

	const [hoverData, setHoverData] = useState<HoverData | null>(null)

	const transformLibTypesToNames: Record<InboxChannelLibraryClientType, string> = {
		baileys: "Baileys"
	}

	if (!channelConnectionMetrics) {
		return (
			<Typography
				variant="body1"
			>
				Métricas não encontradas
			</Typography>
		)
	}

	const getFormattedInfo = (connectionMetric: ChannelConnectionMetric): FormattedInfo => {
		const {
			customStyle,
			label
		} = getInboxChannelStatusData({
			status: connectionMetric?.status,
			inboxChannelType: channelType
		})

		return {
			backgroundColor: customStyle?.backgroundColor,
			status: label
		}
	}

	const handleCloseHint = () => {
		setHoverData(null)

		if (onHintClosed) {
			onHintClosed()
		}
	}

	const handleOpenHint = (pointData: ChartData) => {
		const connectionMetric = channelConnectionMetrics?.connectionMetrics?.find(({ durationInMilliseconds }) => (
			durationInMilliseconds === pointData?.durationInMilliseconds
		))

		if (connectionMetric) {
			const hoverData: HoverData = {
				pointData,
				connectionMetric,
				formattedInfo: getFormattedInfo(connectionMetric)
			}

			setHoverData(hoverData)

			if (onHintOpened) {
				onHintOpened()
			}
		}
	}

	const data: ChartData[] = channelConnectionMetrics?.connectionMetrics?.map(connectionMetric => {
		const {
			customStyle
		} = getInboxChannelStatusData({
			status: connectionMetric?.status,
			inboxChannelType: channelType
		})

		return {
			y: 0,
			x: connectionMetric.durationInMilliseconds,
			color: customStyle?.backgroundColor,
			durationInMilliseconds: connectionMetric.durationInMilliseconds
		}
	})

	const mainDate = new Date(channelConnectionMetrics.startTimestamp)

	const mountDisconnectionsExternalLink = () => {
		const {
			startTimestamp,
			endTimestamp
		} = channelConnectionMetrics

		const mountedLink = `https://grafana.apps.appdot.dev/d/uuEIJ4-4k/relatorio-de-status-do-canal-de-hora-em-hora?orgId=1&from=${startTimestamp}&to=${endTimestamp}&var-instance_id=${instanceId}`

		return mountedLink
	}

	return (
		<Grid
			container
			spacing={1}
		>
			<Grid
				item
				xs={12}
			>
				<Grid
					container
					alignItems="center"
					justify="space-between"
				>
					<Typography
						variant="overline"
					>
						{formatDateInBrazilianDate(mainDate)}

						{isToday(mainDate) && " - Hoje"}
					</Typography>

					<Grid item>
						<a
							href={mountDisconnectionsExternalLink()}
							className={classes.customLink}
							target="_blank"
							rel="noopener noreferrer"
						>
							{channelConnectionMetrics.totalDisconnections} Desconexões
						</a>
					</Grid>
				</Grid>
			</Grid>

			<Grid
				item
				xs={12}
			>
				<Grid
					container
					className={classes.metricsPlotContainer}
				>
					<FlexibleWidthXYPlot
						height={BAR_HEIGHT}
						stackBy="x"
						className={classes.metricsPlot}
						onMouseLeave={() => handleCloseHint()}
						animation
					>
						<HorizontalBarSeries
							colorType="literal"
							data={data}
							barWidth={channelConnectionMetrics.endTimestamp - channelConnectionMetrics.startTimestamp}
							className={classes.metricsBar}
							onValueMouseOver={(pointData) => handleOpenHint(pointData as ChartData)}
						/>

						{(channelConnectionMetrics.endTimestamp - channelConnectionMetrics.currentTimestamp) > 0 && (
							<HorizontalBarSeries
								key="missing"
								data={[{ y: 0, x: channelConnectionMetrics.endTimestamp - channelConnectionMetrics.currentTimestamp }]}
								color="#EFEFEF"
								barWidth={channelConnectionMetrics.endTimestamp - channelConnectionMetrics.startTimestamp}
								className={classes.metricsBar}
							/>
						)}

						{(hoverData?.pointData && hoverData?.connectionMetric && !disableHint) && (
							<Hint
								value={hoverData.pointData}
								align={{ horizontal: "auto", vertical: showHintBelowPlot ? "bottom" : "top" }}
								style={{ marginTop: 8 }}
							>
								<Grid
									container
									direction="column"
									alignItems="stretch"
									justify="center"
									className={classes.metricsPlotHint}
								>
									<Grid item>
										<Box display="flex" alignItems="center">
											<Box
												width={14}
												height={14}
												bgcolor={hoverData.formattedInfo?.backgroundColor}
												mr={1}
												borderRadius="50%"
											/>

											<Typography variant="overline" color="textPrimary">
												<b>{hoverData.formattedInfo?.status}</b>
											</Typography>
										</Box>
									</Grid>

									<Divider orientation="horizontal" size={1.5} />

									<MuiDivider />

									<Divider orientation="horizontal" size={1.5} />

									<Grid item>
										<Typography variant="overline" color="textPrimary">
											<b>Desde:</b> {formatDateAndTimeBrazilianDate(hoverData.connectionMetric?.startedAt)?.dateAndTime}
										</Typography>
									</Grid>

									<Grid item>
										<Typography variant="overline" color="textPrimary">
											<b>Até:</b> {formatDateAndTimeBrazilianDate(hoverData.connectionMetric?.finishedAt)?.dateAndTime}
										</Typography>
									</Grid>

									<Grid item>
										<Typography variant="overline" color="textPrimary">
											<b>Duração:</b> {millisecondsToHumanReadableTime(hoverData.connectionMetric?.durationInMilliseconds)}
										</Typography>
									</Grid>

									<Divider orientation="horizontal" size={1.5} />

									<MuiDivider />

									<Divider orientation="horizontal" size={1.5} />

									<Grid item>
										<Typography variant="overline" color="textPrimary">
											<b>Número do telefone:</b> {hoverData.connectionMetric?.extraData?.phone_number ? (
												formatPhoneNumber(hoverData.connectionMetric?.extraData?.phone_number)
											) : (
												"??"
											)}
										</Typography>
									</Grid>

									<Grid item>
										<Typography variant="overline" color="textPrimary">
											<b>Biblioteca:</b> {
												hoverData.connectionMetric?.extraData?.sessionLibType ? (
													transformLibTypesToNames[hoverData.connectionMetric?.extraData?.sessionLibType]
												) : (
													"??"
												)}
										</Typography>
									</Grid>
									<Grid item>
										<Typography variant="overline" color="textPrimary">
											<b>Detalhes da conexão:</b>
											<br />
											<span>- Conversas: {hoverData.connectionMetric?.extraData?.loaded_data_count?.chats ?? "??"}</span>
											<br />
											<span>- Contatos: {hoverData.connectionMetric?.extraData?.loaded_data_count?.contacts ?? "??"}</span>
											<br />
											<span>- Mensagens: {hoverData.connectionMetric?.extraData?.loaded_data_count?.messages ?? "??"}</span>
											<br />
											<span>- Tipo de telefone: {hoverData.connectionMetric?.extraData?.phone_model ? (
												<PhoneModelSearch
													phoneModel={hoverData.connectionMetric?.extraData?.phone_model}
												/>
											) : (
												"??"
											)}</span>
										</Typography>
									</Grid>
								</Grid>
							</Hint>
						)}
					</FlexibleWidthXYPlot>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default ChannelConnectionMetrics
