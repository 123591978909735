import { Grid, IconButton, Link, TextField, Tooltip, Typography } from "@material-ui/core"
import React, { useState } from "react"
import useStyles from "@/pages/Tools/ToolsPages/FindChatByProtocolToolPage/styles"
import {
	LaunchTwoTone as LaunchTwoToneIcon,
	SearchTwoTone as SearchTwoToneIcon
} from "@material-ui/icons"
import { Divider, Notification, Portlet } from "@/components"
import PhoneNumberRedirect from "@/components/PhoneNumberRedirect"
import { formatDateAndTimeBrazilianDate } from "@/utils/time"
import { InboxAdminApi } from "@/services/Api"

type SearchedProtocolData = {
	protocol: string
	instanceId: number
	chatUrl: string
	chatPhoneNumber: string
	createdAt: Date
	instanceOwnerUserId: number
}

const FindChatByProtocolToolPage = () => {
	const classes = useStyles()

	const [protocol, setProtocol] = useState<string>()
	const [searchedProtocolHistory, setSearchedProtocolHistory] = useState<string[]>([])
	const [searchedProtocol, setSearchedProtocol] = useState<SearchedProtocolData>()

	const [searchingProtocol, setSearchingProtocol] = useState<boolean>(false)
	const [logInUserLoading, setLogInUserLoading] = useState<boolean>(false)

	const handleLogInUser = async (userId: number, instanceId: number) => {
		setLogInUserLoading(true)

		try {
			const { data } = await InboxAdminApi.get(`/auth/login-user/${userId}/${instanceId}`)

			window.open(data.urlRedirect, "_blank")
		} catch (error) {
			Notification.error({ message: `Houve um erro ao entrar instância ${instanceId}` })
		}

		setLogInUserLoading(false)
	}

	const handleSearchProtocol = async (paramProtocol: string) => {
		setSearchingProtocol(true)

		try {
			const isRepeatingLastSearch = paramProtocol === searchedProtocol?.protocol
			if (!isRepeatingLastSearch) {
				const { data } = await InboxAdminApi.get("channel/chat/attendance", {
					params: {
						protocol: paramProtocol
					}
				})

				setSearchedProtocol({
					protocol: data.protocol,
					createdAt: data.createdAt,
					instanceId: data.instanceId,
					chatUrl: data.chatUrl,
					chatPhoneNumber: data.chatPhoneNumber,
					instanceOwnerUserId: data.instanceOwnerUserId
				})

				setSearchedProtocolHistory([paramProtocol, ...searchedProtocolHistory])
			}
		} catch (error) {
			Notification.error({ message: `Houve um erro ao buscar pelo protocolo ${paramProtocol}` })
		}

		setSearchingProtocol(false)
	}

	return (
		<Grid container spacing={2}>
			<Grid
				item
				xs={12}
			>
				<Typography
					color="textPrimary"
					variant="h4"
				>
					Encontrar chat por protocolo
				</Typography>
			</Grid>

			<Grid
				item
				xs={12}
			>
				<Grid
					container
					className={classes.protocolContainer}
				>
					<Grid
						item
						xs={6}
					>
						<TextField
							id="input-protocol"
							name="protocol"
							value={protocol}
							onChange={
								({ target }) => {
									setProtocol(target.value)
								}
							}
							variant="outlined"
							label="Protocolo"
							fullWidth
						/>
					</Grid>

					<Grid
						item
						xs={2}
					>
						<IconButton
							disabled={searchingProtocol}
							onClick={() => {
								handleSearchProtocol(String(protocol))
							}}
						>
							<SearchTwoToneIcon />
						</IconButton>
					</Grid>
				</Grid>

				<Divider
					orientation="horizontal"
					size={2}
				/>

				<Grid
					container
					className={classes.protocolHistoryContainer}
				>
					{
						searchedProtocolHistory.map((searchedProtocolHistoryItem, index) => {
							return (
								<Link
									key={index}
									className={classes.protocolHistoryItem}
									onClick={() => {
										setProtocol(searchedProtocolHistoryItem)
										handleSearchProtocol(searchedProtocolHistoryItem)
									}}
								>
									{searchedProtocolHistoryItem}
								</Link>
							)
						})
					}
				</Grid>

				<Divider
					orientation="horizontal"
					size={2}
				/>

				{
					searchedProtocol && (
						<>

							<Grid
								container
							>
								<Portlet
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center"
									}}
								>
									<Grid
										item
										xs={3}
									>
										Criado em {formatDateAndTimeBrazilianDate(searchedProtocol.createdAt).dateAndTime}
									</Grid>
									<Grid
										item
										xs={2}
									>
										#{searchedProtocol.protocol}
									</Grid>
									<Grid
										item
										xs={2}
									>
										Instância {searchedProtocol.instanceId}
									</Grid>
									<Grid
										item
										xs={3}
									>
										<PhoneNumberRedirect
											chatUrl={searchedProtocol.chatUrl}
											phoneNumber={searchedProtocol.chatPhoneNumber}
										/>
									</Grid>
									<Grid
										item
										xs={2}
										className={classes.actionItem}
									>
										<Tooltip
											title={`Autenticar como usuário dono da instância ${searchedProtocol.instanceId}`}
										>
											<IconButton
												disabled={logInUserLoading}
												onClick={() => {
													handleLogInUser(searchedProtocol.instanceOwnerUserId, searchedProtocol.instanceId)
												}}
											>
												<LaunchTwoToneIcon />
											</IconButton>
										</Tooltip>
									</Grid>
								</Portlet>

							</Grid>

							<Divider
								orientation="horizontal"
								size={1}
							/>

							<Grid
								item
							>
								<Typography
									className={classes.chatLinkWarning}
								>
									Atenção: antes de abrir a URL do chat, verifique se está autenticado na instância correta
								</Typography>
							</Grid>
						</>
					)
				}

			</Grid>
		</Grid>
	)
}

export default FindChatByProtocolToolPage
