import React, { useState } from "react"

import { Switch, Route, useHistory } from "react-router-dom"

import AuthService from "@/services/Auth"

import PrivateRoutes from "@/routes/private"
import PublicRoutes from "@/routes/public"

import { Loading } from "@/components"
import useDidMount from "@/hooks/useDidMount"
import { useGlobalStateStore } from "@/store/GlobalState"
import User from "@/services/User"

const PrivateComponent = () => {
	const [loading, setLoading] = useState(true)
	const useGlobalState = useGlobalStateStore()
	const history = useHistory()

	const getInitialData = async () => {
		const isLoggedIn = await AuthService.isLoggedIn()

		if (!isLoggedIn) {
			history.push("/signin")
		}

		const user = await User.getInfo()

		if (user) {
			useGlobalState.setUserData({
				id: user?.userData?.id,
				name: user?.userData?.name,
				email: user?.userData?.email
			})
		}

		setLoading(false)
	}

	useDidMount(() => {
		getInitialData()
	})

	return (
		<Loading loading={loading}>
			<PrivateRoutes />
		</Loading>
	)
}

const Routes = () => (
	<Switch>
		{PublicRoutes}
		<Route path="/" component={PrivateComponent} />
		<Route path="*" component={() => <h1>Page Not Found</h1>} />
	</Switch>
)

export default Routes
