export default {
	field_cannot_be_empty: "O campo não pode ser vazio",
	invalid_format_email: "Email é inválido",
	user_email_not_exist: "Usuário não cadastrado",
	user_email_exist: "Usuário já cadastrado no sistema",
	number_of_characters_less_than_six: "Deve conter mais de 6 caracteres",
	number_of_characters_less_than_three: "Deve conter mais de 3 caracteres",
	password_is_invalid: "Senha inválida",
	value_must_be_a_number: "Insira um número.",
	editable_text_already_exists: "Já existe um texto editável com este código."
}
