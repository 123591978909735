import React from "react"
import { Route } from "react-router-dom"
import Tools from "@/pages/Tools"
import ClearMessageQueueToolPage from "@/pages/Tools/ToolsPages/ClearMessageQueueToolPage"
import FindChatByProtocolToolPage from "@/pages/Tools/ToolsPages/FindChatByProtocolToolPage"

const ToolsRoutes = [
	<Route
		key="tools"
		exact
		path="/tools"
		component={Tools}
	/>,
	<Route
		key="clear-message-queue-tool"
		exact
		path="/tools/clear-message-queue"
		component={ClearMessageQueueToolPage}
	/>,
	<Route
		key="find-chat-by-protocol"
		exact
		path="/tools/find-chat-by-protocol"
		component={FindChatByProtocolToolPage}
	/>
]

export default ToolsRoutes
