import React, { useState } from "react"
import { useParams, Link as LinkRouter } from "react-router-dom"
import {
	Grid,
	Typography,
	Breadcrumbs,
	List,
	ListItem,
	ListItemText,
	Switch
} from "@material-ui/core"
import {
	Launch as LaunchIcon
} from "@material-ui/icons"

import { AccountAdminApi, InboxAdminApi } from "@/services/Api"

import useDidMount from "@/hooks/useDidMount"
import useValidation, { ErrorType } from "@/hooks/useValidation"
import { useGlobalStateStore } from "@/store/GlobalState"

import { ActionDialog, CustomTextField, Divider, Loading, Portlet } from "@/components"

import {
	GetUsersInInstanceResponseProps
} from "@/pages/Inbox/protocols/UserInInstanceProtocol"
import { InboxChannelProps } from "@/pages/Inbox/protocols/ChannelProtocol"
import { InstanceAdminProps } from "@/pages/Inbox/protocols/InstanceProtocol"

import UsersTable, { UsersInInstanceWhereData } from "@/pages/Inbox/Instances/Details/UsersTable"
import ChannelsTable from "@/pages/Inbox/Instances/Details/ChannelsTable"
import { InstanceCard } from "@/pages/Inbox/Instances/Details/InstanceCard/"
import ManageInstanceChats from "@/pages/Inbox/Instances/Details/ManageInstanceChats"

import CardSkeleton from "@/skeletons/CardSkeleton"

import { formatCnpj } from "@/utils/mask"
import HardCodedUtil from "@/shared/utils/hardcoded"

import useStyles from "@/pages/Inbox/Instances/Details/styles"

type Module = "message-blast"

type ModuleData = {
	active: boolean
}

const InboxInstanceDetails: React.FC = () => {
	const { triggerValidation } = useValidation()
	const classes = useStyles()
	const params = useParams<{ instanceId: string }>()

	const instanceId = Number(params.instanceId)
	const useGlobalState = useGlobalStateStore()
	const adminId = useGlobalState.userData.id as number

	const [usersInInstance, setUsersInInstance] = useState<GetUsersInInstanceResponseProps>({} as GetUsersInInstanceResponseProps)
	const [usersInInstanceLoading, setUsersInInstanceLoading] = useState<boolean>(true)

	const [ownerInstance, setOwnerInstance] = useState<InstanceAdminProps>({} as InstanceAdminProps)

	const [inboxChannel, setInboxChannel] = useState<InboxChannelProps>({} as InboxChannelProps)
	const [inboxChannelLoading, setInboxChannelLoading] = useState<boolean>(true)
	const [instanceLoading, setInstanceLoading] = useState<boolean>(true)
	const [editDialogData, setEditDialogData] = useState({
		inputName: "",
		inputLabel: "",
		inputValue: "",
		open: false
	})

	const [modules, setModules] = useState<Record<Module, ModuleData>>({} as Record<Module, ModuleData>)
	const [modulesLoading, setModulesLoading] = useState<boolean>(true)

	const isWABAInstance = ownerInstance.current_channel_type === "waba"
	const enableEditInstanceModuleStatus = HardCodedUtil.canChangeModuleStatus(adminId) && !isWABAInstance

	const getUsersInInstance = async (newWhereData?: Partial<UsersInInstanceWhereData>) => {
		const defaultWhereData = {
			page: 0,
			rowsPerPage: 50
		}

		setUsersInInstanceLoading(true)

		try {
			const { data } = await InboxAdminApi.get(`/user-in-instance/${instanceId}`, {
				params: {
					...defaultWhereData,
					...newWhereData
				}
			})

			setUsersInInstance(data)
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setUsersInInstanceLoading(false)
	}

	const getInstanceInfo = async () => {
		setInstanceLoading(true)
		try {
			const { data: inboxData } = await InboxAdminApi
				.get("/instances", {
					params: {
						instance_id: instanceId
					}
				})

			const instanceData = inboxData.data[0]

			let accountData = null
			if (instanceData?.owner_user_account_id) {
				const { data } = await AccountAdminApi
					.get(`/user/${instanceData.owner_user_account_id}`, {
						params: {
							instance_id: instanceId
						}
					})

				accountData = data
			}

			setOwnerInstance({
				...instanceData,
				owner_user_phone_number: accountData?.phone_number
			})
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
		setInstanceLoading(false)
	}

	const getInboxChannel = async () => {
		setInboxChannelLoading(true)

		try {
			const { data } = await InboxAdminApi.get(`/channel/${instanceId}`)

			setInboxChannel(data)
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setInboxChannelLoading(false)
	}

	const openEditDialog = (inputName: string, inputValue: string, inputLabel: string) => {
		setEditDialogData({
			...editDialogData,
			inputLabel,
			inputName,
			inputValue,
			open: true
		})
	}

	const handleSaveInstaceData = async () => {
		try {
			await InboxAdminApi.put(`/instance/${ownerInstance.id}`, {
				[editDialogData.inputName]: editDialogData.inputValue
			})

			setEditDialogData({
				...editDialogData,
				open: false
			})

			await getInstanceInfo()
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
	}

	const getAllModuleStatus = async () => {
		setModulesLoading(true)
		try {
			const { data } = await InboxAdminApi.get(`/permission/modules/${instanceId}`)

			setModules(data?.modules || {
				"message-blast": {
					active: false
				}
			})
		} catch (error) {
			triggerValidation(error as ErrorType)
		}

		setModulesLoading(false)
	}

	const handleUpdateModuleStatus = async (module: Module, data: Partial<ModuleData>) => {
		try {
			await InboxAdminApi.put(`/permission/modules/${instanceId}`, {
				module,
				moduleData: data
			})

			setModules(lastState => ({
				...lastState,
				[module]: data as ModuleData
			}))
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
	}

	const handleToggleActivation = async (module: Module, active: boolean) => {
		await handleUpdateModuleStatus(module, {
			active
		})
	}

	useDidMount(() => {
		getUsersInInstance()
		getInstanceInfo()
		getInboxChannel()

		if (HardCodedUtil.canChangeModuleStatus(adminId)) {
			getAllModuleStatus()
		}
	})

	return (
		<>
			<Loading
				customLoadingElement={
					<CardSkeleton
						cardHeight={200}
					/>}
				loading={inboxChannelLoading}
			>
				<Grid spacing={3} container justifyContent="flex-start">
					<Grid item xs={12}>
						<Breadcrumbs aria-label="breadcrumb">
							<LinkRouter to="/inbox/instances" className={classes.instanceLink}>
								Instâncias
							</LinkRouter>

							<Typography
								color="textPrimary"
							>
								{instanceId}
							</Typography>

							<Typography
								color="textPrimary"
							>
								Detalhes
							</Typography>
						</Breadcrumbs>
					</Grid>

					<Grid item>
						<LinkRouter to={`/account/${ownerInstance.owner_user_account_id}`} className={classes.accountLink}>
							Acessar account do dono da Instância
							<LaunchIcon
								fontSize="small"
							/>
						</LinkRouter>
					</Grid>

					<Divider size={3} orientation="horizontal" />
					{HardCodedUtil.canClearInboxAttendances() && <ManageInstanceChats
						inboxChannel={inboxChannel}
						usersInInstance={usersInInstance}
					/>}

					<Divider size={3} orientation="horizontal" />

					<Grid item xs={12} md={12}>
						<Typography
							variant="h2"
							className={classes.title}
						>
							DADOS DA INSTÂNCIA
						</Typography>

						<Divider size={2} orientation="horizontal" />

						<Loading
							customLoadingElement={
								<CardSkeleton
									cardHeight={200}
								/>}
							loading={instanceLoading}
						>
							<Portlet
								style={{
									padding: "8px 16px",
									minHeight: "80px"
								}}
								elevation={1}
							>
								<Grid spacing={1} container justify="center" alignItems="center">
									<Grid item xs>
										<List>
											<ListItem
												className={classes.listItem}
											>
												<ListItemText
													primary={<Typography className={classes.listPrimaryText} variant="caption">CNPJ</Typography>}
													secondary={
														<InstanceCard
															name={formatCnpj(ownerInstance?.cnpj || "")}
															onClickEditButton={() => openEditDialog(
																"cnpj",
																ownerInstance?.cnpj || "",
																"CNPJ"
															)}
														/>
													} />
											</ListItem>

											<ListItem
												className={classes.listItem}
											>
												<ListItemText
													primary={<Typography className={classes.listPrimaryText} variant="caption">Número de telefone responsável</Typography>}
													secondary={
														<InstanceCard
															name={ownerInstance.responsible_phone}
															canEdit={false}
														/>
													} />
											</ListItem>
										</List>
									</Grid>

								</Grid>
							</Portlet>
						</Loading>

					</Grid>

					{enableEditInstanceModuleStatus && (
						<Grid item xs={12} md={12}>
							<Typography
								variant="h2"
								className={classes.title}
							>
								LICENÇAS DA INSTÂNCIA
							</Typography>

							<Divider size={2} orientation="horizontal" />

							<Loading
								customLoadingElement={
									<CardSkeleton
										cardHeight={100}
									/>}
								loading={modulesLoading}
							>
								<Portlet
									elevation={1}
								>
									<Grid
										container
										direction="column"
										justifyContent="center"
									>
										<Grid
											item
											xs={12}
										>
											<Grid
												container
												alignItems="center"
												justifyContent="space-between"
												className={classes.moduleItem}
											>
												<Grid
													item
												>
													<Typography
														color="textPrimary"
													>
														Envio em massa
													</Typography>
												</Grid>
												<Grid
													item
												>
													<Switch
														color="primary"
														checked={modules["message-blast"]?.active}
														onChange={({ target }) => handleToggleActivation("message-blast", target.checked)}
													/>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Portlet>
							</Loading>
						</Grid>
					)}

					<UsersTable
						instanceId={instanceId}
						usersInInstance={usersInInstance}
						usersInInstanceLoading={usersInInstanceLoading}
						instanceOwnerUserId={ownerInstance?.owner_user_id}
						getUsersInInstance={getUsersInInstance}
						getInstanceInfo={getInstanceInfo}
						appInstanceId={ownerInstance?.account_instance_id}
					/>

					<Divider size={3} orientation="horizontal" />

					<Grid item xs>
						<Typography
							variant="h2"
							className={classes.title}
						>
							Relatórios de Utilização
						</Typography>

						<Divider size={1} orientation="horizontal" />

						<Portlet elevation={1}>
							<a
								href={`https://grafana.apps.appdot.dev/d/RV3xiazVz/linha-do-tempo-de-utilizacao-por-instancia?orgId=1&var-feature=All&var-instance_id=${instanceId}`}
								className={classes.accountLink}
								target="_blank"
								rel="noopener noreferrer"
							>
								Linha do Tempo de Utilização
								<LaunchIcon
									fontSize="small"
								/>
							</a>
						</Portlet>
					</Grid>

					<Divider size={2} orientation="horizontal" />

					<ChannelsTable
						getInboxChannel={getInboxChannel}
						inboxChannel={inboxChannel}
						inboxChannelLoading={inboxChannelLoading}
					/>

					<ActionDialog
						title={`Editar ${editDialogData.inputLabel}`}
						onSave={() => handleSaveInstaceData()}
						saveText="Salvar"
						onClose={() => {
							setEditDialogData({
								...editDialogData,
								open: false
							})
						}}
						openDialog={editDialogData.open}
						fullWidth
					>
						<Grid container>
							<Grid item xs={12}>
								<CustomTextField
									onChange={
										({ target }) => setEditDialogData({ ...editDialogData, inputValue: target.value })
									}
									value={editDialogData.inputValue}
									variant="outlined"
									placeholder={editDialogData.inputLabel}
									fullWidth
								/>
							</Grid>

							<Divider size={2} orientation="horizontal" />
						</Grid>
					</ActionDialog>
				</Grid>
			</Loading>
		</>
	)
}

export default InboxInstanceDetails
