import { AxiosError } from "axios"

export const getStatusCode = (error: AxiosError): number | null => {
	const statusCode = +(error?.response?.status as number)

	return statusCode ?? null
}

export const getErrorCodeMessages = (error: AxiosError): Record<string, unknown> | null => {
	const codeMessages = error?.response?.data?.codeMessages

	return codeMessages ?? null
}
