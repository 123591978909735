import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	protocolContainer: {

	},
	protocolHistoryContainer: {
		gap: "15px"
	},
	protocolHistoryItem: {
		cursor: "pointer"
	},
	actionItem: {
		textAlign: "end"
	},
	chatLinkWarning: {
		fontSize: "20px",
		color: newColors.red[500]
	}
})

export default useStyles
