import React, { FormEvent, useState } from "react"
import { useHistory } from "react-router-dom"
import {
	Grid,
	Typography,
	Avatar,
	Container,
	useTheme,
	useMediaQuery,
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
	IconButton,
	TextField,
	InputAdornment
} from "@material-ui/core"
import {
	ExitToApp as LogoutIcon,
	Menu as MenuIcon,
	Search as SearchIcon
} from "@material-ui/icons"

import AuthService from "@/services/Auth"

import { Divider } from "@/components"
import { isSmallScreen } from "@/utils/checkDevice"

import logoImg from "@/assets/images/logos/letalk-logo-white.svg"

import useStyles from "@/routes/private/Components/Navbar/styles"
import { useGlobalStateStore } from "@/store/GlobalState"

type NavbarProps = {
	onOpen: () => void
}

const Navbar: React.FC<NavbarProps> = (props) => {
	const {
		onOpen
	} = props

	const MuiTheme = useTheme()
	const classes = useStyles()
	const isSmall = useMediaQuery(MuiTheme.breakpoints.down("xs"))
	const [search, setSearch] = useState("")
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const openMenu = Boolean(anchorEl)
	const history = useHistory()
	const useGlobalState = useGlobalStateStore()

	const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	const handleLogout = async (event: React.SyntheticEvent) => {
		event.preventDefault()

		await AuthService.logout()
	}

	const handleDrawerOpen = () => {
		onOpen()
	}

	const searchInstance = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		const query = `?search=${search}`
		setSearch("")
		history.push({ pathname: "/inbox/instances", search: query })
	}

	return (
		<Grid container alignItems="center" className={classes.navbarContainer}>
			<Container maxWidth={"xl"}>
				<Grid container justify="space-between" alignContent="center" alignItems="center" wrap="nowrap" >
					<Grid item>
						<Grid container alignItems="center" wrap="nowrap">
							{
								isSmallScreen
									? <IconButton color="inherit" onClick={handleDrawerOpen} className={classes.iconButton}>
										<MenuIcon className={classes.menuIcon} />
									</IconButton>
									: <img alt="Letalk" src={logoImg} className={classes.logoImg} />
							}

							<Divider orientation="vertical" size={2} />

							{
								!isSmall &&
								<Typography variant="button" color="inherit" className={classes.titleText}>
									PAINEL ADMINISTRATIVO INTERNO
								</Typography>
							}
						</Grid>
					</Grid>

					<Grid item>
						<Grid container spacing={3} alignItems="center" wrap="nowrap">
							<Grid item>
								<Grid container alignItems="center">
									<form onSubmit={searchInstance}>
										<TextField
											size="small"
											className={classes.search}
											value={search}
											onChange={({ target }) => setSearch(target.value)}
											variant="outlined"
											placeholder="Pesquisar nas instâncias"
											InputProps={{
												startAdornment: (
													<InputAdornment position="start" >
														<SearchIcon />
													</InputAdornment>
												)
											}}
										/>
									</form>
								</Grid>
							</Grid>

							{
								!isSmall && (
									<Grid item>
										<Grid container direction="column" alignItems="flex-end">
											<Typography variant="h2" color="inherit" className={classes.nameText}>
												{useGlobalState?.userData?.name}
											</Typography>

											<Typography color="inherit" variant="h2" className={classes.emailText}>
												{useGlobalState?.userData?.email}
											</Typography>
										</Grid>
									</Grid>
								)
							}

							<Grid item>
								<Avatar
									aria-label="more"
									aria-controls="header-menu"
									aria-haspopup="true"
									onClick={handleClickMenu}
									className={classes.profile}
								>
									{useGlobalState?.userData?.name?.split("").shift()}
								</Avatar>

								<Menu
									id="header-menu"
									anchorEl={anchorEl}
									keepMounted
									open={openMenu}
									onClose={handleCloseMenu}
									autoFocus={false}
									PaperProps={{
										style: {
											marginTop: 40
										}
									}}
								>
									<MenuItem component="a" href="#logout" onClick={handleLogout}>
										<ListItemIcon>
											<LogoutIcon fontSize="small" />
										</ListItemIcon>
										<ListItemText primary="Sair" />
									</MenuItem>
								</Menu>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container >
		</Grid >
	)
}

export default Navbar
