import React from "react"
import { Route } from "react-router-dom"

import AccountsPage from "@/pages/Account/Account"
import AccountDetailsPage from "@/pages/Account/Account/Details"
import PlansAndAddons from "@/pages/Account/PlansAndAddons"
import Hotmart from "@/pages/Account/Hotmart"
import SpreadsheetCustomers from "@/pages/Account/SpreadsheetCustomers"
import SpreadsheetCustomersDetails from "@/pages/Account/SpreadsheetCustomers/Details"

const AccountRoutes = [
	<Route
		key="account"
		exact
		path="/account"
		component={AccountsPage}
	/>,
	<Route
		key="account-details"
		exact
		path="/account/:accountId"
		component={AccountDetailsPage}
	/>,
	<Route
		key="plans"
		exact
		path="/plans"
		component={PlansAndAddons}
	/>,
	<Route
		key="hotmart"
		exact
		path="/hotmart"
		component={Hotmart}
	/>,
	<Route
		key="spreadsheet-customers"
		exact
		path="/spreadsheet-customers"
		component={SpreadsheetCustomers}
	/>,
	<Route
		key="spreadsheet-customers-details"
		exact
		path="/spreadsheet-customers/:id"
		component={SpreadsheetCustomersDetails}
	/>
]

export default AccountRoutes
