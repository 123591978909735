import React from "react"
import { Link } from "@material-ui/core"

type PhoneModelSearchProps = {
	phoneModel: string
}

const PhoneModelSearch: React.FC<PhoneModelSearchProps> = (props) => {
	const { phoneModel } = props

	return (
		<Link
			href={encodeURI(`https://www.google.com/search?q=${phoneModel}`)}
			target="_blank"
		>
			{phoneModel}
		</Link>
	)
}

export default PhoneModelSearch
