import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

export const BAR_HEIGHT = 30

const useStyles = makeStyles(theme => ({
	metricsPlot: {
		"& g": {
			transform: `translate(0, ${BAR_HEIGHT}px)`
		}
	},
	metricsBar: {
		"& rect": {
			width: "100%"
		}
	},
	metricsPlotContainer: {
		position: "relative"
	},
	metricsPlotHint: {
		backgroundColor: colors.grayScale[11],
		padding: theme.spacing(1, 2),
		boxShadow: theme.shadows[1],
		borderRadius: theme.spacing(0.5),
		position: "relative",
		zIndex: 3
	},
	customLink: {
		color: colors.palette.link,
		textDecoration: "none",
		fontSize: "14px"
	}
}))

export default useStyles
