import React, { useState } from "react"
import { Button, CircularProgress, FormControl, FormHelperText, Grid } from "@material-ui/core"

import AceEditor, { IAceEditorProps } from "react-ace"
import "ace-builds/src-noconflict/mode-json"
import "ace-builds/src-noconflict/theme-textmate"

import useValidation from "@/hooks/useValidation"

type JsonEditorProps = IAceEditorProps & {
	value: string | undefined
	onChange?: (newJson: string) => void
	onSave?: (jsonData: string) => Promise<void>
}

const JsonEditor: React.FC<JsonEditorProps> = (props) => {
	const { value, onChange, onSave, ...rest } = props

	const {
		addValidation,
		clearValidation,
		validation
	} = useValidation()

	const stringifyValue = JSON.stringify(value, null, 2)

	const [jsonData, setJsonData] = useState(stringifyValue)
	const [loadingSaveJson, setLoadingSaveJson] = useState<boolean>(false)

	const handleChange = (newValue: string) => {
		clearValidation("jsonEditor")

		setJsonData(newValue)

		onChange && onChange(newValue)
	}
	const handleSave = async () => {
		setLoadingSaveJson(true)

		if (onSave) {
			try {
				const parseJsonData = JSON.parse(jsonData)

				await onSave(parseJsonData)
			} catch (error) {
				addValidation({
					jsonEditor: "Algo está errado. Revise os dados inseridos"
				})
			}
		}

		setLoadingSaveJson(false)
	}

	return (
		<Grid container spacing={2}>
			<Grid item>
				<FormControl
					error={!!validation.jsonEditor}
					fullWidth
				>
					<AceEditor
						{...rest}
						name="json-editor"
						value={jsonData}
						mode="json"
						theme="textmate"
						onChange={handleChange}
						fontSize={16}
						showPrintMargin={true}
						showGutter={true}
						highlightActiveLine={true}
						editorProps={{ $blockScrolling: true }}
						setOptions={{
							showLineNumbers: true,
							tabSize: 2
						}}
					/>
				</FormControl>
				<FormHelperText
					error={!!validation.jsonEditor}
					id="json-editor-helper-text"
				>
					{validation.jsonEditor}
				</FormHelperText>
			</Grid>

			{
				onSave &&
				<Grid item>
					<Grid container justify="flex-end">
						<Grid item>
							<Button
								onClick={handleSave}
								color="primary"
								variant="contained"
								disableElevation
								disabled={loadingSaveJson}
								endIcon={loadingSaveJson && (
									<CircularProgress size={20} color="inherit" />
								)}
							>
							Salvar
							</Button>
						</Grid>
					</Grid>

				</Grid>
			}

		</Grid>
	)
}

export default JsonEditor
