import React from "react"
import {
	Grid,
	Typography,
	IconButton
} from "@material-ui/core"

import {
	Edit as EditIcon
} from "@material-ui/icons"

import useStyles from "@/pages/Inbox/Instances/Details/InstanceCard/style"

type TeamDataProps = {
	id: number
	instanceId?: number
	name: string
}

type InstanceCardProps = {
	name?: string
	status?: "blocked",
	teams?: TeamDataProps[]
	canEdit?: boolean

	onClickEditButton?: () => void
}

const InstanceCard: React.FC<InstanceCardProps> = (props) => {
	const { name, status, canEdit = true, onClickEditButton } = props

	const classes = useStyles()

	return (
		<Grid container justify="space-between" alignItems="center">
			<Grid
				item xs={12} sm={8}
				className={classes.textContainer}
			>
				<Typography
					color="textPrimary"
					variant="h2"
					className={classes.nameText}
				>
					{name}
				</Typography>
			</Grid>

			{
				canEdit && (
					<Grid item xs={12} sm={2}>
						<Grid container justify="flex-end">
							<IconButton onClick={onClickEditButton} size="small">
								<EditIcon style={{ color: status === "blocked" ? "red" : "" }} />
							</IconButton>
						</Grid>
					</Grid>
				)
			}
		</Grid>
	)
}

export { InstanceCard }
