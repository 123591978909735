import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"

const useStyles = makeStyles({
	title: {
		color: colors.grayScale[1],
		textTransform: "uppercase",
		fontWeight: "bold"
	},
	paper: {
		width: "100%"
	},
	tableRow: {
		cursor: "auto",
		textDecoration: "none !important"
	},
	datePicker: {
		width: "100%"
	},
	datePickerInput: {
		"& .MuiOutlinedInput-input": {
			padding: "12px 10px"
		}
	},
	dateDeleteIcon: {
		padding: "5px"
	},
	deleteIcon: {
		padding: "5px"
	},
	deletedAtDate: {
		color: "red",
		fontSize: "12px",
		fontStyle: "italic"
	}
})

export default useStyles
