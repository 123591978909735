import React, { ReactNode, useState, useEffect } from "react"
import {
	ThemeProvider,
	IconButton,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	Button,
	CircularProgress,
	Drawer
} from "@material-ui/core"
import {
	Close as CloseIcon
} from "@material-ui/icons"

import theme from "@/styles/theme"

import { renderComponent } from "@/utils/node"
import { isSmallScreen } from "@/utils/checkDevice"
import { handleBackButton } from "@/utils/backButton"

import useDidMount from "@/hooks/useDidMount"

import useStyles from "@/components/ActionDialog/styles"
import useCustomStyles from "@/styles/custom"

type ActionDialogProps = {
	title: string
	children: ReactNode
	onSave?: () => Promise<void>
	onClose?: () => void

	saveText?: string
	openDialog?: boolean
	loading?: boolean
	fullWidth?: boolean
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl"

	hideSaveButton?: boolean
	hideCancelButton?: boolean

	customId?: string
}

type ActionDialogType = {
	open: (props: ActionDialogProps) => void
}

const ActionDialog: ActionDialogType & React.FC<ActionDialogProps> = (props) => {
	const {
		title,
		openDialog,
		children,
		loading,
		fullWidth,
		maxWidth,
		saveText,
		hideSaveButton,
		hideCancelButton,
		onClose,
		onSave,
		customId
	} = props

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const [opened, setOpened] = useState<boolean>(Boolean(openDialog))

	const handleClose = () => {
		onClose && onClose()

		setOpened(false)
	}

	const handleSave = async () => {
		onSave && await onSave()
	}

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		handleSave()
	}

	useDidMount(() => {
		if (isSmallScreen) {
			handleBackButton(() => handleClose())
		}
	})

	useEffect(() => {
		setOpened(Boolean(openDialog))
	}, [openDialog])

	const content = (
		<form onSubmit={handleSubmit} id={`form-${customId}`}>
			<IconButton onClick={handleClose} className={classes.closeIcon} id={`close-button-${customId}`}>
				<CloseIcon />
			</IconButton>
			<DialogTitle>
				{title}
			</DialogTitle>
			<DialogContent>
				{children}
			</DialogContent>
			<DialogActions className={classes.dialogActions}>
				{!hideCancelButton && (
					<Button
						id={`cancel-button-${customId}`}
						onClick={handleClose}
						className={customClasses.cancelButton}
					>
						Cancelar
					</Button>
				)}

				{!hideSaveButton && (
					<Button
						id={`save-button-${customId}`}
						onClick={handleSave}
						variant="contained"
						color="primary"
						endIcon={loading && <CircularProgress size={20} />}
						disabled={loading}
					>
						{saveText || "Salvar"}
					</Button>
				)}
			</DialogActions>
		</form>
	)

	return (
		<ThemeProvider theme={theme}>
			{
				isSmallScreen ? (
					<Drawer
						anchor="right"
						open={opened}
						onClose={handleClose}
						classes={{ paper: classes.drawerPaper }}
						style={{ width: "100vw" }}
					>
						{content}
					</Drawer>
				) : (
					<Dialog
						open={opened}
						onClose={handleClose}
						PaperProps={{
							className: classes.paper
						}}
						classes={{
							paperFullWidth: classes.paperFullWidth
						}}
						fullWidth={fullWidth}
						maxWidth={maxWidth || "sm"}
						disableBackdropClick
					>
						{content}
					</Dialog>
				)
			}
		</ThemeProvider>
	)
}

ActionDialog.open = (props: ActionDialogProps) => {
	renderComponent(
		"action-dialog",
		<ActionDialog
			{...props}
		/>
	)
}

export default ActionDialog
