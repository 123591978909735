import { PopConfirm, Portlet, Notification } from "@/components"
import { InboxAdminApi } from "@/services/Api"
import { Button, Grid, Typography, Tooltip } from "@material-ui/core"
import {
	Replay as ReplayIcon,
	InfoRounded as InfoIcon
} from "@material-ui/icons"
import React from "react"
import useStyles from "@/pages/Tools/ToolsPages/ClearMessageQueueToolPage/styles"

const ClearMessageQueueToolPage = () => {
	const handleRestartInboxSideJobs = async () => {
		try {
			await InboxAdminApi.get("/inbox-side-jobs/restart")
			Notification.success({ message: "Processo de reinicialização iniciado" })
		} catch (error) {
			Notification.error({ message: "Houve um erro." })
		}
	}

	const handleConfirmRestartInboxSideJobs = async () => {
		const props = {
			description: "Deseja reiniciar este serviço?",
			confirmButtonText: "REINICIAR",
			onConfirm: () => handleRestartInboxSideJobs()
		}
		PopConfirm.open(props)
	}

	const classes = useStyles()

	return <Grid container spacing={2}>
		<Grid item xs={12}>
			<Portlet
				elevation={1}
			>
				<Grid container justify="space-between" alignItems="center">
					<Grid
						item xs={8} sm={6}
					>
						<Grid container alignItems="center">
							<Grid item className={classes.toolCardIcon}>
								<Tooltip title="Inbox Side Jobs">
									<InfoIcon />
								</Tooltip>
							</Grid>
							<Grid item>
								<Typography
									color="textPrimary"
									variant="h4"
								>
                                    Fila de Mensagens
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={4} sm={6}>
						<Grid container justify="flex-end">
							<Grid item>
								<Button
									variant="contained"
									color="primary"
									onClick={handleConfirmRestartInboxSideJobs}
								>
									<ReplayIcon/>
                                    Reniciar
								</Button>

							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Portlet>
		</Grid>
	</Grid>
}

export default ClearMessageQueueToolPage
