import React, { useState } from "react"
import {
	Grid,
	Typography,
	Link as MuiLink,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	IconButton,
	Tooltip,
	CircularProgress
} from "@material-ui/core"
import { AxiosError } from "axios"
import { Link } from "react-router-dom"
import {
	ImportExport as RestartIcon,
	Refresh as ReloadIcon
} from "@material-ui/icons"

import ErrorHandler from "@/services/ErrorHandler"
import { InboxAdminApi, InboxWhatsappChannelApi } from "@/services/Api"

import useDidMount from "@/hooks/useDidMount"
import useInstanceDetailsStyles from "@/pages/Inbox/Instances/Details/styles"

import { getStatusCode } from "@/utils/response"

import { InboxChannelStatus } from "@/pages/Inbox/protocols/ChannelProtocol"

import {
	InfoDialog,
	Loading,
	Notification,
	Divider,
	PopConfirm
} from "@/components"

import WhatsappPodUsageDialogSkeleton from "@/skeletons/WhatsappPodUsageDialogSkeleton"
import useValidation from "@/hooks/useValidation"

type WhatsappPodInfoDialogProps = {
	inboxChannelIntegrationId: string
	getInboxChannel?: () => Promise<void>
}

export type PodUsageSession = {
	name: string
	email: string
	status: InboxChannelStatus
	instanceId: number
	inboxChannelId: number
	inboxChannelIntegrationId: string
	resourcesUsage: {
		cpu: string
		ramMemory: string
	}
}

export type PodUsage = {
	rancherPod: {
		name: string
		url: string
	}
	resourcesUsage: {
		cpu: string
		ramMemory: string
	}
	lastLoadInfo: {
		amountOfDataLoaded: {
			chats: number
			contacts: number
			messages: number
		}
		phoneModel: string
	}
	sessions: PodUsageSession[]
}

type PodSessionRowProps = {
	session?: PodUsageSession
}

const PodSessionRow: React.FC<PodSessionRowProps> = (props) => {
	const { session } = props

	return (
		<TableRow>
			<TableCell>
				<MuiLink
					component={Link}
					to={`/inbox/instance/${session?.instanceId}`}
				>
					{session?.name}
				</MuiLink>

				<br />

				<Typography
					variant="caption"
					color="textPrimary"
				>
					{session?.email}
				</Typography>
			</TableCell>

			<TableCell>
				<Typography
					variant="body1"
				>
					{session?.resourcesUsage?.ramMemory || "??"}
				</Typography>
			</TableCell>

			<TableCell>
				<Typography
					variant="body1"
				>
					{session?.resourcesUsage?.cpu || "??"}
				</Typography>
			</TableCell>
		</TableRow>
	)
}

const WhatsappPodUsageDialog: React.FC<WhatsappPodInfoDialogProps> = (props) => {
	const {
		children,
		inboxChannelIntegrationId,
		getInboxChannel
	} = props

	const [loading, setLoading] = useState(true)
	const [podUsage, setPodUsage] = useState<PodUsage | null>(null)
	const [dialogOpened, setDialogOpened] = useState(false)
	const [loadedFirstTime, setLoadedFirstTime] = useState(false)

	const { triggerValidation } = useValidation()

	const instanceDetailsClasses = useInstanceDetailsStyles()

	const mainSession = podUsage?.sessions?.find(session => session.inboxChannelIntegrationId === inboxChannelIntegrationId)
	const otherSessions = podUsage?.sessions?.filter(session => session.inboxChannelIntegrationId !== inboxChannelIntegrationId)

	const getWhatsappPodUsage = async () => {
		setLoading(true)

		try {
			const result = await InboxAdminApi.get(`/channel/whatsapp/${inboxChannelIntegrationId}/pod-usage`)

			setPodUsage(result.data)
		} catch (error) {
			const responseStatusCode = getStatusCode(error as AxiosError)

			const noUsageFound = responseStatusCode === 404

			if (noUsageFound) {
				setPodUsage(null)
			} else {
				if (dialogOpened) {
					Notification.error({ message: "Ocorreu um erro inesperado ao buscar os dados." })
				}
			}

			ErrorHandler.handle(error)
		}

		setLoading(false)

		setLoadedFirstTime(true)
	}

	const restartWhatsAppSession = async (integrationId: string) => {
		setLoading(true)
		try {
			await InboxWhatsappChannelApi.get(`/whatsapp/session/${integrationId}/kill`)

			/**
			 * Add a delay to make sure the session is killed in order
			 * to start it over.
			 */
			await new Promise(resolve => setTimeout(resolve, 4000))

			await InboxWhatsappChannelApi.get(`/whatsapp/session/${integrationId}/start`)

			Notification.success({ message: "Processo de reinicialização iniciado." })

			getInboxChannel && await getInboxChannel()
		} catch (error) {
			Notification.error({ message: "Houve um erro." })
			triggerValidation(error)
		}
		setLoading(false)
	}

	const handleRestartWhatsAppSession = async (integrationId: string) => {
		const props = {
			description: "Deseja reiniciar sessão?",
			confirmButtonText: "REINICIAR",
			onConfirm: () => restartWhatsAppSession(integrationId)
		}
		PopConfirm.open(props)
	}

	const handleOpenDialog = () => {
		setDialogOpened(true)
	}

	const handleCloseDialog = () => {
		setDialogOpened(false)
	}

	useDidMount(() => {
		getWhatsappPodUsage()
	})

	return (
		<>
			{React.cloneElement(children as React.ReactElement, {
				onClick: handleOpenDialog
			})}

			<InfoDialog
				title={(
					<Grid
						container
						alignItems="center"
					>
						Detalhes de uso de recursos da aplicação

						<Divider orientation="vertical" size={1} />

						<Tooltip
							title="Atualizar dados"
						>
							<IconButton
								disabled={loading}
								onClick={getWhatsappPodUsage}
							>
								{(loading && loadedFirstTime) ? (
									<CircularProgress
										size={24}
									/>
								) : (
									<ReloadIcon />
								)}
							</IconButton>
						</Tooltip>

						<Divider orientation="vertical" size={1} />

						<Tooltip
							title="Reiniciar Sessão"
						>
							<IconButton
								disabled={loading}
								onClick={() => handleRestartWhatsAppSession(inboxChannelIntegrationId)}
							>
								{
									loading
										? <CircularProgress size={20} color="inherit" />
										: <RestartIcon/>
								}
							</IconButton>
						</Tooltip>
					</Grid>
				)}
				fullWidth={true}
				openDialog={dialogOpened}
				onClose={handleCloseDialog}
				maxWidth="md"
			>
				<Loading
					loading={loading}
					customLoadingElement={<WhatsappPodUsageDialogSkeleton />}
				>
					<Grid
						container
					>
						{podUsage ? (
							<Grid
								container
								spacing={6}
							>
								<Grid
									item
									xs={12}
								>
									<Typography
										variant="h4"
										className={instanceDetailsClasses.title}
									>
										RANCHER
									</Typography>

									<Divider orientation="horizontal" size={1} />

									<Typography
										variant="body1"
									>
										<b>- Aplicação:</b>
										{" "}
										<MuiLink
											href={podUsage.rancherPod.url}
											target="_blank"
										>
											{podUsage.rancherPod.name}
										</MuiLink>
									</Typography>
								</Grid>

								<Grid
									item
									xs={12}
								>
									<Typography
										variant="h4"
										className={instanceDetailsClasses.title}
									>
										USO DE RECURSO GERAL DO POD
									</Typography>

									<Divider orientation="horizontal" size={1} />

									<Typography
										variant="body1"
									>
										<b>- Memória RAM:</b> {podUsage.resourcesUsage.ramMemory}
									</Typography>
								</Grid>

								<Grid
									item
									xs={12}
								>
									<Typography
										variant="h4"
										className={instanceDetailsClasses.title}
									>
										USO DE RECURSO DE CADA GOOGLE CHROME ABERTO NO POD
									</Typography>

									<Divider orientation="horizontal" size={1} />

									<TableContainer>
										<Table
											stickyHeader
											aria-label="sticky table"
										>
											<TableHead>
												<TableRow>
													<TableCell>
														Usuário
													</TableCell>

													<TableCell>
														Memória RAM
													</TableCell>

													<TableCell>
														Processamento
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												<PodSessionRow
													session={mainSession}
												/>

												{otherSessions?.map(session => (
													<PodSessionRow
														session={session}
													/>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</Grid>
						) : (
							<Grid
								container
							>
								<Typography
									variant="body1"
								>
									Informações não encontradas. Verifique se esse usuário está com o Google Chrome aberto no ambiente de produção.
								</Typography>
							</Grid>
						)}
					</Grid>
				</Loading>
			</InfoDialog>
		</>
	)
}

export default WhatsappPodUsageDialog
