import React from "react"
import ReactDOM from "react-dom"
import { ThemeProvider } from "@material-ui/core"
import { BrowserRouter } from "react-router-dom"

import GlobalStateProvider from "@/store/GlobalState"
import AccessibleDrawerStateProvider from "@/store/AccessibleDrawerState"

import App from "@/App"

import theme from "@/styles/theme"

import "@/styles/global.css"

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<GlobalStateProvider>
					<AccessibleDrawerStateProvider>
						<App />
					</AccessibleDrawerStateProvider>
				</GlobalStateProvider>
			</BrowserRouter>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById("root")
)
