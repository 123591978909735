import { makeStyles } from "@material-ui/core"
import colors from "@/styles/colors"
import newColors from "@/styles/newColors"

const useCustomStyles = makeStyles({
	buttonPrimaryColorOutLined: {
		fontSize: "14px",
		padding: "15px 15px",
		marginBottom: "20px",
		border: "1px solid",
		boxShadow: "none",
		borderColor: "rgba(126, 13, 214, 0.12)",
		fontWeight: 500,
		backgroundColor: "transparent",
		color: newColors.getPrimaryColor(),
		letterSpacing: 0.4,
		"&:hover": {
			backgroundColor: "transparent",
			boxShadow: "none"
		}
	},
	primaryButton: {
		fontSize: "14px",
		backgroundColor: newColors.getPrimaryColor(),
		color: colors.grayScale[11],
		padding: "15px 15px",
		marginBottom: "20px",
		"&:hover": {
			backgroundColor: newColors.purple[400]
		}
	},
	buttonAction: {
		fontSize: "14px",
		backgroundColor: colors.palette.button1,
		color: colors.grayScale[11],
		padding: "15px 15px",
		marginBottom: "20px",
		"&:hover": {
			backgroundColor: "#02c3b0"
		}
	},
	inlineText: {
		textOverflow: "ellipsis",
		whiteSpace: "pre-wrap",
		overflow: "hidden",
		maxWidth: "100%",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 1,
		flex: 1
	},
	scrollBar: {
		"&::-webkit-scrollbar": {
			width: "6px !important",
			height: "6px !important"
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "rgba(0, 0, 0, 0.2)"
		},
		"&::-webkit-scrollbar-track": {
			background: "hsla(0, 0%, 100%, 0.1)"
		}
	},
	inputLabel: {
		fontSize: 15,
		fontWeight: "bolder"
	},
	datePicker: {
		width: "100%"
	},
	datePickerInput: {
		"& .MuiOutlinedInput-input": {
			padding: "12px 10px"
		}
	},
	cancelButton: {
		color: colors.grayScale[3],
		padding: 16
	}
}, {
	index: 1 // this attribute is necessary to ensure classes not being overriden
})

export default useCustomStyles
