import theme from "@/styles/theme"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	toolCardIcon: {
		marginRight: theme.spacing(1),
		marginLeft: theme.spacing(1),
		paddingTop: theme.spacing(0.4)
	}
})

export default useStyles
