type DateValue = string | Date

export type DateAndTimeResponse = {
	date?: string
	time?: string
	dateAndTime?: string
}

export const isToday = (someDate: Date) => {
	const today = new Date()
	return someDate.getDate() === today.getDate() &&
		someDate.getMonth() === today.getMonth() &&
		someDate.getFullYear() === today.getFullYear()
}

export const formatDateInBrazilianDate = (value?: DateValue): string | null => {
	if (!value) return null

	return new Date(value).toLocaleDateString("pt-BR")
}

export const formatTimeInBrazilianDate = (value: DateValue): string => {
	if (!value) return value

	return new Date(value).toLocaleTimeString("pt-BR")
}

export const formatDateAndTimeBrazilianDate = (value?: DateValue): DateAndTimeResponse => {
	if (!value) return {}
	const formattedDate = formatDateInBrazilianDate(value)
	const formattedTime = formatTimeInBrazilianDate(value)

	if (!formattedDate || !formattedTime) return {}

	return {
		date: formattedDate,
		time: formattedTime,
		dateAndTime: `${formattedDate} | ${formattedTime}`
	}
}

export const millisecondsToHumanReadableTime = (milliseconds: number): string => {
	const seconds = Number((milliseconds / 1000).toFixed(1))
	const minutes = Number((milliseconds / (1000 * 60)).toFixed(1))
	const hours = Number((milliseconds / (1000 * 60 * 60)).toFixed(1))
	const days = Number((milliseconds / (1000 * 60 * 60 * 24)).toFixed(1))

	if (seconds < 60) {
		return `${seconds} segundos`
	} else if (minutes < 60) {
		return `${minutes} minutos`
	} else if (hours < 24) {
		return `${hours} horas`
	} else {
		return `${days} dias`
	}
}

export const dayInMilliseconds = 24 * 60 * 60 * 1000
