import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(() => ({
	listItemIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		color: colors.grayScale[4]
	},
	listItemActive: {
		position: "relative",
		"&:before": {
			position: "absolute",
			content: "close-quote",
			width: "4px",
			height: "100%",
			left: 0,
			top: 0,
			backgroundColor: colors.palette.primary
		}
	},
	menuItem: {
		width: "100%"
	}
}))

export default useStyles
