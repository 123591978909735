import newColors from "@/styles/newColors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	buttonContainer: {
		display: "flex",
		gap: "16px"
	},
	toolButton: {
		"&:disabled": {
			backgroundColor: newColors.grey[300],
			color: newColors.grey[600],
			opacity: 0.9
		}
	}
})

export default useStyles
