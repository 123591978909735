import React from "react"

import {
	ChannelType, InboxChannelBrokerType, InboxChannelLibraryClientType,
	InboxChannelStatus,
	InboxWABAChannelStatus,
	InboxWhatsappChannelStatus
} from "@/pages/Inbox/protocols/ChannelProtocol"

import {
	getWhatsappChannelStatusData, getWhatsappChannelStatusFilterLabel,
	getWhatsappChannelStatusPlatformName
} from "@/channels/Whatsapp/utils/channel"
import {
	getWABAChannelStatusData, getWABAChannelStatusFilterLabel,
	getWABAChannelStatusPlatformName
} from "@/channels/WABA/utils/channel"

import WhatsappChipSessionStatus from "@/channels/Whatsapp/components/WhatsappChipSessionStatus"
import WABAChipSessionStatus from "@/channels/WABA/components/WABAChipSessionStatus"

export type InboxChannelStatusData = {
	label: string
	icon: JSX.Element
	description: string
	customStyle: {
		backgroundColor: string
		color: string
	}
}

export const getInboxChannelStatusData = (input: {
	status: InboxChannelStatus
	inboxChannelType: ChannelType
}): InboxChannelStatusData => {
	const {
		inboxChannelType,
		status
	} = input

	const inboxChannelTypeToInboxChannelStatusData: Record<ChannelType, () => InboxChannelStatusData> = {
		whatsapp: () => getWhatsappChannelStatusData(status as InboxWhatsappChannelStatus),
		waba: () => getWABAChannelStatusData(status as InboxWABAChannelStatus)
	}

	const statusData = inboxChannelTypeToInboxChannelStatusData[inboxChannelType]()

	return statusData
}

export const getInboxChannelStatusComponent = (input: {
	status: InboxChannelStatus
	inboxChannelType?: ChannelType
}): React.ReactElement => {
	const {
		inboxChannelType,
		status
	} = input

	const inboxChannelTypeToInboxChannelTypeComponent: Record<ChannelType, React.ReactElement> = {
		whatsapp: <WhatsappChipSessionStatus status={status as InboxWhatsappChannelStatus} />,
		waba: <WABAChipSessionStatus status={status as InboxWABAChannelStatus} />
	}

	return inboxChannelTypeToInboxChannelTypeComponent[inboxChannelType as ChannelType]
}

export const getInboxChannelStatusPlatformName = (input: {
	wabaBrokerType?: InboxChannelBrokerType
	whatsappLibraryClient?: InboxChannelLibraryClientType
	inboxChannelType?: ChannelType
}): string => {
	const {
		inboxChannelType,
		wabaBrokerType,
		whatsappLibraryClient
	} = input

	if (!inboxChannelType) {
		return ""
	}

	const inboxChannelTypeToPlatformName: Record<ChannelType, () => string> = {
		whatsapp: () => getWhatsappChannelStatusPlatformName(whatsappLibraryClient as InboxChannelLibraryClientType),
		waba: () => getWABAChannelStatusPlatformName(wabaBrokerType as InboxChannelBrokerType)
	}

	return inboxChannelTypeToPlatformName[inboxChannelType as ChannelType]()
}

export const getInboxChannelStatusFilterLabel = (input: {
	status: InboxChannelStatus
	inboxChannelType?: ChannelType
}): string => {
	const {
		inboxChannelType,
		status
	} = input

	const inboxChannelTypeToStatusFilterLabel: Record<ChannelType, () => string> = {
		whatsapp: () => getWhatsappChannelStatusFilterLabel(status as InboxWhatsappChannelStatus),
		waba: () => getWABAChannelStatusFilterLabel(status as InboxWABAChannelStatus)
	}

	return inboxChannelTypeToStatusFilterLabel[inboxChannelType as ChannelType]()
}

export const getChannelConnectionMetricsTitle = (inboxChannelType?: ChannelType): string => {
	const inboxChannelTypeToStatusFilterLabel: Record<ChannelType, string> = {
		whatsapp: "Métricas de conexão com o Whatsapp",
		waba: "Métricas de conexão com o WABA"
	}

	return inboxChannelTypeToStatusFilterLabel[inboxChannelType as ChannelType]
}
