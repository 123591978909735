import React, { useState } from "react"

import {
	Grid,
	Table,
	TableBody,
	TableCell,
	TableCellProps,
	TableContainer,
	TableHead,
	Typography,
	CircularProgress,
	TableRow,
	Link,
	IconButton,
	Collapse,
	Box,
	Tooltip
} from "@material-ui/core"
import {
	KeyboardArrowDown as ArrowDownIcon,
	KeyboardArrowUp as ArrowUpIcon,
	Close as CloseIcon,
	ClearAll as ClearListIcon,
	BarChart as UsageIcon,
	Settings as SettingsIcon
} from "@material-ui/icons"

import { InboxAdminApi, InboxWhatsappChannelApi } from "@/services/Api"

import useStyles from "@/pages/Inbox/Instances/Details/styles"
import useValidation, { ErrorType } from "@/hooks/useValidation"

import { Divider, Loading, Portlet, PopConfirm, Notification } from "@/components"

import {
	ChannelType,
	DailyChannelConnectionMetrics,
	InboxChannel,
	InboxChannelProps
} from "@/pages/Inbox/protocols/ChannelProtocol"
import { UserStatus } from "@/pages/Inbox/protocols/UserProtocol"

import CardSkeleton from "@/skeletons/CardSkeleton"

import { formatPhoneNumber } from "@/utils/mask"
import { formatDateAndTimeBrazilianDate } from "@/utils/time"

import ChannelConnectionMetricsList from "@/pages/Inbox/Instances/components/ChannelConnectionMetricsList"
import WhatsappPodUsageDialog from "@/pages/Inbox/Instances/components/WhatsappPodUsageDialog"
import ClearQueuesDialog from "@/pages/Inbox/Instances/components/ClearQueuesDialog/index"
import InboxChannelSettings from "@/pages/Inbox/Instances/components/InboxChannelSettings"
import { getInboxChannelStatusComponent } from "@/utils/channelStatus"

type ChannelColumns = {
	name: "channel_id" | "owner_user_phone_number" | "integrationId" | "inbox_channel_status" | "actions"
	label: string
	align?: TableCellProps["align"]
	style?: React.CSSProperties
	formatPhoneNumber?: (value: string) => string
	formatStatus?: (userRole: UserStatus) => string
}

type ChannelsTableProps = {
	inboxChannel: InboxChannelProps
	inboxChannelLoading: boolean
	getInboxChannel: () => Promise<void>
}

type ChannelRowProps = {
	inboxChannel: InboxChannel
}

const TableChannelColumns: ChannelColumns[] = [
	{
		name: "channel_id",
		label: "ID"
	},
	{
		name: "owner_user_phone_number",
		label: "Número",
		formatPhoneNumber: (value: string) => formatPhoneNumber(value) as string
	},
	{
		name: "integrationId",
		label: "ID de integração"
	},
	{
		name: "inbox_channel_status",
		label: "Status",
		formatStatus: (userStatus: UserStatus) => userStatus === "active" ? "Ativo" : "Convidado"
	},
	{
		name: "actions",
		label: ""
	}
]

const ChannelsTable: React.FC<ChannelsTableProps> = (props) => {
	const {
		inboxChannel,
		inboxChannelLoading,
		getInboxChannel
	} = props

	const { triggerValidation } = useValidation()
	const classes = useStyles()

	const [actionsLoading, setActionsLoading] = useState<boolean>(false)

	const [clearQueuesDialog, setClearQueuesDialog] = useState<boolean>(false)

	const clearWhatsAppSession = async (integrationId: string) => {
		setActionsLoading(true)
		try {
			await InboxWhatsappChannelApi.get(`/whatsapp/session/${integrationId}/clear`)

			await getInboxChannel()
		} catch (error) {
			triggerValidation(error as ErrorType)
		}
		setActionsLoading(false)
	}

	const handleClearWhatsAppSession = async (integrationId: string) => {
		const props = {
			description: "Deseja encerrar sessão?",
			confirmButtonText: "ENCERRAR",
			onConfirm: () => clearWhatsAppSession(integrationId)
		}
		PopConfirm.open(props)
	}

	const ChannelRow: React.FC<ChannelRowProps> = (props) => {
		const { inboxChannel } = props

		const [collapseOpen, setCollapseOpen] = useState<boolean>(false)
		const [channelConnectionMetrics, setChannelConnectionMetrics] = useState<DailyChannelConnectionMetrics[]>()

		const getFormattedInfo = () => {
			const applicationStartDate = inboxChannel?.whatsappPodInfo?.applicationStartDate
			const version = inboxChannel?.whatsappPodInfo?.version

			let formattedVersion = ""

			if (version) {
				formattedVersion += `Versão: ${version}`
			}

			let formattedApplicationStartDate = ""

			if (applicationStartDate) {
				const result = formatDateAndTimeBrazilianDate(new Date(applicationStartDate))

				formattedApplicationStartDate += `(${result.dateAndTime})`
			}

			const formattedInfo = [
				formattedVersion,
				<br key="spacing" />,
				formattedApplicationStartDate
			]

			return formattedInfo
		}

		const handleConnectionMetricsOpen = async () => {
			try {
				const { data } = await InboxAdminApi.get(`instance/${inboxChannel.instanceId}/channel-connections/${inboxChannel.id}`)
				setChannelConnectionMetrics(data as unknown as DailyChannelConnectionMetrics[])
				setCollapseOpen(!collapseOpen)
			} catch (error) {
				Notification.error({
					message: "Houve um erro ao buscar as métricas de conexão da instância"
				})
			}
		}

		return (
			<>
				<TableRow
					component={Link}
					hover
					tabIndex={-1}
					className={classes.tableRow}
				>
					<TableCell>
						<IconButton aria-label="expand row" size="small" onClick={handleConnectionMetricsOpen}>
							{collapseOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
						</IconButton>
					</TableCell>

					{TableChannelColumns.map((column, index) => {
						let phoneNumber = inboxChannel?.extraData?.user?.phoneNumber
						const integrationId = inboxChannel.integrationId
						const channelId = inboxChannel.id

						if (column?.formatPhoneNumber) {
							if (phoneNumber) {
								phoneNumber = column.formatPhoneNumber(phoneNumber)
							}
						}

						if (!inboxChannel) {
							return (
								<>
									<Divider size={2} orientation="horizontal" />
									<Typography variant="h2" align="center">NENHUM CANAL CONFIGURADO</Typography>
								</>
							)
						} else {
							return (
								<TableCell
									key={index}
									style={column?.style}
									align={column?.align}
								>
									{column.name === "channel_id" && channelId}

									{column.name === "owner_user_phone_number" && phoneNumber}

									{column.name === "integrationId" && integrationId}

									{column.name === "inbox_channel_status" && (
										<Grid
											container
											direction="column"
											alignItems="center"
											justifyContent="center"
										>
											{getInboxChannelStatusComponent({
												status: inboxChannel.status,
												inboxChannelType: inboxChannel?.channelType
											})}

											{inboxChannel?.whatsappPodInfo?.version && (
												<>
													<Divider orientation="horizontal" size={1} />

													<Typography
														variant="caption"
														color="textPrimary"
														align="center"
													>
														{getFormattedInfo()}
													</Typography>
												</>
											)}
										</Grid>
									)}

									{column.name === "actions" && (
										<Grid container spacing={1}>

											<Grid item xs={3}>
												<Tooltip
													title="Encerrar Sessão"
												>
													<IconButton
														className={classes.clearSessionBtn}
														disabled={actionsLoading}
														onClick={() => handleClearWhatsAppSession(inboxChannel?.integrationId)}
													>
														{
															actionsLoading
																? <CircularProgress size={20} color="inherit" />
																: <CloseIcon />
														}
													</IconButton>
												</Tooltip>
											</Grid>

											<Grid item xs={3}>
												<Tooltip
													title="Limpar filas"
												>
													<IconButton
														disabled={actionsLoading}
														onClick={() => setClearQueuesDialog(true)}
													>
														{
															actionsLoading
																? <CircularProgress size={20} color="inherit" />
																: <ClearListIcon />
														}
													</IconButton>
												</Tooltip>
											</Grid>

											<Grid item xs={3}>
												<WhatsappPodUsageDialog
													inboxChannelIntegrationId={inboxChannel?.integrationId}
													getInboxChannel={getInboxChannel}
												>
													<Tooltip
														title="Ver dados de uso de recursos da aplicação na qual essa sessão está aberta"
													>
														<IconButton>
															<UsageIcon />
														</IconButton>
													</Tooltip>
												</WhatsappPodUsageDialog>
											</Grid>

											<Grid item xs={3}>
												<InboxChannelSettings
													getInboxChannel={getInboxChannel}
													inboxChannel={inboxChannel}
													inboxChannelSettingsInitialData={
														{
															isStaging: Boolean(inboxChannel?.extraData?.labels?.find(value => value === "whatsapp-api-staging")),
															libraryClientType: inboxChannel?.extraData?.libraryClientType,
															localLabels: inboxChannel?.extraData?.labels
														}
													}
													inboxChannelIntegrationId={inboxChannel?.integrationId}
												>
													<Tooltip
														title="Configurar Canal"
													>
														<IconButton
															disabled={actionsLoading}
														>
															{
																actionsLoading
																	? <CircularProgress size={20} color="inherit" />
																	: <SettingsIcon />
															}
														</IconButton>
													</Tooltip>
												</InboxChannelSettings>
											</Grid>

											<ClearQueuesDialog
												setShowClearQueuesDialog={setClearQueuesDialog}
												showClearQueuesDialog={clearQueuesDialog}
												inboxChannelIntegrationId={integrationId}
											/>
										</Grid>
									)}
								</TableCell>
							)
						}
					})}
				</TableRow>

				<TableRow>
					<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
						<Collapse in={collapseOpen} timeout="auto" unmountOnExit>
							<Box style={{ padding: "16px" }} margin={1}>
								<ChannelConnectionMetricsList
									channelConnectionMetrics={channelConnectionMetrics}
									instanceId={inboxChannel?.instanceId}
									channelType={inboxChannel?.channelType as ChannelType}
								/>
							</Box>
						</Collapse>
					</TableCell>
				</TableRow>
			</>
		)
	}

	return (
		<>
			<Grid item xs={12}>
				<Typography
					variant="h2"
					className={classes.title}
				>
					Canais
				</Typography>

				<Divider size={3} orientation="horizontal" />

				<Loading
					customLoadingElement={<CardSkeleton cardHeight={200} />}
					loading={inboxChannelLoading}
				>
					<Portlet
						elevation={1}
					>
						{inboxChannel?.count === 0 ? (
							<Typography
								variant="body1"
							>
								Nenhum canal encontrado
							</Typography>
						) : (
							<Grid container>
								<Grid item xs={12}>
									<Grid item xs>
										<TableContainer>
											<Table stickyHeader aria-label="sticky table">
												<TableHead>
													<TableRow>
														<TableCell />

														{TableChannelColumns.map((column, index) => (
															<TableCell
																key={index}
															>
																{column.label}
															</TableCell>
														))}
													</TableRow>
												</TableHead>
												<TableBody>
													{inboxChannel.rows?.map((inboxChannel, index) => (
														<ChannelRow
															inboxChannel={inboxChannel}
															key={index}
														/>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									</Grid>
								</Grid>
							</Grid>
						)}
					</Portlet>
				</Loading>

				<Divider size={2} orientation="horizontal" />
			</Grid>
		</>
	)
}

export default ChannelsTable
