import colors from "@/styles/colors"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	avatar: {
		width: 70,
		height: 70
	},
	title: {
		color: colors.grayScale[1],
		textTransform: "uppercase",
		fontWeight: "bold"
	},
	listItem: {
		paddingTop: 0,
		paddingBottom: 0
	},
	listPrimaryText: {
		fontWeight: "bold"
	},
	listSecondaryText: {
		fontSize: "16px",
		fontWeight: 500,
		lineHeight: "24px"
	}
})

export default useStyles
