import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

import { isSmallScreen } from "@/utils/checkDevice"

const useStyles = makeStyles({
	dialog: {
		position: "relative"
	},
	closeIcon: {
		position: "absolute",
		top: 0,
		right: 0
	},
	loading: {
		color: colors.grayScale[11]
	},
	drawerPaper: {
		width: `${isSmallScreen && "100vw"}`
	},
	children: {
		position: "relative",
		"&:before": {
			position: "absolute",
			top: 0,
			right: 0,
			width: "100%",
			height: "100%",
			zIndex: 9
		}
	}
})

export default useStyles
