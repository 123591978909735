import React from "react"
import {
	Chip, Tooltip
} from "@material-ui/core"

import {
	InboxWhatsappChannelStatus
} from "@/pages/Inbox/protocols/ChannelProtocol"

import {
	getWhatsappChannelStatusData
} from "@/channels/Whatsapp/utils/channel"

type WhatsappChipSessionStatusProps = {
	status: InboxWhatsappChannelStatus
}

const WhatsappChipSessionStatus = (props: WhatsappChipSessionStatusProps) => {
	const {
		status
	} = props

	const {
		label,
		icon,
		customStyle,
		description
	} = getWhatsappChannelStatusData(status)

	return (
		<>
			<Tooltip
				title={description}
			>
				<Chip
					label={label}
					style={{
						...customStyle,
						fontWeight: "bold"
					}}
					icon={icon}
				/>
			</Tooltip>
		</>
	)
}

export default WhatsappChipSessionStatus
