import { InputLabel, TextField, TextFieldProps } from "@material-ui/core"
import React from "react"
import { Divider } from "@/components/index"
import useCustomStyles from "@/styles/custom"

const CustomTextField = (props: TextFieldProps) => {
	const customClasses = useCustomStyles()
	return <div>
		<InputLabel
			className={customClasses.inputLabel}
		>
			{props.placeholder}
		</InputLabel>

		<Divider orientation="horizontal" size={1} />

		<TextField
			{...props}
		/>
	</div>
}

export default CustomTextField
