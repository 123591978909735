import React, { useState } from "react"

import { Button, Grid } from "@material-ui/core"

import { useHistory } from "react-router-dom"
import useStyles from "@/pages/Tools/styles"

type ToolName = "clear-message-queue" | "find-chat-by-protocol"
type ToolProps = {
	key: ToolName
	title: string
	pagePath: string
}

const tools: ToolProps[] = [
	{
		key: "clear-message-queue",
		title: "Limpar fila de mensagens",
		pagePath: "/clear-message-queue"
	},
	{
		key: "find-chat-by-protocol",
		title: "Buscar chat por protocolo",
		pagePath: "/find-chat-by-protocol"
	}
]

const Tools = () => {
	const history = useHistory()
	const classes = useStyles()
	const [isAnyToolLoading, setIsAnyToolLoading] = useState<boolean>(false)

	const basePath = "/tools"

	return (
		<Grid
			container
			className={classes.buttonContainer}
		>
			{
				tools?.map((renderedTool, toolIndex) => {
					return (
						<Grid
							item
							key={toolIndex}
						>
							<Button
								color="primary"
								variant="contained"
								id={renderedTool.key}
								className={classes.toolButton}
								fullWidth
								disabled={isAnyToolLoading}
								type="button"
								onClick={() => {
									setIsAnyToolLoading(true)
									history.push(`${basePath}${renderedTool.pagePath}`)
									setIsAnyToolLoading(false)
								}}
							>
								{renderedTool.title}
							</Button>
						</Grid>
					)
				})
			}
		</Grid>

	)
}

export default Tools
